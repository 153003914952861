import React, { useState } from 'react';

// utils
import { useNavigate } from 'react-router-dom';

// assets

// helper
import { getRandomColorsBassedOnLetters } from 'helper/colors';

// components
import Loader from '../../../components/commonComponents/Loader/Index';
import useToggle from '../../../hooks/useToggle';
import {
  updateCurrentBrand,
  UpdateOnboardingAccountId,
  updateToggleToast,
} from '../../../reduxToolkit/appSlice';
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';
import OnBoardingModal from './OnBoardingModal';
import OverviewCarousel from './OverviewCarousel';
import { dateConverter } from '../../../helper/dateConverter';
import { brandsInfoApi } from '../api/Api';
import { currentBrandWhatsappConnected } from '../../../api/Api';

export default function AccountCard({
  id,
  name,
  image,
  metrics,
  loadData,
  lastLogin,
  billing_plan_name,
  total_amount,
  brandUsers,
  end_date,
  status,
}) {
  const navigate = useNavigate();

  const dispatch = useAspDispatch();

  const { currentBrand, currentUser, currentAccount, toggleToast } =
    useAspSelector((state) => state?.app);

  const [openModal, setOpenModal] = useToggle(false);
  const [isSelecting, setIsSelecting] = useToggle(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const countsList = [
    {
      id: 1,
      label: 'No. of brands',
      value: metrics?.total_brands,
    },
    {
      id: 2,
      label: 'No. of people',
      value: metrics?.total_users,
    },
    {
      id: 3,
      label: 'Active brands',
      value: metrics?.active_brands,
    },
    {
      id: 4,
      label: 'In-active brands',
      value: metrics?.inactive_brands,
    },
    {
      id: 5,
      label: 'Pending Invoices',
      value: metrics?.pendinng_invoices,
    },
  ];

  const colors = getRandomColorsBassedOnLetters(name);
  const date = dateConverter(lastLogin);
  const lastloginBrand = `${date?.monthInShort} ${date?.date},${date?.year}`;

  const [brandsData, setBrandsData] = useState([]);

  const handleNavigate = (id) => {
    brandsInfoApi(id)
      .then((res) => {
        if (res?.status === 200) {
          setBrandsData(res?.data);
          navigate(`/profile/account/overview/${id}/brand_info`, {
            state: { brandsData: res?.data },
          });
        } else {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: res?.response?.data?.error,
                status: 'Error',
                duration: '',
              },
            ])
          );
        }
      })
      .catch((error) => {
        console.error('Error fetching brand info:', error);
      });
  };

  const brand = currentAccount?.brands?.find((item) => item?.brand_id === id);

  const handleBrandSelect = (id) => {
    setIsSelecting(true);
    setSelectedBrand(brand);
    if (id) {
      currentBrandWhatsappConnected(id).then((res) => {
        const wap = res?.data?.is_whatsapp_active;

        setTimeout(() => {
          dispatch(
            updateCurrentBrand({
              ...brand,
              brand_billing_plan_name: billing_plan_name,
              brand_status: status,
              is_whatsapp_connected: wap,
            })
          );
          setIsSelecting(false);
        }, 3000);
      });
    }

    setTimeout(() => {
      navigate('/user/home');
    }, 3000);
  };

  const utcDate = new Date(end_date);
  const currentDate = new Date();
  const differenceInMs = utcDate.getTime() - currentDate.getTime();
  const differenceInDays = Math.max(
    0,
    Math.floor(differenceInMs / (1000 * 60 * 60 * 24))
  );

  return (
    <div className=''>
      {billing_plan_name !== 'Starter Plan' ? (
        <>
          <span
            className={`text-white font15px-500 ${
              total_amount === null
                ? 'bg-[#FF543E] border-[#FF543E]'
                : total_amount < 1000 && total_amount !== null
                ? 'border-[#F6A723] border bg-[#F6A723]'
                : differenceInDays < 3 && differenceInDays !== 0
                ? 'border-[#F6A723] border bg-[#F6A723]'
                : differenceInDays === 0
                ? 'bg-[#FF543E] border-[#FF543E]'
                : [2, 3, 4, 5].includes(status)
                ? 'bg-[#B49FF9]'
                : ''
            }  rounded-t-xl p-5`}>
            {total_amount < 1000 && total_amount !== null
              ? 'Credits running low!'
              : ''}
            {total_amount === null ? 'Credits exhausted!' : ''}
            {differenceInDays < 3 &&
            differenceInDays !== 0 &&
            ((total_amount < 1000 && total_amount > 1) || total_amount !== null)
              ? 'Subscription expires soon!'
              : ''}
            {differenceInDays === 0 &&
            (total_amount === 0 || total_amount !== null)
              ? 'Subscription expired!'
              : ''}
            {[2, 3, 4, 5].includes(status) ? 'Onboarding...' : ''}
          </span>
        </>
      ) : (
        <>
          <span
            className={`text-white font15px-500 ${''}  rounded-t-xl p-5`}></span>
        </>
      )}
      <div
        className={`group w-full h-full max-w-[380px] relative bg-white  pt-4 pb-10 border ${
          billing_plan_name === 'Starter Plan' // Replace "plan" with the actual variable holding the plan type
            ? 'border-[var(--border-input)] rounded-xl'
            : total_amount < 1000 && total_amount !== null
            ? 'border-[#F6A723] rounded-bl-xl rounded-tr-xl rounded-br-xl'
            : total_amount === null
            ? 'border-[#FF543E] rounded-bl-xl rounded-tr-xl rounded-br-xl'
            : differenceInDays < 3 && differenceInDays !== 0
            ? 'border-[#F6A723] rounded-bl-xl rounded-tr-xl rounded-br-xl'
            : differenceInDays === 0
            ? 'border-[#FF543E] rounded-bl-xl rounded-tr-xl rounded-br-xl'
            : [2, 3, 4, 5].includes(status)
            ? 'rounded-bl-xl rounded-tr-xl rounded-br-xl'
            : 'border-[var(--border-input)] rounded-xl'
        } pointer`}>
        <div className='w-full h-full'>
          <div
            className='w-full flex items-center gap-4 px-4 pb-5'
            // onClick={(e) => {
            //   clearSearch();
            //   // handleNavigate(id);
            // }}
          >
            <div
              className={`w-[3.5vw] h-[3.5vw] rounded-xl relative  ${
                image ? 'border-2' : 'border-none'
              }`}>
              {image !== null ? (
                <img
                  src={image !== null ? image : ''}
                  alt=''
                  className='w-full h-full object-cover p-1 rounded-xl'
                />
              ) : (
                <p
                  className='text-white bg-[#8796AF] w-full h-full flex justify-center items-center rounded-xl text-[2vw]'
                  style={{
                    color: colors.color,
                    backgroundColor: colors.backgroundColor,
                  }}>
                  {name.charAt(0).toUpperCase()}
                </p>
              )}
            </div>
            <div className='flex flex-col justify-center gap-1'>
              <h4 className='w-full max-w-[200px] truncate text-base weight-medium capitalize'>
                {name}
              </h4>
              <span className='text-[#6564CA] font12px-500 italic'>
                {lastLogin !== null && `(Last login: ${lastloginBrand})`}
              </span>
              {/* {ACCOUNT_LEVEL_ACCESS(role) && (
              <p className="w-fit rounded text-xs font-semibold px-2.5 py-1 bg-[var(--BG-50)] text-[var(--font-600)]">
                {role}
              </p>
            )} */}
            </div>
          </div>
          <div className='w-full'>
            <div className='w-full h-full  relative'>
              <OverviewCarousel
                data={countsList}
                // viewAccount={viewAccount}
                billing_plan_name={billing_plan_name}
                total_amount={total_amount}
                brandUsers={brandUsers}
                differenceInDays={differenceInDays}
                className='group-hover:!flex hidden'
                name={name}
              />
            </div>
            <div className='px-4 flex-row align-center gap-4 pb-10'>
              {currentAccount?.role !== 'Account Manager' ? (
                <button
                  className='w-full flex-row items-center justify-center font16px-500 border border-[var(--new-primary)] text-[var(--new-primary)] b-radius-6 px-4 py-2'
                  onClick={() => {
                    handleBrandSelect(id);
                  }}>
                  {isSelecting ? (
                    <div className=' flex items-center justify-center'>
                      <Loader
                        Width={20}
                        Height={20}
                      />
                    </div>
                  ) : (
                    'Goto Brand'
                  )}
                </button>
              ) : (
                <button className='w-full font16px-500 bg-[var(--BG-50)] text-[#A9B1C1] b-radius-6 px-4 py-2 cursor-not-allowed'>
                  Goto Brand
                </button>
              )}
              <button
                // className={`w-full font16px-500 ${
                //   status === 9 ? "bg-[#FF543E]" : "bg-[var(--new-primary)]"
                // } text-white b-radius-6 px-4 py-2`}
                className={`w-full font16px-500 text-white b-radius-6 px-4 py-2 bg-[var(--new-primary)]`}
                onClick={() => {
                  handleNavigate(id);

                  // dispatch(
                  //   UpdateOnboardingAccountId({
                  //     acc_id: id,
                  //   })
                  // );
                }}>
                View Info
              </button>
            </div>
          </div>
        </div>
        {/* <Tooltip title="Account settings">
        <div
          className="absolute top-5 right-5 z-50"
          onClick={(e) => {
            clearSearch();
            navigate(`${id}/edit`);
          }}
        >
          <Settings width={16} height={16} strokeWidth="2" />
        </div>
      </Tooltip> */}
        <OnBoardingModal
          open={openModal}
          setOpen={setOpenModal}
          creation_type={'brand'}
          accountData={{ id: id }}
          loadData={loadData}
        />
      </div>
    </div>
  );
}
