import AspApi from "./ApiConfig";

export const Logout = async () =>
  await AspApi.post("api/v1/svc/aaa/authentication/logout");

export const currentUserDetails = async () =>
  await AspApi.get("api/v1/svc/aaa/authentication/current_user");

export const currentBrandWhatsappConnected = async (brandId) =>
  await AspApi.get(`/api/v1/channels/validate?brand_id=${brandId}`);

export const preSendUrl = async (params, payload) => {
  let qpm = [];
  if (payload?.brand_id) qpm.push(`brand_id=${payload?.brand_id}`);
  if (payload?.template_id) qpm.push(`template_id=${payload?.template_id}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString += "&" + qpm?.join("&");

  const res = await AspApi.get(
    `api/v1/svc/aaa/presigned_url?extension_name=${params}${qpmString}`
  );
  return res;
};

export const preSendingUrl = async (params) =>
  await AspApi.get(`api/v1/svc/aaa/presigned_url?extension_name=${params}`);

export const whatsappBusinessNumbers = async (brandId) => {
  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/whatsapp_numbers?brand_id=${brandId}`
  );
  return res;
};

// Account last active api ---------------

export const markRecentAccountActive = async (account_id) => {
  const res = await AspApi.put(
    `api/v1/accounts/${account_id}/mark_recent_active`
  );
  return res;
};

export const markRecentActive = async (brandId, accountId) => {
  const res = await AspApi.put(
    `api/v1/brands/${brandId}/mark_recent_active?account_id=${accountId}`
  );
  return res;
};

// Profile Info api ----------------
export const profileInformationApi = async (user_id) => {
  const res = await AspApi.get(`api/v1/svc/aaa/users/${user_id}`);
  return res;
};

export const profileInformationUpdate = async (user_id, payload) => {
  const res = await AspApi.put(`api/v1/svc/aaa/users/${user_id}`, payload);
  return res;
};

export const resetPasswordPatch = async (user_id, payload) => {
  const res = await AspApi.patch(
    `api/v1/svc/aaa/users/${user_id}/reset_password`,
    payload
  );
  return res;
};

//account info api
export const accountInformationApi = async (accountId) => {
  const res = await AspApi.get(`api/v1/accounts/${accountId}`);
  return res;
};

export const accountInformationUpdate = async (accountId, payload) => {
  const res = await AspApi.put(`api/v1/accounts/${accountId}`, payload);
  return res;
};

export const accountInformationOtp = async (accountId, payload) => {
  const res = await AspApi.post(
    `api/v1/account_center/sent_whatsapp_otp?account_id=${accountId}`,
    payload
  );
  return res;
};

export const accountOwnersInfoApi = async (accountId) => {
  const res = await AspApi.get(`api/v1/accounts/${accountId}/accounts_owners`);
  return res;
};

export const accountContactInfoApi = async (accountId) => {
  const res = await AspApi.get(
    `api/v1/accounts/${accountId}/contact_information`
  );
  return res;
};

export const accountContactInformationUpdate = async (accountId, payload) => {
  const res = await AspApi.put(
    `api/v1/accounts/${accountId}/update_contact_information`,
    payload
  );
  return res;
};

// Credits api

export const creditsApi = async (brand_id) => {
  const res = await AspApi.get(`api/v1/brands/${brand_id}/get_brand_credits`);
  return res;
};
