import React from 'react';

const SectionRow = ({ title, description }) => {
  return (
    <div className='w-full max-w-[230px] flex-1 flex flex-col justify-center gap-[1px]'>
      {title && (
        <h5 className='w-4/5 text-[0.813rem] weight-semibold text-[#2D3036] truncate'>
          {title}
        </h5>
      )}
      {description && (
        <p className='w-4/5 text-[0.625rem] weight-medium text-[var(--font-600)] overflow-hidden truncate'>
          {description}
        </p>
      )}
    </div>
  );
};

export default SectionRow;
