import { Modal } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

// api
import { deleteNode, markAsEndNode } from '../../../api/sequence';

// assets
import Refresh from '../../../../../assets/customSVG/Refresh';
import FullArrow from '../../../../../assets/customSVG/FullArrow';
import Xmark from '../../../../../assets/customSVG/Xmark';
import bg from '../../../../../assets/images/body-message.png';

// constants
import {
  INITIAL_NODE_COMPONENT_DATA,
  INITIAL_TRIGGER_DATA,
} from '../../../constants/sequence';
import { INITIAL_NODE_TRIGGER_DATA } from '../../../constants/triggers';

// helpers
import { cn } from '../../../../../helper/cn';
import { isObjectEmpty } from '../../../helpers';
import {
  formatUpdatedBroadcast,
  getNodeCurrentStatus,
} from '../../../helpers/sequence/node';

// redux
import { updateToggleToast } from '../../../../../reduxToolkit/appSlice';
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../test/jest-redux-hooks';

// hooks
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import useToggle from '../../../../../hooks/useToggle';

// components
import EdgeHandler from '../edge/Handler';
import NodeEditMode from '../edit/Index';
import PreviewNodeContainer from '../preview/PreviewNodeContainer';
import TriggerList from '../triggers/TriggerList';
import TriggerModel from '../triggers/TriggerModel';
import Actions from './Actions';
import Details from './Details';
import Handler from './Handler';
import Metrics from './Metrics';
import PreviewModalHeader from '../preview/PreviewModalHeader';

const Nodes = ({
  nodes,
  edges,
  newEdge,
  handleMouseDown,
  handleMouseUp,
  onChange,
  handleOnMouseDownEdge,
  findEmptySpace,
  onMouseEnterEdgeEnd,
  handleEdgeMouseUp,
  allowToAddNode,
  getSelectectedNodeId,
  allowToEditFlow,
  sequenceDetails,
  selectedNode,
}) => {
  const { campaign_id, sequence_id } = useParams();
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const nodeRef = useRef();
  const nodeOutsideRef = useOutsideClick(() => {});

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useToggle(null);
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    const handleKeyDown = (e) => e?.code === 'Escape' && handleCancelEditMode();

    if (selectedNode?.settings?.defaults?.isEditMode)
      window.addEventListener('keyup', handleKeyDown);

    return () => window.removeEventListener('keyup', handleKeyDown);
  }, [selectedNode?.settings?.defaults?.isEditMode]);

  const handleToastError = (message) =>
    dispatch(
      updateToggleToast([
        ...toggleToast,
        {
          id: toggleToast?.length + 1,
          content: message,
          status: 'Error',
          duration: '',
        },
      ])
    );

  const onSelectTrigger = ({ node, data }) => {
    const updatedDefaults = {
      ...node.settings.defaults,
      isExisting: true,
      isEditMode: true,
    };

    onChange({
      type: 'UPDATE_NODE',
      id: node?.uniq_id,
      data: {
        ...node,
        ...INITIAL_TRIGGER_DATA[data?.key],
        data: INITIAL_NODE_COMPONENT_DATA[data?.key],
        settings: {
          ...node.settings,
          defaults: updatedDefaults,
        },
      },
    });

    onChange({
      type: 'UPDATE_DATA',
      data: {
        selectedNode: [
          {
            ...node,
            ...INITIAL_TRIGGER_DATA[data?.key],
            data: INITIAL_NODE_COMPONENT_DATA[data?.key],
            settings: {
              ...node.settings,
              defaults: updatedDefaults,
            },
          },
        ],
      },
    });
  };

  const onTriggerClick = async ({ data, node }) => {
    let currentNode = {
      ...node,
      label: 'Send Message',
      type: data?.key,
      data: INITIAL_NODE_TRIGGER_DATA[data?.key],
    };

    onChange({
      type: 'UPDATE_DATA',
      data: { selectedNode: [currentNode] },
    });
  };

  const toggleNodeEditMode = async ({
    id,
    value,
    uniqId,
    defaults,
    key,
    node = null,
  }) => {
    if (node) {
      let data = {
        ...node,
        settings: {
          ...node?.settings,
          defaults: { ...node?.settings?.defaults, isEditMode: true },
        },
      };

      if (node?.type === 'TRIGGER' && !node?.settings?.defaults?.isExisting)
        data = formatUpdatedBroadcast({ node: data });

      onChange({
        type: 'UPDATE_DATA',
        data: { selectedNode: [data] },
      });
    } else {
      if (key && key?.length > 0) {
        onChange({
          type: 'UPDATE_NODE',
          id: uniqId,
          primaryKey: 'settings',
          secondaryKey: 'defaults',
          key,
          data: value,
        });
      } else {
        onChange({
          type: 'UPDATE_NODE',
          id: uniqId,
          primaryKey: 'settings',
          secondaryKey: 'defaults',
          key: 'isEditMode',
          data: value,
        });
      }
    }
  };

  const deleteEdges = useCallback(
    (id) => {
      const deleteEdges = edges?.filter(
        (edge) => edge?.end_id !== id && edge?.start_id !== id
      );

      onChange({
        type: 'UPDATE_DATA',
        data: { edges: deleteEdges },
      });
    },
    [edges]
  );

  const handleDeleteNode = async ({ uniq_id, type, order, node }) => {
    const payload = {
      brandId: currentBrand?.brand_id,
      campaignId: campaign_id,
      nodeType: type,
    };

    const res = await deleteNode(sequence_id, uniq_id, payload);
    if (res?.status === 200) {
      onChange({
        type: 'DELETE_NODE',
        id: uniq_id,
        key: order,
      });
      deleteEdges(uniq_id);
    }
  };

  const onEdgeMouseDown = ({ node, connection_point, position }) =>
    handleOnMouseDownEdge({ node, connection_point, position });

  const checkIsEdgeConnected = useCallback(
    ({ start_node_id, connection_point }) => {
      const isConnected = edges?.find(
        (edge) =>
          edge?.start_id === start_node_id &&
          edge?.connection_point?.id === connection_point?.id
      );

      return isConnected ? true : false;
    },
    [edges, nodes]
  );

  const toggleLastNode = async ({ nodeId, value }) => {
    try {
      const payload = {
        brand_id: currentBrand?.brand_id,
        campaign_id: campaign_id,
        end: value,
      };
      const res = await markAsEndNode(sequence_id, nodeId, payload);

      if (res?.status === 200) {
        onChange({
          type: 'TOGGLE_LAST_NODE',
          id: nodeId,
          value,
        });
      } else {
        handleToastError(res?.response?.data?.message);
      }
    } catch (error) {
      console.warn(error?.message);
    }
  };

  const toggleSelectedNode = (id, uniq_id, value) => {
    getSelectectedNodeId(uniq_id);
    if (allowToEditFlow)
      onChange({
        type: 'UPDATE_NODE_DATA',
        id: uniq_id,
        primaryKey: 'settings',
        secondaryKey: 'defaults',
        key: 'isSelected',
        data: value,
      });
  };

  const checkIsEndNodeConnected = useCallback(
    ({ nodeId }) => {
      const res = edges?.filter((edge) => edge?.end_id === nodeId);
      return res?.length > 0 ? true : false;
    },
    [edges]
  );

  const checkIsStartNodeConnected = useCallback(
    ({ nodeId }) => {
      const res = edges?.filter((edge) => edge?.start_id === nodeId);
      return res?.length > 0 ? true : false;
    },
    [edges]
  );

  const getUnSavedEdge = useCallback(
    async (id) => await edges?.find((edge) => edge?.end_id === id),
    [edges]
  );

  const removeEdges = useCallback(
    (startNodeId) => {
      const res = edges?.filter((edge) => edge?.start_id !== startNodeId);
      onChange({
        type: 'UPDATE_DATA',
        data: { edges: res },
      });
    },
    [edges]
  );

  const updateEdgesCoordinates = useCallback(
    ({ startNodeId, removedEdges, data }) => {
      let updatedEdges = edges;

      // Filter out the removed edges once, before any further processing
      if (removedEdges?.length > 0) {
        updatedEdges = updatedEdges.filter(
          (edge) => !removedEdges.includes(edge?.uniq_id)
        );
      }

      const currentNodeUpdatedEdges = [];

      const res = updatedEdges.map((edge) => {
        if (edge?.start_id === startNodeId) {
          // Updating the edge coordinates
          const updatedEdge = {
            ...edge,
            coordinates: {
              ...edge.coordinates,
              start: {
                ...edge.coordinates.start,
                y: edge.coordinates.start.y + Number(data?.height),
              },
            },
          };

          currentNodeUpdatedEdges.push({
            id: updatedEdge.uniq_id,
            coordinates: updatedEdge.coordinates,
          });

          return updatedEdge;
        }

        return edge;
      });

      return { edges: res, currentNodeUpdatedEdges };
    },
    [edges]
  );

  const handleCancel = () => setIsPreviewModalOpen(false);

  const handleCancelEditMode = useCallback(() => {
    onChange({
      type: 'UPDATE_DATA',
      data: { selectedNode: null },
    });
  }, [selectedNode]);

  return (
    <>
      {nodes?.map((node) => {
        const isConnected = checkIsEndNodeConnected({ nodeId: node?.uniq_id });
        const isEdgeConnected = checkIsStartNodeConnected({
          nodeId: node?.uniq_id,
        });

        const nodeCurrentStatus = getNodeCurrentStatus(node);

        const color = '#0ACD95';
        return (
          <div
            key={node?.id}
            className={cn(
              'w-24 h-auto text-white flex items-center gap-2.5 z-0'
            )}
            style={{
              zIndex: node?.uniq_id === selectedNode ? 999 : 10,
              pointerEvents: 'all',
              visibility: 'visible',
              position: 'absolute',
              transform: `translate(${node?.coordinates?.x}px, ${node?.coordinates?.y}px)`,
            }}
            onMouseEnter={(e) =>
              toggleSelectedNode(node?.id, node?.uniq_id, true)
            }
            onMouseLeave={(e) => toggleSelectedNode(node?.id, null, false)}
            ref={nodeOutsideRef}>
            <div className='relative w-full h-full flex flex-1 items-start z-0'>
              <div
                className={cn(
                  'group relative w-24 h-auto text-white flex flex-col items-center justify-center gap-2.5 !z-[1]'
                )}>
                {/* Nodes toolbar */}
                {node?.data && (
                  <Actions
                    sequenceDetails={sequenceDetails}
                    node={node}
                    newEdge={newEdge}
                    setPreviewData={setPreviewData}
                    allowToEditFlow={allowToEditFlow}
                    setIsPreviewModalOpen={setIsPreviewModalOpen}
                    isConnected={isConnected}
                    isEdgeConnected={isEdgeConnected}
                    toggleLastNode={toggleLastNode}
                    toggleNodeEditMode={toggleNodeEditMode}
                    handleDeleteNode={handleDeleteNode}
                  />
                )}

                {/* Node handler */}
                <div
                  ref={nodeRef}
                  className='relative flex items-center'>
                  {!node?.is_trigger && (
                    <div
                      className={cn(
                        'absolute z-[2]',
                        node?.status !== 'UN_SAVED'
                          ? node?.is_last_node
                            ? 'top-[25.8%] -left-2'
                            : 'top-[33%] -left-2'
                          : 'top-[33%] -left-2'
                      )}>
                      <EdgeHandler
                        type='end'
                        node={node}
                        onEdgeMouseEnter={() => {
                          if (allowToEditFlow) onMouseEnterEdgeEnd({ node });
                        }}
                        handleEdgeMouseUp={({ node, position }) => {
                          if (handleEdgeMouseUp && allowToEditFlow)
                            handleEdgeMouseUp({ node, position });
                        }}
                        // color={node?.status === 'UN_SAVED' ? '#6940F2' : color}
                        color={color}
                        allowToEditFlow={allowToEditFlow}
                      />
                    </div>
                  )}
                  <div
                    onMouseDown={(e) => {
                      if (allowToEditFlow) handleMouseDown(e, node?.uniq_id);
                    }}
                    style={{
                      width: node?.data ? (node?.is_trigger ? 110 : 86) : 86,
                    }}>
                    <Handler
                      id={node?.id}
                      uniqId={node?.uniq_id}
                      node_type={node?.node_type}
                      is_trigger={node?.is_trigger}
                      is_last_node={node?.is_last_node}
                      trigger_type={node?.trigger_type}
                      component_type={node?.component_type}
                      label={
                        node?.label
                          ? node?.label
                          : node?.is_trigger
                          ? 'Select Trigger'
                          : 'Add Node'
                      }
                      order={node?.order}
                      data={node.data}
                      settings={node?.settings}
                      isEditMode={node?.isEditMode}
                      status={node?.status}
                      onChange={onChange}
                      allowToEditFlow={allowToEditFlow}
                      handleMouseUp={handleMouseUp}
                      handleEdgeMouseUp={({ event, node, position }) => {
                        if (handleEdgeMouseUp && allowToEditFlow)
                          handleEdgeMouseUp({ event, node, position });
                      }}
                      newEdge={newEdge}
                      bgColor={
                        nodeCurrentStatus
                          ? nodeCurrentStatus?.color
                          : !isObjectEmpty(node?.data)
                          ? node?.is_trigger
                            ? '#04B8A2'
                            : '#0acd95'
                          : '#6940F2'
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                id={node?.uniq_id}
                className={cn(
                  'absolute top-0 !z-0',
                  newEdge ? 'cursor-grabbing' : 'cursor-default',
                  node?.data
                    ? node?.is_trigger
                      ? 'left-[125%]'
                      : 'left-[115%]'
                    : 'left-[115%]'
                )}
                onMouseDown={(e) =>
                  toggleSelectedNode(node?.id, node?.uniq_id, true)
                }
                onMouseUp={(e) =>
                  toggleSelectedNode(node?.id, node?.uniq_id, false)
                }>
                <div className='absolute flex flex-col items-center'>
                  {!allowToEditFlow && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '-45px',
                        left: '50%',
                        transform: `translate(-50%, -45px)`,
                        zIndex: 3,
                      }}>
                      <Metrics
                        metrics={node?.metrics}
                        type={node?.type}
                      />
                    </div>
                  )}
                  {node?.type && node?.data && !isObjectEmpty(node?.data) ? (
                    <PreviewNodeContainer
                      node={node}
                      bgColor={
                        nodeCurrentStatus
                          ? nodeCurrentStatus?.color
                          : node?.is_trigger
                          ? '#04B8A2'
                          : '#0ACD95'
                      }>
                      <Details
                        node={node}
                        newEdge={newEdge}
                        onChange={onChange}
                        onEdgeMouseDown={({ connection_point, position }) =>
                          onEdgeMouseDown({
                            node,
                            connection_point,
                            position: position,
                          })
                        }
                        checkIsEdgeConnected={checkIsEdgeConnected}
                        isConnected={isConnected}
                        color={
                          nodeCurrentStatus
                            ? nodeCurrentStatus?.color
                            : '#0ACD95'
                        }
                        allowToEditFlow={allowToEditFlow}
                        nodeCurrentStatus={nodeCurrentStatus}
                      />
                    </PreviewNodeContainer>
                  ) : (
                    <div className='w-full text-black relative'>
                      {node?.is_trigger &&
                      !node?.settings?.defaults?.isEditMode ? (
                        <div className='relative w-full min-w-96'>
                          <TriggerList
                            onSelectTrigger={(data) =>
                              onSelectTrigger({ node, data })
                            }
                          />
                        </div>
                      ) : (
                        <div className='rounded-2xl shadow-[0px_4px_30px_0px_#2D30361A]'>
                          <TriggerModel
                            type='tab'
                            onTriggerClick={(data) => {
                              onTriggerClick({ node, data });
                            }}
                            allow={true}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <Modal
        open={selectedNode?.settings?.defaults?.isEditMode}
        afterOpenChange={(open) => {
          if (allowToEditFlow)
            toggleNodeEditMode({
              id: selectedNode?.id,
              uniqId: selectedNode?.uniq_id,
              value: open ? 'true' : 'false',
              defaults: selectedNode?.settings?.defaults,
            });
        }}
        centered
        footer={null}
        closable={false}
        wrapClassName={'bg-[#00000035] !p-0 !m-0'}
        className={'!rounded-2xl !overflow-hidden'}
        destroyOnClose={true}>
        <NodeEditMode
          originalData={previewData}
          node={selectedNode}
          onChange={onChange}
          onTriggerClick={onTriggerClick}
          findEmptySpace={findEmptySpace}
          allowToAddNode={allowToAddNode}
          toggleNodeEditMode={toggleNodeEditMode}
          toggleLastNode={toggleLastNode}
          allowToEditFlow={allowToEditFlow}
          removeEdges={removeEdges}
          updateEdgesCoordinates={updateEdgesCoordinates}
          handleCancelEditMode={handleCancelEditMode}
          getUnSavedEdge={getUnSavedEdge}
          sequenceDetails={sequenceDetails}
        />
      </Modal>
      <Modal
        open={isPreviewModalOpen}
        afterOpenChange={(open) => {
          setIsPreviewModalOpen(open);
        }}
        centered
        footer={null}
        closable={false}
        wrapClassName={'bg-[#00000035] !p-0 !m-0'}
        className={'!w-full max-w-[375px] !h-fit !rounded-2xl !overflow-hidden'}
        destroyOnClose={true}>
        <div className={''}>
          <PreviewModalHeader
            title={currentBrand?.brand_name}
            handleCancel={handleCancel}
            showClose
          />
          <div
            className=''
            style={{ backgroundImage: `url(${bg})` }}>
            <div className='w-fit py-2.5 px-4'>
              <Details
                previewMode={true}
                previewType={null}
                node={previewData}
                checkIsEdgeConnected={checkIsEdgeConnected}
                isConnected={checkIsEndNodeConnected({
                  nodeId: previewData?.uniq_id,
                })}
                allowToShowEdge={false}
                color={
                  getNodeCurrentStatus()
                    ? getNodeCurrentStatus()?.color
                    : '#0ACD95'
                }
                allowToEditFlow={allowToEditFlow}
                nodeCurrentStatus={getNodeCurrentStatus()}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Nodes;
