import React from 'react';

// helpers
import { cn } from '../../../../helper/cn';

const Tabs = ({ tabs, selected, onTabChange }) => {
  return (
    <div className='flex items-center gap-3'>
      {tabs?.map((t) => {
        return (
          <div
            className={cn(
              'rounded-md py-1 px-2.5 border text-[0.813rem] weight-medium',
              t?.isDisabled
                ? 'border-gray-200 hover:bg-gray-200 cursor-not-allowed'
                : t.key === 'none' || selected?.key === t?.key
                ? 'bg-[#6564CA] text-white border-[#6564CA] cursor-pointer'
                : 'border-gray-200 hover:bg-gray-200 cursor-pointer'
            )}
            onClick={() => {
              onTabChange(t);
            }}>
            {t.label}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
