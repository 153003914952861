import { Popover } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import html2canvas from 'html2canvas';

// api
import {
  getSequenceActivitesCard,
  getSequenceOverviewPerformenceMetrics,
  updateSequenceStatus,
} from '../api/SquenceOverview';
import { getSequence, updateSequence } from '../api/sequence';

// assets
import Arrow from '../../../assets/customSVG/Arrow';
import Download from '../../../assets/customSVG/campaigns/Download';
import EditIcon from '../../../assets/customSVG/EditIcon';
import Flow from '../../../assets/customSVG/sequence/Flow';
import { ICONS } from '../../../assets/icons';

// constants
import {
  PERFORMENCE_DATE_FILTERS_LIST,
  PERFORMENCE_TABS_FILTER,
} from '../constants/sequenceOverview';

// hooks
import useToggle from '../../../hooks/useToggle';
import useOutsideClick from '../../../hooks/useOutsideClick';

// helpers
import { dateConverter } from '../../../helper/dateConverter';

// constants
import {
  MAX_SEQUENCE_LENGTH,
  SEQUENCE_STATUS_STYLES,
} from '../constants/sequence';

// redux
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';
import { updateToggleToast } from '../../../reduxToolkit/appSlice';

// components
import Breadcrumbs from '../../../components/commonComponents/breadcrumbs';
import Switch from '../../../components/commonComponents/step/switch';
import Cards from '../components/sequence/overview/Cards';
import ContactList from '../components/sequence/overview/ContactList';
import Preview from '../components/sequence/overview/Preview';
import Tooltip from '../../../components/commonComponents/tooltip';
import Performance from '../components/overviewChart/Performance';
import Loader from '../../../components/commonComponents/Loader/Index';
import MonthCalender from '../../../components/commonComponents/calendar/MonthCalender';

const cardsArray = [
  {
    id: 1,
    icon: ICONS?.ConversionCost,
    label: 'Re-engagement Rate',
    description:
      'Number / Percentage of customers re-engaging after a prior conversation.',
    key: 're_engagement',
    value: '0',
  },
  {
    id: 2,
    icon: ICONS?.ActiveConversion,
    label: 'Active Conversations',
    key: 'active_conversations',
    value: '0',
  },
  {
    id: 3,
    icon: ICONS?.FirstReply,
    label: 'Customer Engagement Score',
    description:
      'Customer engagement within conversations (e.g.,  reactions, clicks).',
    key: 'engagement_score',
    value: '0',
  },
  {
    id: 4,
    icon: ICONS?.ConvertionWaitTime,
    label: 'Avg. Wait Time for Conversion',
    key: 'avg_wait_time',
    value: '0',
  },
];

const SequenceMetrix = () => {
  const { sequence_id, campaign_id } = useParams();
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [isLoading, setIsLoading] = useToggle(false);
  const [sequenceDetails, setSequenceDetails] = useState(null);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [flowDetails, setFlowDetails] = useState(null);
  const [cardsMetrics, setCardsMetrics] = useState(cardsArray);
  const [isEditName, setIsEditName] = useToggle(false);
  const [editedName, setEditedName] = useState('');
  const [isOpenDatesFilter, setIsOpenDatesFilter] = useToggle(false);
  const [selectedDateFilter, setSelectedDateFilter] = useState(null);
  const [IsOpenCustomDateFilter, setIsOpenCustomDateFilter] = useToggle(false);
  const [filteredDates, setFilteredDates] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  const [filter, setFilter] = useState([]);
  const [performenceData, setPerformenceData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [updatedPerformanceData, setUpdatedPerformanceData] = useState(
    PERFORMENCE_TABS_FILTER
  );
  const captureRef = useRef();

  const breadcumbsData = [
    { label: 'Campaigns', path: '/user/campaign/all-campaigns' },
    {
      label: 'Campaign Execution',
      path: `/user/campaign/view/${campaign_id}/execution`,
    },
    { label: 'Sequence Overview', path: '' },
  ];

  const getDates = (key) => {
    const today = moment();
    const prevday = moment(today)
      .add(-1, 'days')
      .startOf('days')
      .format('YYYY-MM-DD');
    const lastWeekStartDate = moment(today)
      .add(-6, 'days')
      .startOf('days')
      .format('YYYY-MM-DD');
    const last14DaysStartDate = moment(today)
      .add(-13, 'days')
      .startOf('days')
      .format('YYYY-MM-DD');
    const last28DaysStartDate = moment(today)
      .add(-27, 'days')
      .startOf('days')
      .format('YYYY-MM-DD');

    let dates = {};
    if (key === 'last7days')
      dates = {
        startDate: lastWeekStartDate,
        endDate: today?.format('YYYY-MM-DD'),
      };
    else if (key === 'last14days')
      dates = {
        startDate: last14DaysStartDate,
        endDate: today?.format('YYYY-MM-DD'),
      };
    else if (key === 'last28days')
      dates = {
        startDate: last28DaysStartDate,
        endDate: today?.format('YYYY-MM-DD'),
      };

    setFilteredDates(dates);
    return dates;
  };

  const updateSequenceData = async () => {
    if (editedName?.length === 0) {
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: 'The sequence name cannot be left empty.',
            status: 'Success',
            duration: '',
          },
        ])
      );
      return;
    }
    try {
      const payload = {
        campaign_id,
        brand_id: currentBrand?.brand_id,
        name: editedName,
      };
      const res = await updateSequence(sequence_id, payload);
      if (res?.status === 200) {
        setSequenceDetails((prev) => ({ ...prev, name: editedName }));
        setIsEditName(false);
        setEditedName('');
      } else {
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const inputRef = useOutsideClick(updateSequenceData);

  useEffect(() => {
    sequenseData();
    getActivitesCardData();
    getPerformenceMetrics(getDates('last7days'));
  }, []);

  useEffect(() => {
    setUpdatedPerformanceData(
      PERFORMENCE_TABS_FILTER?.map((item) => {
        switch (item.label) {
          case 'Triggered':
            return { ...item, count: performenceData?.TRIGGERED || 0 };
          case 'Failure':
            return { ...item, count: performenceData?.FAILED || 0 };
          case 'Completed':
            return { ...item, count: performenceData?.COMPLETED || 0 };
          case 'Abandoned':
            return { ...item, count: performenceData?.ABANDONED || 0 };
          default:
            return item;
        }
      })
    );
  }, [performenceData]);

  const handlePerformance = (value) => {
    setFilter((prevFilter) => {
      if (prevFilter.includes(value)) return [];
      else return [value];
    });
  };

  const convertObjectToArray = (obj) => {
    return Object.entries(obj).map(([key, value]) => {
      return { key, value };
    });
  };

  const convertArrayToObject = (arr) => {
    return arr.reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});
  };

  const filterDatas = (data, key) => {
    let updatedData = data;

    if (key) {
      const arrayData = convertObjectToArray(data);
      const res = arrayData?.filter((d) => d?.key === key);
      const convert = convertArrayToObject(res);

      updatedData = {
        TRIGGERED: data?.TRIGGERED || 0,
        FAILURE: data?.FAILED || 0,
        COMPLETED: data?.COMPLETED || 0,
        ABANDONED: data?.ABANDONED || 0,
        ...convert,
      };
    }
    setFilteredData(updatedData);
  };

  const sequenseData = async () => {
    setIsLoading(true);
    const payload = {
      brandId: currentBrand?.brand_id,
      campaignId: campaign_id,
      flow: 'false',
    };
    try {
      const res = await getSequence(sequence_id, payload);
      if (res?.status === 200) {
        setSequenceDetails(res?.data?.data?.sequence);
        setCampaignDetails(res?.data?.data?.campaign);
        setFlowDetails({
          nodes: res?.data?.data?.nodes,
          edges: res?.data?.data?.edges,
        });
        setEditedName(sequenceDetails?.name);
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.message,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const sequenseStatusUpdate = useCallback(async () => {
    let status = ['Draft', 'New']?.includes(getStatusStyles()?.text)
      ? 'activate'
      : ['Running', 'ACTIVE', 'SCHEDULED']?.includes(getStatusStyles()?.text)
      ? 'pause'
      : ['Paused']?.includes(getStatusStyles()?.text)
      ? 'start'
      : 'activate';
    const payload = {
      brand_id: currentBrand?.brand_id,
      campaign_id: campaign_id,
      state_action: status,
    };
    try {
      const res = await updateSequenceStatus(sequence_id, payload);
      if (res?.status === 200)
        setSequenceDetails((prev) => ({
          ...prev,
          status: res?.data?.sequence_status,
        }));
      else
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.message,
              status: 'Error',
              duration: '',
            },
          ])
        );
    } catch (error) {
      console.warn(error);
    }
  }, [sequenceDetails]);

  const getActivitesCardData = async () => {
    try {
      const payload = {
        brandId: currentBrand?.brand_id,
        campaignId: campaign_id,
      };
      const res = await getSequenceActivitesCard(sequence_id, payload);
      if (res?.status === 200) {
        const data = cardsArray?.map((card) => {
          return { ...card, value: res?.data[card?.key] };
        });
        setCardsMetrics(data);
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.message,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getPerformenceMetrics = async (dates) => {
    const payload = {
      brandId: currentBrand?.brand_id,
      campaignId: campaign_id,
      startData: dates?.startDate,
      endDate: dates?.endDate,
    };
    const res = await getSequenceOverviewPerformenceMetrics(
      sequence_id,
      payload
    );
    if (res?.status === 200) {
      setPerformenceData(res?.data?.data);
      filterDatas(res?.data?.data, filter?.[0] || null);
    }
  };

  const handleEdit = () => {
    setIsEditName(true);
    setEditedName(sequenceDetails?.name);
  };

  const onChange = (e) => {
    const newValue = e?.target?.value?.replace(/[^A-Za-z0-9-_ ]/g, '');
    setEditedName(newValue);
  };

  const takeScreenshot = async () => {
    try {
      const element = captureRef.current;

      element.style.position = 'relative';
      element.style.zIndex = '9999';
      element.style.padding = '0px';
      element.style.backgroundColor = '#f9f9f9';

      const canvas = await html2canvas(element, {
        scrollX: 0,
        scrollY: 0,
        useCORS: true,
        backgroundColor: '#f9f9f9',
      });

      // Convert canvas to image and trigger download
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'screenshot.png';
      link.click();
    } catch (error) {
      console.error('Error taking screenshot:', error);
    }
  };

  const getStatusStyles = useCallback(
    () => SEQUENCE_STATUS_STYLES[sequenceDetails?.status],
    [sequenceDetails]
  );

  const allowToEditStatus = useCallback(() => {
    const isActive = flowDetails?.nodes?.filter((n) => n?.status === 'ACTIVE');

    return (
      flowDetails?.nodes?.length >= 5 &&
      isActive?.length >= 5 &&
      flowDetails?.edges?.length > 4
    );
  }, [flowDetails]);

  const performencePopupContent = (
    <div className='w-full min-w-36 h-full flex flex-col px-1.5'>
      {PERFORMENCE_DATE_FILTERS_LIST?.map((list) => {
        return (
          <div
            className='w-full h-12 py-1.5 flex flex-1 items-center justify-center border-b border-[var(--border-100)] cursor-pointer last:border-none'
            onClick={async () => {
              setSelectedDateFilter(list);
              if (list?.key !== 'custom') {
                const dates = getDates(list?.key);
                await getPerformenceMetrics(dates);
                setIsOpenDatesFilter(false);
              } else {
                setIsOpenCustomDateFilter(true);
              }
            }}>
            <div className='w-full h-9 flex items-center justify-center flex-1 text-sm weight-medium hover:bg-[var(--BG-50)] rounded-md'>
              {list?.label}
            </div>
          </div>
        );
      })}
    </div>
  );

  if (isLoading)
    return (
      <div className='flex-row align-center justify-center'>
        <Loader
          Width={20}
          Height={20}
          // loaderBg='white'
        />
      </div>
    );

  return (
    <div
      className='w-full h-screen flex flex-col gap-7 px-7 py-8 max-[1600px]:px-5 max-[1600px]:py-6 max-[1600px]:gap-4'
      ref={captureRef}>
      <Breadcrumbs breadcrumbs={breadcumbsData} />
      <div className='w-full h-[148px] bg-white p-4 rounded-2xl flex items-center gap-4'>
        <div className='w-1/2 flex flex-1 flex-col justify-center gap-2.5'>
          <div className='flex items-center gap-2'>
            <p
              className={`size-4 rounded contents-['']`}
              style={{ background: campaignDetails?.display_color }}
            />
            <h5 className='w-fit max-w-52 truncate capitalize text-base weight-medium text-[var(--font-600)] max-[1440px]:text-sm'>
              {campaignDetails?.name}
            </h5>
            <Tooltip
              content='Campaign Name'
              position='right'>
              <img
                src={ICONS?.conversationAction}
                alt='info'
                className='size-5'
              />
            </Tooltip>
          </div>
          <div className='w-auto h-9 flex items-center gap-2.5'>
            <Switch
              isChecked={
                ['Running', 'Scheduled', 'Active']?.includes(
                  getStatusStyles()?.text
                )
                  ? true
                  : false
              }
              allow={allowToEditStatus()}
              toggleSwitch={(event) => {
                event.stopPropagation();
                event.preventDefault();
                if (allowToEditStatus()) sequenseStatusUpdate();
              }}
            />
            {isEditName ? (
              <div
                ref={inputRef}
                className='px-2.5 py-1 flex items-center gap-2.5 border border-[var(--border-100)] rounded-md'>
                <input
                  type='text'
                  value={editedName}
                  maxLength={MAX_SEQUENCE_LENGTH}
                  onChange={onChange}
                  className='text-sm weight-semibold text-[#2D3036]'
                />
                <p className='text-sm weight-medium text-[text-[#616874]]'>
                  {editedName?.length}/{MAX_SEQUENCE_LENGTH}
                </p>
              </div>
            ) : (
              <Tooltip content={sequenceDetails?.name}>
                <h4 className='w-fit max-w-52 truncate capitalize text-xl weight-bold text-[#2D3036] max-[1440px]:text-base'>
                  {sequenceDetails?.name}
                </h4>
              </Tooltip>
            )}
            {!isEditName && (
              <Tooltip
                content={'Edit'}
                onClick={handleEdit}>
                <EditIcon
                  width={24}
                  height={24}
                  className={'hover:bg-[var(--BG-50)] p-1 rounded-md'}
                />
              </Tooltip>
            )}
          </div>
          <div className='w-auto h-[30px] flex items-center'>
            <div
              className='w-fit h-full flex items-center gap-1.5 px-2 mr-2.5 rounded-md border-r border-gray-200'
              style={{
                background: getStatusStyles()?.bg,
              }}>
              {getStatusStyles()?.icon && (
                <img
                  src={getStatusStyles()?.icon}
                  alt={getStatusStyles()?.text}
                />
              )}
              <p className='text-sm weight-semibold text-white max-[1440px]:text-xs'>
                {getStatusStyles()?.text}
              </p>
            </div>
            <p className='text-sm weight-medium text-[var(--font-600)] ml-2.5 max-[1440px]:text-xs'>
              Duration{' '}
              {dateConverter(sequenceDetails?.start_date)?.formatedShortDate} -{' '}
              {dateConverter(sequenceDetails?.end_date)?.formatedShortDate}
            </p>
          </div>
        </div>
        <div className='w-1/2 flex flex-1 items-center justify-end gap-4'>
          <Link
            to={'flow'}
            className='flex items-center gap-1.5 px-3 py-2 border border-[#E8E8EA] rounded-md hover:bg-[#E8E8EA]'>
            <Flow />
            <p className='text-sm weight-medium max-[1440px]:text-xs'>
              Go to Flow
            </p>
          </Link>
          <Tooltip
            content={'Download Reports'}
            onClick={takeScreenshot}
            className={'-ml-[45px] mt-0.5'}>
            <Download className='border border-[#E8E8EA] w-11 h-[37px] py-2 px-0.5 rounded-md hover:bg-[#E8E8EA] cursor-pointer' />
          </Tooltip>
        </div>
      </div>

      <div className='w-full h-fit max-h-[calc(100vh-28vh)] flex-1'>
        <div className='w-full h-full flex gap-6 relative max-[1600px]:gap-4'>
          <div className='w-full !max-w-[76%] h-full flex flex-col flex-1 gap-6 rounded-2xl overflow-y-scroll max-[1600px]:gap-4'>
            <div className='w-full h-fit flex gap-6 max-[1600px]:!flex-col max-[1600px]:gap-4'>
              <div className='w-1/3 grid grid-cols-2 gap-6 max-[1600px]:gap-4 max-[1600px]:w-full max-[1600px]:grid-cols-4'>
                {cardsMetrics?.map((card) => {
                  return <Cards data={card} />;
                })}
              </div>

              <div className='w-2/3 bg-white p-4 rounded-2xl max-[1600px]:w-full'>
                <div className='w-full h-12 flex justify-between text-[var(--contentText)] border-b border-[#E8E8EA]'>
                  <h5 className='text-lg weight-semibold py-1'>Performance</h5>
                  <Popover
                    trigger={['click']}
                    open={isOpenDatesFilter}
                    afterOpenChange={(open) => setIsOpenDatesFilter(open)}
                    arrow={false}
                    content={
                      IsOpenCustomDateFilter ? (
                        <MonthCalender
                          allowedDate='next'
                          // changeYears={true}
                          open={IsOpenCustomDateFilter}
                          setOpen={setIsOpenCustomDateFilter}
                          primary={true}
                          customRanges={false}
                          startDate={filteredDates?.startDate}
                          endDate={filteredDates?.endDate}
                          onChange={async (value) => {
                            value = {
                              startDate: moment(value?.start).format(
                                'YYYY-MM-DD'
                              ),
                              endDate: moment(value?.end).format('YYYY-MM-DD'),
                            };
                            setFilteredDates(value);
                            await getPerformenceMetrics(value);
                            setIsOpenDatesFilter(false);
                          }}
                          rootClassName='w-full flex-1 justify-start bg-transparent border border-gray-200'
                          handleCancel={() => {
                            setIsOpenDatesFilter(false);
                          }}>
                          <></>
                        </MonthCalender>
                      ) : (
                        performencePopupContent
                      )
                    }
                    className='h-fit px-3 py-2 border border-[var(--border-input)] text-sm weight-medium flex items-center gap-2 rounded-md'>
                    <div
                      className='!cursor-pointer'
                      onClick={() => setIsOpenDatesFilter(!isOpenDatesFilter)}>
                      <p>
                        {selectedDateFilter?.key === 'custom'
                          ? `${moment(filteredDates?.startDate).format(
                              'YYYY-MM-DD'
                            )} - ${moment(filteredDates?.endDate).format(
                              'YYYY-MM-DD'
                            )}`
                          : selectedDateFilter?.label || 'Last 7 days'}
                      </p>
                      <Arrow className='-rotate-90' />
                    </div>
                  </Popover>
                </div>
                <div className=''>
                  <Performance
                    performanceList={filteredData}
                    startDate={filteredDates?.startDate}
                    endDate={filteredDates?.endDate}
                    updatedPerformanceData={updatedPerformanceData}
                    isBroadcast={false}
                    rootClassName='mr-0 !h-[38vh]'
                  />
                  <div className='flex-row align-center text-[var(--textBlack)] mt-10 pt-5'>
                    {updatedPerformanceData?.map((item) => (
                      <div
                        key={item?.id}
                        className={`w-full flex-col items-center gap-[1.3vw] border-r-[1px] last:border-r-0 cursor-pointer`}
                        onClick={() => {
                          handlePerformance(item?.key);
                          filterDatas(performenceData, item?.key);
                        }}>
                        <div
                          className={`rounded-lg w-95 m-5 p-5 ${
                            filter?.includes(item?.key) &&
                            item?.label !== 'Conversions'
                              ? 'bg-[#E8E8EA]'
                              : ''
                          }`}>
                          <div className='flex-row justify-center items-center'>
                            <img
                              src={item?.icon}
                              alt='summary'
                              className='w-5'
                            />
                            <span className='no-search-body px-2'>
                              {item?.label}
                            </span>
                            <Tooltip content={`Overall ${item?.label} Count`}>
                              <img
                                src={ICONS?.conversationAction}
                                alt='performanceIcon'
                                className=''
                              />
                            </Tooltip>
                          </div>
                          <div className='flex-row justify-center pt-5'>
                            <span
                              className={` ${
                                item?.count === undefined ||
                                item?.label === 'Conversions'
                                  ? 'count-style-light'
                                  : 'count-style'
                              }`}>
                              {item?.count || 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <ContactList sequenceDetails={sequenceDetails} />
          </div>

          <div className='w-full !max-w-[24%] h-full max-h-[calc(100vh-28vh)] flex-1 overflow-hidden'>
            <Preview />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SequenceMetrix;
