import removeUser from "./remove-user.svg";
import accountOwnerPriority from "./account-owners-priority.svg";
import verifiedTick from "./verified-tick.svg";
import changeRole from "./change-role.svg";
import roleSuccessIcon from "./role-success.svg";

import razorPay from "./razorpay.svg";
import creditsSideBar from "./credits-sidebar.svg";
import stoppedIcon from "./stopped.svg";

export const ICONSS = {
  gainwixLogo:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/gainwix-logo.svg",
  sidenav:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/sidenav.svg",
  homeSelected:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/selected-home.svg",
  campaignSelected:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/selected-campaign.svg",
  calendarSelected:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/selcected-calendar.svg",
  inboxSelected:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/selected-inbox.svg",
  crmSelected:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/selected-crm.svg",
  channelSelected:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/selected-channel.svg",
  logout:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/logout.svg",
  profileBoy:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/profile-boy.svg",
  helpSupport:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/helpSupport.svg",
  notifications:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/notifications.svg",
  settingSelected:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/selected-setting.svg",
  profile:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/profile.svg",

  // sign up

  friend_user:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/friend_user.svg",
  website:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/website.svg",

  //header-dropdown
  freePlan:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/free-plan.svg",
  starterPlan:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/starter-plan.svg",
  magnumPlan:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/magnum-plan.svg",
  growthPlan:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/growth-plan.svg",

  //account-center

  brandLogo:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/brand-info-profile.svg",

  switchAccount:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/siwtch-account.svg",
  starterActionIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/starter-action.svg",
  starterPlanIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/starter-plan.svg",
  growthVerify:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/growth-verification.svg",
  magnumPlanIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/magnum-plan.svg",
  conversationCredits:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/conversation-credits.svg",
  businessVerification:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/business-verification.svg",
  paymentMethod:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/payment-method.svg",
  creditMarketing:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/credits-marketing.svg",
  creditUtility:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/credits-utility.svg",
  creditAuthentication:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/credits-authentication.svg",
  creditService:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/credits-services.svg",
  dotStyleIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/dot-style.svg",
  billingHistory:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/billing-history.svg",
  starterVerify:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/starter-verify.svg",
  starterVerifyication:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/starter-verification.svg",
  growthPlanIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/growth-plan.svg",
  magnumVerify:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/magnum-verification.svg",
  alertRole:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/alert-role.svg",
  members:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/members.svg",
  creditUsage:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/credit-usage.svg",
  debitUsage:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/debit-usage.svg",

  // account-brands

  timeCircle:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/time-circle.svg",
  inactive_brand:
    "https://storage.googleapis.com/asp-v1-images/assets/gainwix-images/inactive_brands.svg",

  removeUser: removeUser,
  accountOwnerPriority: accountOwnerPriority,
  verifiedTick: verifiedTick,
  changeRole: changeRole,
  roleSuccessIcon: roleSuccessIcon,

  razorPay: razorPay,
  creditsSideBar: creditsSideBar,
  stoppedIcon: stoppedIcon,
};
