import React from 'react';
import { useParams } from 'react-router-dom';

// api
import { createNode } from '../../../api/sequence';

// assets
import AddIcon from 'assets/customSVG/AddIcon';
import AI from 'assets/customSVG/campaigns/AI';
import FitView from 'assets/customSVG/campaigns/FitView';
import Redo from 'assets/customSVG/campaigns/Redo';
import Undo from 'assets/customSVG/campaigns/Undo';
import VersionHistory from 'assets/customSVG/campaigns/VersionHistory';
import ZoomIn from 'assets/customSVG/campaigns/ZoomIn';
import ZoomOut from 'assets/customSVG/campaigns/ZoomOut';

// helper
import { Popover } from 'antd';
import { cn } from '../../../../../helper/cn';
import { generateRandomId } from '../../../helpers';

// constants
import { ZoomInValue, ZoomOutValue } from '../../../constants/defaults';
import { INITIAL_NODE_TRIGGER_DATA } from '../../../constants/triggers';

// hooks
import useToggle from '../../../../../hooks/useToggle';

// redux
import { updateToggleToast } from '../../../../../reduxToolkit/appSlice';
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../test/jest-redux-hooks';

// components
import TriggerModel from '../triggers/TriggerModel';
import Tooltip from '../../../../../components/commonComponents/tooltip';

const newNode = {
  id: 800,
  uniq_id: '814937fd449540eaa030b7b316361a8a',
  is_trigger: false,
  component_type: null,
  trigger_type: null,
  type: 'TEXT',
  label: 'Send Message',
  order: 7,
  status: 'UN_SAVED',
  data: {},
  settings: {
    defaults: {
      isExpand: false,
      isEditMode: true,
      isSelected: false,
      showControlls: false,
    },
    connections: { count: 0, points: [] },
  },
  coordinates: {},
  is_last_node: false,
};

export default function Controls({
  scale,
  handleZoomIn,
  handleZoomOut,
  handleFitView,
  handleUndo,
  handleRedo,
  disabled,
  onChange,
  findEmptySpace,
  allowToAddNode,
  allowToEditFlow,
  sequenceDetails,
}) {
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const { sequence_id, campaign_id } = useParams();
  const [openTrigger, setOpenTrigger] = useToggle(false);

  const onTriggerClick = async (data) => {
    // const payload = {
    //   brand_id: currentBrand?.brand_id,
    //   campaign_id: campaign_id,
    //   node_type: data?.key,
    //   label: 'Send Message',
    //   coordinates: findEmptySpace(),
    //   save: false,
    //   message_body: INITIAL_NODE_TRIGGER_DATA[data?.key],
    // };

    if (allowToAddNode()?.allow) {
      const node = {
        ...newNode,
        label: 'Send Message',
        type: data?.key,
        data: INITIAL_NODE_TRIGGER_DATA[data?.key],
        id: generateRandomId(),
        uniq_id: generateRandomId(),
      };

      onChange({
        type: 'UPDATE_DATA',
        data: { selectedNode: [node] },
      });

      setOpenTrigger(false);
    }
    // else {
    //     dispatch(
    //       updateToggleToast([
    //         ...toggleToast,
    //         {
    //           id: toggleToast?.length + 1,
    //           content: `Failed to create ${data?.key} NODE`,
    //           status: 'Error',
    //           duration: '',
    //         },
    //       ])
    //     );
    //   }
    // }
  };

  return (
    <div className='w-fit min-h-[50px] bg-white z-50 rounded-[10px] shadow-[0px_4px_16px_0px_#7B6FA329] py-2.5 mt-2.5 flex items-center select-none'>
      {!['COMPLETED']?.includes(sequenceDetails?.status) && (
        <Tooltip content='Add New Node'>
          <Popover
            trigger={
              allowToEditFlow && allowToAddNode()?.allow ? ['click'] : []
            }
            arrow={false}
            open={openTrigger}
            placement='bottomLeft'
            onOpenChange={(open) => {
              if (allowToAddNode()?.allow) setOpenTrigger(open);
            }}
            content={
              <div className='mt-3'>
                <TriggerModel
                  type={'list'}
                  open={true}
                  onTriggerClick={onTriggerClick}
                  allow={allowToAddNode()?.allow}
                />
              </div>
            }>
            <div
              className={cn(
                'px-4 border-r',
                allowToEditFlow && allowToAddNode()?.allow
                  ? 'cursor-pointer border-[var(--border-100)]'
                  : 'cursor-not-allowed border-gray-200'
              )}>
              <AddIcon
                width={32}
                height={32}
                stroke={
                  allowToEditFlow && allowToAddNode()?.allow
                    ? 'var(--primary)'
                    : ''
                }
                fill={
                  allowToEditFlow && allowToAddNode()?.allow
                    ? 'var(--primaryLight)'
                    : ''
                }
                className={
                  allowToEditFlow && allowToAddNode()?.allow
                    ? ''
                    : 'stroke-gray-500 fill-gray-200'
                }
                rectClassName={
                  allowToEditFlow && allowToAddNode()?.allow
                    ? ''
                    : '!stroke-gray-200'
                }
              />
            </div>
          </Popover>
        </Tooltip>
      )}
      {/* <div className='px-4 border-r border-[var(--border-100)] flex items-center gap-2.5'>
        <div
          className={cn(
            'group/undo w-9 h-8 p-2 flex items-center justify-center rounded-md',
            !allowToEditFlow && disabled?.undo
              ? 'cursor-default'
              : 'cursor-pointer hover:bg-[var(--primaryLight)]'
          )}
          onClick={() => {
            if (allowToEditFlow) handleUndo();
          }}
        >
          <Undo
            width={20}
            height={14}
            color=''
            className={cn(
              !allowToEditFlow && disabled?.undo
                ? 'cursor-default stroke-[#D1D3D8]'
                : 'cursor-pointer stroke-[var(--font-600)] group-hover/undo:stroke-[var(--primary)]'
            )}
          />
        </div>
        <div
          className={cn(
            'group/redo w-9 h-8 p-2 flex items-center justify-center rounded-md',
            !allowToEditFlow && disabled?.redo
              ? 'cursor-default'
              : 'cursor-pointer hover:bg-[var(--primaryLight)]'
          )}
          onClick={() => {
            if (allowToEditFlow) handleRedo();
          }}
        >
          <Redo
            width={20}
            height={14}
            color=''
            className={cn(
              allowToEditFlow && disabled?.redo
                ? 'stroke-[#D1D3D8] cursor-pointer'
                : 'stroke-[var(--font-600)] group-hover/redo:stroke-[var(--primary)] cursor-not-allowed'
            )}
          />
        </div>
      </div> */}
      <div className='px-5 border-r border-[var(--border-100)] flex items-center gap-2.5'>
        <Tooltip content='Zoom Out'>
          <div
            className={cn(
              'w-11 h-8 p-2 flex items-center justify-center rounded-md',
              scale > ZoomOutValue
                ? 'group/zoomIn hover:bg-[var(--primaryLight)] cursor-pointer'
                : 'cursor-not-allowed'
            )}
            onClick={(e) => {
              if (handleZoomOut && scale > ZoomOutValue) handleZoomOut(e);
            }}>
            <ZoomOut
              width={22}
              height={16}
              color=''
              className={cn(
                scale > ZoomOutValue
                  ? 'stroke-[var(--font-600)] group-hover/zoomIn:stroke-[var(--primary)] cursor-pointer'
                  : 'stroke-[#D1D3D8] cursor-not-allowed'
              )}
            />
          </div>
        </Tooltip>

        <Tooltip content='Zoom In'>
          <div
            className={cn(
              'group/zoomOut w-11 h-8 p-2 flex items-center justify-center rounded-md',
              scale < ZoomInValue
                ? 'group/zoomOut cursor-pointer hover:bg-[var(--primaryLight)]'
                : 'cursor-not-allowed'
            )}
            onClick={(e) => {
              if (handleZoomIn && scale < ZoomInValue) handleZoomIn(e);
            }}>
            <ZoomIn
              width={22}
              height={16}
              color=''
              className={cn(
                scale < ZoomInValue
                  ? 'fill-[var(--font-600)] group-hover/zoomOut:fill-[var(--primary)] cursor-pointer'
                  : 'fill-[#D1D3D8] cursor-not-allowed'
              )}
            />
          </div>
        </Tooltip>

        <Tooltip content='Fit to View'>
          <div
            className='group/fitview w-11 h-8 p-2 flex items-center justify-center rounded-md cursor-pointer hover:bg-[var(--primaryLight)]'
            onClick={(e) => {
              handleFitView(e);
            }}>
            <FitView
              width={21}
              height={15}
              color=''
              className={cn(
                'stroke-[var(--font-600)] group-hover/fitview:stroke-[var(--primary)]'
              )}
            />
          </div>
        </Tooltip>
        {/* <div className='group/ai w-9 h-8 p-2 flex items-center justify-center rounded-md cursor-pointer hover:bg-[var(--primaryLight)]'>
          <AI width={20} height={14} />
        </div> */}
        {/* <div className='group/version w-9 h-8 p-[7px] flex items-center justify-center rounded-md cursor-pointer hover:bg-[var(--primaryLight)]'>
          <VersionHistory
            width={24}
            height={18}
            color=''
            className={cn(
              'stroke-[var(--font-600)] group-hover/version:stroke-[var(--primary)]'
            )}
          />
        </div> */}
      </div>
      <div className='px-5 flex items-center gap-2 text-base weight-semibold text-[var(--font-600)]'>
        <span>
          {allowToAddNode()?.nodesLength}/{allowToAddNode()?.maxNodes}
        </span>
        <span>Nodes</span>
      </div>
    </div>
  );
}
