import React, { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useOutlet,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { Modal, Popover } from 'antd';
import moment from 'moment';

// api
import { broadcastList } from '../../../api/apiClients/campaign';
import { broadCastListApi } from '../api';

// constants
import { CREATION_TYPES, SORT, STATUS, TYPES } from '../constants/Execution';

// helpers
import { cn } from '../../../helper/cn';
import { convertToCustomFormat } from '../../../helper/dateConverter';

// hooks
import useToggle from '../../../hooks/useToggle';
import { useSearch } from '../../../hooks/useSearch';

// constants
import { MAX_SEQUENCE_LENGTH } from '../constants/sequence';

// api
import { createSequence } from '../api/sequence';

// redux
import { useAspDispatch, useAspSelector } from '../../../test/jest-redux-hooks';
import { reset } from '../../../reduxToolkit/SequenceSlice';
import { updateToggleToast } from '../../../reduxToolkit/appSlice';

// components
import SearchBar from '../../../components/commonComponents/searchBar';
import ClearFilters from '../../../assets/customSVG/ClearFilters';
import CheckBoxSqure from '../../../components/commonComponents/checkbox/CheckBoxSqure';
import List from '../components/executions/List';
import Header from '../../../components/commonComponents/modals/Header';
import MonthCalender from '../../../components/commonComponents/calendar/MonthCalender';

let page = 1;
let limit = 10;
let count = 0;
let sequence_id = null;

export default function Execution() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const broadcastdata = location?.state?.data;

  const { campaignOverviewData } = useOutletContext();

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isFilters, setIsFilters] = useToggle(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useToggle(false);

  const [sort, setSort] = useState(null);
  const [sortLabel, setSortLabel] = useState('');

  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const { searchKey, setSearchKey, onChange: onSearchChange } = useSearch('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [statusTrue, setStatusTrue] = useState(false);

  const [selectedSortId, setSelectedSortId] = useState(null);
  const [selectedGoalIds, setSelectedGoalIds] = useState(null);
  const [selectedStatusId, setSelectedStatusId] = useState([]);

  const [isCreate, setIsCreate] = useToggle(false);
  const [isSequenceModelOpen, setIsSequenceModelOpen] = useToggle(false);
  const [sequenceData, setSequenceData] = useState({
    name: '',
    start_date: null,
    end_date: null,
  });

  let data = {};
  sort?.map((s) => {
    if (s?.id === 1) {
      s?.options?.map((o) => {
        if (o?.isSelected) data['sort_by'] = o?.status;
      });
    }

    if (s?.id === 2) {
      s?.options?.map((o) => {
        if (o?.isSelected) data['order_by'] = o?.status;
      });
    }
  });

  useEffect(() => {
    setType(TYPES);
    setStatus(STATUS);
    setSort(SORT);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      limit = 10;
      page = 1;
      const payload = {
        page: page,
        limit: limit,
        search: searchKey,
      };

      const statusList = getSelectedStatus();
      const typesList = getSelectedTypesId();

      if (statusList?.length > 0) payload['status'] = statusList;
      if (typesList?.length > 0) {
        // const typesKeys = typesList?.filter(t=>);
        // payload['type'] = [];
      }

      getCampaignList(false, currentBrand?.brand_id, id, payload);
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchKey]);

  useEffect(() => {
    const statusList = getSelectedStatus();

    limit = 10;
    page = 1;
    const payload = {
      page: page,
      limit: limit,
      search: searchKey,
    };

    if (statusList?.length > 0) payload['status'] = statusList;
    else payload['status'] = null;
    if (selectedTypes?.length > 0) {
      // const typesKeys = typesList?.filter(t=>);
      payload['type'] =
        selectedTypes?.length === TYPES?.length ? 'all' : selectedTypes;
    } else payload['type'] = null;

    getCampaignList(false, currentBrand?.brand_id, id, payload);
  }, [status, type, selectedTypes]);

  useEffect(() => {
    getSelectedTypesId();
  }, [type]);

  useEffect(() => {
    getSelectedStatusId();
  }, [status]);

  useEffect(() => {
    getSelectedSortId();
  }, [sort]);

  // sort filter
  const onSortChange = (id, data) => {
    const res = sort?.map((s) => {
      if (s.id === id) {
        const updatedOptions = s?.options?.map((o) => {
          if (o?.id === data?.id) return { ...o, isSelected: true };
          return { ...o, isSelected: false };
        });
        return { ...s, options: updatedOptions };
      } else {
        const updatedOptions = s?.options?.map((o) => {
          return { ...o, isSelected: false };
        });
        return { ...s, options: updatedOptions };
      }
    });

    const selectedOptions = res
      .flatMap((s) => s?.options)
      .filter((option) => option?.isSelected);
    setSortLabel(selectedOptions?.[0]?.label);

    setSort(res);
  };

  const clearSort = () => {
    const res = sort?.map((s) => {
      return { ...s, isSelected: false };
    });
    setSort(res);
  };

  const getSelectedSortId = useCallback(
    () => setSelectedSortId(sort?.filter((t) => t?.isSelected)),
    [sort]
  );

  // type filter
  const onTypeChange = (data) => {
    const res = type?.map((t) => {
      if (t?.id === data?.id) return { ...t, isSelected: !t?.isSelected };
      return t;
    });
    const filters = res?.filter((r) => r?.isSelected);
    const getFilteresId = filters?.map((f) => f?.key);
    setSelectedTypes(getFilteresId);
    setType(res);
  };

  const clearType = () => {
    const res = type?.map((t) => {
      return { ...t, isSelected: false };
    });
    setType(res);
    setSelectedTypes([]);
  };

  const getSelectedTypesId = useCallback(
    () => setSelectedGoalIds(type?.filter((t) => t?.isSelected)),
    [type]
  );

  // status filter
  const onStatusChange = (data) => {
    const res = status?.map((s) => {
      if (s?.id === data?.id) return { ...s, isSelected: !s?.isSelected };
      return s;
    });
    setStatus(res);
    setStatusTrue(true);
  };

  const clearStatus = () => {
    const res = status?.map((t) => {
      return { ...t, isSelected: false };
    });
    setStatus(res);
    setStatusTrue(true);
  };

  const getSelectedStatus = useCallback(() => {
    const res = status?.filter((s) => s?.isSelected === true);
    const ids = res?.map((r) => r?.key?.toUpperCase());
    return ids?.length === STATUS?.length ? null : ids;
  }, [status]);

  const getSelectedStatusId = useCallback(() => {
    const res = type?.filter((t) => t?.isSelected === true);
    const ids = res?.map((r) => r?.key);
    return ids?.length === type?.length ? 'all' : ids;
  }, [status]);

  // modals
  const onCreateModal = (open) => setIsCreate(open);

  const handleCancel = () => setIsCreate(false);

  // API
  const getCampaignList = async (loading, brand_id, id, payload) => {
    setIsLoadingSkeleton(true);

    try {
      // const res = await broadCastListApi(brand_id, id, payload);
      const res = await broadcastList(brand_id, id, payload);
      setIsLoadingSkeleton(false);
      if (page === 1) {
        count = res?.data?.count;
        setList(res?.data?.data);
      } else {
        setList([...list, ...res?.data?.data]);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingSkeleton(false);
    }
  };

  const loadNextPage = async () => {
    if (list?.length < count) {
      // limit += 10;
      page += 1;
      const payload = {
        page: page,
        limit: limit,
        search: searchKey,
      };

      const statusList = getSelectedStatus();
      if (statusList?.length > 0) payload['status'] = statusList;

      await getCampaignList(true, currentBrand?.brand_id, id, payload);
    }
  };

  const allowToCreateNewSequence = useCallback(() => {
    const today = moment();
    const campStartDate = moment(campaignOverviewData?.starting_date_and_time);
    const campEndDate = moment(campaignOverviewData?.ending_date_and_time);
    const seqStartDate = moment(sequenceData?.start_date);
    const seqEndDate = moment(sequenceData?.end_date);

    // const checkingSeqStartDate = seqStartDate.isSameOrAfter(today);
    // const checkingSeqStartDateWithCampStartDate =
    //   seqStartDate.isSameOrAfter(campStartDate);
    // const checkingSeqEndDateWithCampEndDate =
    //   seqEndDate.isSameOrBefore(campEndDate);

    const checkingSeqStartDate =
      today.format('YYYY-MM-DD') <= seqStartDate.format('YYYY-MM-DD');
    const checkingSeqStartDateWithCampStartDate =
      campStartDate.format('YYYY-MM-DD') <= seqStartDate.format('YYYY-MM-DD');

    const checkingSeqEndDateWithCampEndDate =
      seqEndDate.format('YYYY-MM-DD') <= campEndDate.format('YYYY-MM-DD');

    const isName =
      sequenceData?.name?.length > 0 &&
      sequenceData?.name?.length <= MAX_SEQUENCE_LENGTH;

    const isStartandEndDateAreSame =
      seqStartDate.format('YYYY-MM-DD') !== seqEndDate.format('YYYY-MM-DD');

    return (
      checkingSeqStartDate &&
      checkingSeqStartDateWithCampStartDate &&
      checkingSeqEndDateWithCampEndDate &&
      isName &&
      isStartandEndDateAreSame
    );
  }, [sequenceData, campaignOverviewData]);

  const createNewSequence = async () => {
    const payload = {
      brand_id: currentBrand?.brand_id,
      campaign_id: id,
      start_date: convertToCustomFormat(sequenceData?.start_date),
      end_date: convertToCustomFormat(sequenceData?.end_date),
      name: sequenceData?.name,
    };
    try {
      const res = await createSequence(payload);

      if (res?.status === 200) {
        navigate(
          `/user/campaign/${id}/sequence/${res?.data?.data?.sequence?.id}/flow`
        );
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: 'Error',
              duration: '',
            },
          ])
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.warn(error);
      dispatch(
        updateToggleToast([
          ...toggleToast,
          {
            id: toggleToast?.length + 1,
            content: res?.response?.data?.message,
            status: 'Error',
            duration: '',
          },
        ])
      );
    } finally {
      setIsLoading(false);
    }
  };

  const allowAllClear = useCallback(() => {
    return selectedGoalIds?.length > 0 || selectedStatusId?.length > 0;
  }, [selectedGoalIds, selectedStatusId]);

  return (
    <div className='w-full h-full bg-white rounded-t-2xl px-5'>
      <div className='w-full py-5 flex items-center justify-between gap-5 border-b border-[var(--border-50)]'>
        <h4 className='new-header3'>
          {/* {list?.length > 0
            ? `${list?.length} Execution List`
            : `0 Execution List`} */}
          {count} Execution List
        </h4>
        <div className='flex items-center gap-5'>
          <div>
            <SearchBar
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              onChange={onSearchChange}
              loading={false}
              expandable={true}
            />
          </div>
          <Popover
            trigger={['click']}
            arrow={false}
            placement='bottom'
            content={
              <div className='min-w-56 px-3.5 py-2.5'>
                <div className='w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium'>
                  <div className='text-[var(--font-400)]'>Type</div>
                  <div
                    className={cn(
                      'text-[#F96056]',
                      selectedTypes?.length > 0
                        ? 'cursor-pointer opacity-100'
                        : 'cursor-not-allowed opacity-50'
                    )}
                    onClick={() => {
                      if (selectedTypes?.length > 0) clearType();
                    }}>
                    Clear
                  </div>
                </div>
                {type?.map((t) => {
                  return (
                    <div
                      key={t?.id}
                      className={cn(
                        'w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer'
                      )}
                      onClick={() => {
                        onTypeChange(t);
                      }}>
                      <CheckBoxSqure
                        value={t?.isSelected}
                        onChange={(e) => {}}
                        className={`size-4`}
                      />
                      {t?.icon && (
                        <img
                          src={t?.icon}
                          alt={t?.label}
                        />
                      )}
                      <p>{t?.label}</p>
                    </div>
                  );
                })}
              </div>
            }>
            <div className='group/goal flex items-center gap-2.5 bg-[var(--BG-25)] px-5 py-1.5 rounded-lg cursor-pointer'>
              <div className='campaign-filters flex item-center'>
                Type
                <div
                  className={`ml-1 ${
                    selectedTypes?.length === TYPES?.length ||
                    selectedTypes?.length === 0
                      ? ''
                      : 'rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center'
                  }`}>
                  {selectedTypes?.length === TYPES?.length ||
                  selectedTypes?.length === 0
                    ? '(All)'
                    : selectedTypes?.length > 0 &&
                      selectedTypes?.length !== TYPES?.length
                    ? selectedTypes?.length
                    : type?.filter((t) => t?.isSelected)?.[0]?.label}
                </div>
              </div>
              <div
                className={cn(
                  'transition-transform duration-200 ease-linear',
                  false ? '-rotate-180' : 'rotate-0',
                  isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
                )}>
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}></div>
              </div>
            </div>
          </Popover>
          <Popover
            trigger={['click']}
            arrow={false}
            placement='bottom'
            content={
              <div className='min-w-56 px-3.5 py-2.5'>
                <div className='w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium'>
                  <div className='text-[var(--font-400)]'>Status</div>
                  <div
                    className={cn(
                      'text-[#F96056]',
                      selectedStatusId?.length > 0
                        ? 'cursor-pointer opacity-100'
                        : 'cursor-not-allowed opacity-50'
                    )}
                    onClick={() => {
                      if (selectedStatusId?.length > 0) clearStatus();
                    }}>
                    Clear
                  </div>
                </div>
                {status?.map((s) => {
                  return (
                    <div
                      key={s?.id}
                      className={cn(
                        'w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer'
                      )}
                      onClick={() => {
                        onStatusChange(s);
                      }}>
                      <CheckBoxSqure
                        value={s?.isSelected}
                        onChange={(e) => {}}
                        className='size-4'
                      />
                      {s?.icon && (
                        <img
                          src={s?.icon}
                          alt={s.label}
                        />
                      )}
                      <p>{s?.label}</p>
                    </div>
                  );
                })}
              </div>
            }>
            <div className='group/goal flex items-center gap-2.5 bg-[var(--BG-25)] px-5 py-1.5 rounded-lg cursor-pointer'>
              <div className='campaign-filters flex items-center'>
                Status
                <div
                  className={`ml-1 ${
                    selectedStatusId?.length === STATUS?.length ||
                    selectedStatusId?.length === 0
                      ? ''
                      : 'rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center'
                  }`}>
                  {selectedStatusId?.length === STATUS?.length ||
                  selectedStatusId?.length === 0
                    ? '(All)'
                    : selectedStatusId?.length}
                </div>
              </div>
              <div
                className={cn(
                  'transition-transform duration-200 ease-linear ',
                  false ? '-rotate-180' : 'rotate-0',
                  isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
                )}>
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}></div>
              </div>
            </div>
          </Popover>
          <div
            className={cn(
              'group/status flex items-center gap-2.5 hover:bg-[var(--BG-25)] px-2.5 py-2.5 rounded-lg',
              allowAllClear()
                ? 'cursor-pointer opacity-100'
                : 'cursor-not-allowed opacity-50'
            )}
            onClick={() => {
              if (allowAllClear()) {
                clearType();
                clearStatus();
              }
            }}>
            <ClearFilters
              width={16}
              height={16}
              color={allowAllClear() ? 'var(--contentText)' : 'var(--font-600)'}
            />
          </div>
          {campaignOverviewData?.status !== 12 && (
            <div
              className={cn(
                'px-5 py-1.5 bg-[var(--new-primary)] text-white border border-[var(--new-primary)] rounded-lg cursor-pointer'
              )}
              onClick={() => {
                onCreateModal(true);
              }}>
              Create New
            </div>
          )}
        </div>
      </div>

      <List
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        clearType={clearType}
        clearStatus={clearStatus}
        selectedTypes={selectedTypes}
        statusTrue={statusTrue}
        isLoading={isLoading}
        list={list}
        setList={setList}
        count={count}
        loadNextPage={loadNextPage}
        isLoadingSkeleton={isLoadingSkeleton}
        onCreate={() => onCreateModal(true)}
      />

      <Modal
        open={isCreate}
        afterOpenChange={(open) => {
          onCreateModal(open);
        }}
        // onCancel={handleCancel}
        width={600}
        centered
        footer={null}
        closable={false}
        wrapClassName={'bg-[#00000095]'}
        destroyOnClose={true}>
        <Header
          title='Create New'
          subTitleStyle={'text-sm'}
          titleStyle='text-lg'
          titleSize='none'
          subTitleSize='none'
          handleCancel={handleCancel}
        />
        <div className='w-full h-full py-5 px-5 flex-column gap-5'>
          {CREATION_TYPES?.map((t) => {
            const isAccountHasAccess = currentBrand?.account_sequence_access;
            const isDisabled =
              (['Starter Plan']?.includes(
                currentBrand?.brand_billing_plan_name
              ) ||
                !isAccountHasAccess) &&
              t.key === 'sequence';

            return (
              <div
                key={t?.id}
                className={cn(
                  'group w-100  border border-[var(--border-50)] p-1 rounded-lg flex-row align-center gap-5 transition-all ease-linear duration-150 cursor-pointer hover:border-[#6940F2] hover:shadow-[0px_4px_30px_0px_#2D30361A]',
                  isDisabled || t?.disabled
                    ? 'cursor-not-allowed opacity-50'
                    : 'cursor-pointer'
                )}
                onClick={() => {
                  if (!t?.disabled && t.key !== 'sequence') {
                    navigate(`/user/campaign/time_specified/settings/${id}`, {
                      state: { data: campaignOverviewData },
                    });
                  }

                  if (!isDisabled && !t.disabled && t.key === 'sequence') {
                    dispatch(reset());
                    // createNewSequence();
                    onCreateModal();
                    setIsSequenceModelOpen(true);
                  }
                }}>
                <img
                  src={t?.icon}
                  alt={`${t?.label} image`}
                  className='w-auto h-20'
                />
                <div className='flex-column'>
                  <p className='popover-title'>{t?.label}</p>
                  <p className='popover-content'>{t?.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
        open={isSequenceModelOpen}
        afterOpenChange={(open) => {
          setIsSequenceModelOpen(open);
        }}
        centered
        footer={null}
        closable={false}
        wrapClassName={'bg-[#00000095]'}
        className={'!max-w-[630px] !w-full flex-1'}
        destroyOnClose={true}>
        <Header
          title='Create Sequence'
          subTitleStyle={'text-sm'}
          titleStyle='text-lg'
          titleSize='none'
          subTitleSize='none'
          handleCancel={() => {
            setIsSequenceModelOpen(false);
            setSequenceData({
              name: '',
              start_date: null,
              end_date: null,
            });
          }}
        />
        <div className='px-6 py-3.5 pt-6 flex flex-col gap-5'>
          <div className='w-full h-24 flex flex-col gap-3.5'>
            <label className='text-base weight-semibold'>
              Name Your Sequence <span className='text-[#FF543E]'>*</span>
            </label>
            <div className='w-full h-12 border border-gray-200 bg-transparent px-2.5 py-1 rounded-md flex items-center gap-2.5'>
              <input
                type='text'
                value={sequenceData?.name}
                placeholder='Enter sequence name'
                className='w-full h-full flex-1 outline-none text-sm weight-medium text-[#2D3036]'
                maxLength={MAX_SEQUENCE_LENGTH}
                onChange={(e) => {
                  const newValue = e?.target?.value?.replace(
                    /[^A-Za-z0-9-_ ]/g,
                    ''
                  );
                  setSequenceData({ ...sequenceData, name: newValue });
                }}
              />
              <p className='text-[#616874]'>
                {sequenceData?.name?.length}/{MAX_SEQUENCE_LENGTH}
              </p>
            </div>
          </div>
          <div className='w-full h-24 flex flex-col gap-3.5'>
            <label className='text-base weight-semibold'>
              Duration <span className='text-[#FF543E]'>*</span>
            </label>
            <div className='flex-1'>
              <MonthCalender
                allowedDate='allowed'
                dateRange={{
                  startDate: campaignOverviewData?.starting_date_and_time,
                  endDate: campaignOverviewData?.ending_date_and_time,
                }}
                // changeYears={true}
                primary={true}
                customRanges={false}
                placeholder='Please select the duration'
                startDate={sequenceData?.start_date}
                endDate={sequenceData?.end_date}
                onChange={(value) => {
                  setSequenceData({
                    ...sequenceData,
                    start_date: value?.start,
                    end_date: value?.end,
                  });
                }}
                rootClassName='w-full h-12 text-sm weight-medium text-[#2D3036] flex-1 justify-start bg-transparent border border-gray-200'
              />
            </div>
          </div>
          <div className='w-full h-16 flex items-center justify-end mt-3'>
            <button
              className={cn(
                'w-fit h-fit px-3.5 py-1 rounded-md text-base weight-medium',
                allowToCreateNewSequence()
                  ? 'bg-[#05805C] text-white cursor-pointer'
                  : 'bg-[var(--BG-100)] text-[var(--font-600)] cursor-not-allowed'
              )}
              onClick={() => {
                if (allowToCreateNewSequence()) createNewSequence();
              }}>
              Create Sequence
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
