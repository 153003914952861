import React, { useCallback, useEffect } from 'react';

// constants
import {
  ALLOWED_BODY_NODE_TYPES,
  ALLOWED_FOOTER_NODE_TYPES,
  DEFAULT_MEDIA_SIZE,
} from "../../../constants/sequence";

// helpers
import { addLineBreake } from "../../../../inbox/helper";
import { cn } from "../../../../../helper/cn";

// components
import AudioMessagePreview from "../../../../inbox/components/conversation/AudioMessagePreview";
import DocumentMessagePreview from "../../../../inbox/components/conversation/DocumentMessagePreview";
import ImageMessagePreview from "../../../../inbox/components/conversation/ImageMessagePreview";
import VideoMessagePreview from "../../../../inbox/components/conversation/VideoMessagePreview";
import EdgeHandler from "../edge/Handler";
import SectionRow from "./SectionRow";

const MediaMessage = ({
  previewMode = false,
  node,
  color,
  previewType = "node",
  onEdgeMouseDown,
  checkIsEdgeConnected,
  allowToShowEdge = true,
  rootClassName = "",
  customBodyClassName = "",
  customFooterClassName = "",
  onButtonClick,
  allowToEditFlow,
  nodeCurrentStatus,
}) => {
  const { id, type, data } = node;

  useEffect(() => {
    key();
    getheaderMediaData();
  }, [node?.data]);

  const key = useCallback(() => {
    return previewType === 'node'
      ? ['BUTTON', 'LIST']?.includes(type)
        ? data?.interactive?.header?.type?.toUpperCase()
        : data?.type?.toUpperCase()
      : node?.data?.type?.toUpperCase();
  }, [previewType, node, type]);

  const buttons = useCallback(
    () =>
      previewType === "node"
        ? data?.interactive?.action?.buttons
        : data?.interactive?.action?.buttons,
    [previewType, data]
  );

  const lists = useCallback(
    () =>
      previewType === "node"
        ? data?.interactive?.action?.sections
        : node?.data?.interactive?.action?.sections,
    [(previewType, data)]
  );

  const bodyText = useCallback(() => {
    return ["BUTTON", "LIST"]?.includes(type)
      ? addLineBreake(
          previewType === "node"
            ? data?.interactive?.body?.text
            : node?.data?.interactive?.body?.text
        )
      : addLineBreake(
          previewType === "node" ? data?.text?.body : node?.data?.text?.body
        );
  }, [type, previewType, data]);

  const getheaderMediaData = useCallback(
    (key) => {
      return {
        link:
          previewType === 'node'
            ? ['BUTTON', 'LIST']?.includes(node?.data?.type)
              ? node?.data?.interactive?.header?.[key?.toLowerCase()]?.link
              : node?.data?.[key?.toLowerCase()]?.link || ''
            : node?.data?.[key?.toLowerCase()]?.link,
        caption:
          previewType === 'node'
            ? ['BUTTON', 'LIST']?.includes(node?.data?.type)
              ? node?.data?.interactive?.header?.[key?.toLowerCase()]?.caption
              : node?.data?.[key?.toLowerCase()]?.caption || ''
            : node?.data?.[key?.toLowerCase()]?.caption,
      };
    },
    [node, previewType]
  );

  const mediaTypes = useCallback(() => {
    const defaultTypes = ['BUTTON', 'LIST'];
    return previewType === 'node'
      ? ['MEDIA', ...defaultTypes]
      : ['IMAGE', 'VIDEO', 'DOCUMENT', 'AUDIO', ...defaultTypes];
  }, [type, node, previewType]);

  return (
    <div
      className={cn(
        "w-full h-full bg-white rounded-xl flex flex-col gap-2.5",
        previewMode ? "p-1.5" : "p-3",
        rootClassName
      )}
    >
      {key() && key() !== "NONE" && mediaTypes()?.includes(type) && (
        <div className="">
          {key() === "IMAGE" ? (
            <ImageMessagePreview
              url={getheaderMediaData("image")?.link}
              caption={getheaderMediaData("image")?.caption}
              rootClassName={
                'max-w-64 min-w-full min-h-0 h-full p-0 rounded-lg overflow-hidden'
              }
              className={"w-full p-0"}
              imageClassName={cn(
                previewMode
                  ? 'h-full min-h-full'
                  : `h-[${DEFAULT_MEDIA_SIZE['image']}px]`
              )}
            />
          ) : key() === "VIDEO" ? (
            <VideoMessagePreview
              url={getheaderMediaData("video")?.link}
              caption={getheaderMediaData("video")?.caption}
              rootClassName={
                'max-w-64 min-w-full h-full min-h-0 !p-0 rounded-lg overflow-hidden'
              }
              className={"w-full"}
              videoClassName={`h-[${DEFAULT_MEDIA_SIZE["video"]}px]`}
            />
          ) : key() === "DOCUMENT" ? (
            <DocumentMessagePreview
              url={getheaderMediaData("document")?.link}
              caption={getheaderMediaData("document")?.caption}
              fileName={node?.data?.document?.filename}
              rootClassName={
                'max-w-64 min-w-full h-full min-h-0 !p-0 !rounded-2xl !overflow-hidden'
              }
              className={"w-full"}
              documentClassName={`h-[${DEFAULT_MEDIA_SIZE["document"]}px]`}
            />
          ) : key() === "AUDIO" ? (
            <AudioMessagePreview
              url={getheaderMediaData("audio")?.link}
              caption={getheaderMediaData("audio")?.caption}
              rootClassName={
                'max-w-full min-w-full h-full min-h-0 !p-0 rounded-lg overflow-hidden !bg-transparent !shadow-none'
              }
              className={"min-w-full w-full"}
              audioClassName={`w-full !h-[${DEFAULT_MEDIA_SIZE["audio"]}px] py-1`}
            />
          ) : key() === "TEXT" ? (
            <p
              className={cn(
                'w-full text-[0.813rem] text-[#2D3036] weight-semibold !break-words',
                !previewMode && 'line-clamp-1 overflow-hidden',
                customBodyClassName
              )}
            >
              {node?.data?.interactive?.header?.text}
            </p>
          ) : (
            ""
          )}
        </div>
      )}

      {/* Body */}
      {ALLOWED_BODY_NODE_TYPES?.includes(type) && (
        <p
          className={cn(
            'w-full text-[0.813rem] text-[#2D3036] weight-medium !break-words',
            !previewMode && 'line-clamp-1 overflow-hidden',
            customBodyClassName
          )}
          dangerouslySetInnerHTML={{ __html: bodyText() }}
        ></p>
      )}

      {/* Footer */}
      {ALLOWED_FOOTER_NODE_TYPES?.includes(type) &&
        data?.interactive?.footer?.text && (
          <p
            className={cn(
              'text-[0.813rem] text-[var(--font-400)] !break-words weight-medium',
              customFooterClassName
            )}
          >
            {["BUTTON", "LIST"]?.includes(type)
              ? data?.interactive?.footer?.text
              : ""}
          </p>
        )}

      {/* Buttons */}
      {type === "BUTTON" && (
        <div className="flex flex-col gap-2.5">
          {buttons()?.map((b) => {
            const connection_point = {
              id: b?.reply?.id,
              type: "action",
            };
            let isConnected = false;
            if (checkIsEdgeConnected)
              isConnected = checkIsEdgeConnected({
                start_node_id: node?.uniq_id,
                connection_point,
              });
            return (
              <div
                className="relative w-full h-[1.875rem] flex items-center border border-[#E8E8EA] bg-[#F8F9FC] rounded-[5px]"
                onClick={() => {
                  if (onButtonClick) onButtonClick(b);
                }}>
                <div className='relative w-full h-[1.875rem] text-[0.813rem] weight-medium text-[#007AFF] flex items-center justify-center'>
                  {b?.reply?.title}
                </div>
                {allowToShowEdge && (
                  <div
                    id={b?.reply?.id}
                    className="absolute top-2 right-1 z-50"
                  >
                    <EdgeHandler
                      type="start"
                      start_node_id={node?.uniq_id}
                      connection_point={{
                        id: b?.reply?.id,
                        type: "action",
                      }}
                      isConnected={isConnected}
                      onEdgeMouseDown={({ position }) => {
                        if (onEdgeMouseDown) {
                          if (!isConnected)
                            onEdgeMouseDown({
                              connection_point,
                              position: position,
                            });
                        }
                      }}
                      color={color}
                      allowToEditFlow={allowToEditFlow}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* List */}
      {type === "LIST" && (
        <div className="w-full flex flex-col gap-2.5">
          {data?.interactive?.action?.button && (
            <div className='relative w-full h-[1.875rem] text-[0.813rem] weight-medium text-[#007AFF] flex items-center justify-center border border-[#E8E8EA] bg-[#F8F9FC] rounded-[5px]'>
              {data?.interactive?.action?.button}
            </div>
          )}
          <div className="flex flex-col gap-2.5">
            {lists()?.map((section) => {
              return (
                <div className='flex flex-col gap-1'>
                  <h5 className='w-full h-[1.875rem] flex items-center overflow-hidden truncate text-[0.813rem] weight-bold text-[#2D3036]'>
                    {section?.title}
                  </h5>
                  <div className="flex flex-col gap-2">
                    {section?.rows?.map((row, i) => {
                      const connection_point = { id: row?.id, type: "action" };
                      let isConnected = false;
                      if (checkIsEdgeConnected) {
                        isConnected = checkIsEdgeConnected({
                          start_node_id: node?.uniq_id,
                          connection_point,
                        });
                      }

                      return (
                        <div
                          className={cn(
                            "w-full h-full min-h-[1.938rem] max-h-[2.438rem] flex items-center justify-center border-b border-gray-200",
                            previewType === "dashboard" && "cursor-pointer"
                          )}
                          onClick={() => {
                            if (onButtonClick) onButtonClick(row);
                          }}
                        >
                          <SectionRow
                            title={row?.title || ""}
                            description={row?.description || ""}
                          />
                          {allowToShowEdge && (
                            <EdgeHandler
                              type="start"
                              onEdgeMouseDown={({ position }) => {
                                if (onEdgeMouseDown && !isConnected)
                                  onEdgeMouseDown({
                                    connection_point,
                                    position,
                                  });
                              }}
                              isConnected={isConnected}
                              color={color}
                              allowToEditFlow={allowToEditFlow}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaMessage;
