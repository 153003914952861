import moment from 'moment';

import { useEffect, useMemo, useCallback, useState } from 'react';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export default function useRangePicker(
  allowedDate = 'prev',
  changeYears = false,
  dateRange = null
) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const [currentMonth, setCurrentMonth] = useState(0);
  const [currentYear, setCurrentYear] = useState(moment().year());

  const today = moment();
  let current = moment().year(currentYear).add(currentMonth, 'month');

  const months = useCallback(() => {
    const startDateOfMonth = moment()
      .year(currentYear)
      .add(currentMonth, 'month')
      .startOf('month');
    const endDateOfMonth = moment()
      .year(currentYear)
      .add(currentMonth, 'month')
      .endOf('month');
    current = moment().year(currentYear).add(currentMonth, 'month');
    if (changeYears) {
      setCurrentYear(moment(current)?.year());
    }
    const start = moment(startDateOfMonth).startOf('week');
    const end = moment(endDateOfMonth).endOf('week');
    const beforeDays = startDateOfMonth.day() - 1;
    const afterDays = 7 - endDateOfMonth.day();
    const totalDays = beforeDays + endDateOfMonth.date() + afterDays;

    const yesterDay = moment().subtract(1, 'days');
    const tommorowDay = moment().add(1, 'days');

    return Array.from({ length: totalDays }).map((_, index) => {
      const date = moment(start).add(0, 'week').add(index, 'day');

      const startDateAllowed = moment(dateRange?.startDate).add(-1, 'days');
      const endDateAllowed = moment(dateRange?.endDate);

      return {
        date: date.toDate(),
        currentMonth:
          date.month() === current.month() &&
          date.year() === moment(current)?.year(),
        disabled:
          allowedDate === 'prev'
            ? date >= yesterDay
            : allowedDate === 'next'
            ? date <= yesterDay
            : allowedDate === 'allowed' && dateRange
            ? date < startDateAllowed ||
              (endDateAllowed <= date && date > yesterDay)
            : false,
      };
    });
  }, [currentMonth, currentYear, dateRange]);

  const getNextMonth = () => {
    setCurrentMonth((prev) => prev + 1);
  };

  const getPrevMonth = () => {
    setCurrentMonth((prev) => prev - 1);
  };

  const getNextYear = () => {
    setCurrentYear((prev) => prev + 1);
  };

  const getPrevYear = () => {
    setCurrentYear((prev) => prev - 1);
  };

  const getCustomranges = () => {
    const prevday = moment(today).add(-1, 'days').startOf('days');
    const lastWeekStartDate = moment(today).add(-7, 'days').startOf('days');
    const last14DaysStartDate = moment(today).add(-14, 'days').startOf('days');
    const last28DaysStartDate = moment(today).add(-28, 'days').startOf('days');
    const thisMonthStartDate = moment()
      .year(currentYear)
      .add(0, 'month')
      .startOf('month');
    const thisQuarterStartDate = moment()
      .year(currentYear)
      .add(-2, 'month')
      .startOf('month');
    const endDate = moment().add(-1, 'days').endOf('days');

    const currentQuarter = moment().quarter();
    let currentQuarterStartDate = null;
    let prevQuarterStartDate = moment()
      .add(-1, 'month')
      .quarter(currentQuarter - 1)
      .startOf('month');
    let prevQuarterEndDate = moment()
      .quarter(currentQuarter - 1)
      .add(1, 'month')
      .endOf('month');

    if (currentQuarter === 1) {
      currentQuarterStartDate = moment().month(0).startOf('month');
    } else if (currentQuarter === 2) {
      currentQuarterStartDate = moment().month(3).startOf('month');
    } else if (currentQuarter === 3) {
      currentQuarterStartDate = moment().month(6).startOf('month');
    } else if (currentQuarter === 4) {
      currentQuarterStartDate = moment().month(9).startOf('month');
    }

    return {
      today: {
        start: today.toDate(),
        end: today.toDate(),
      },
      yesterday: {
        start: prevday.toDate(),
        end: prevday.toDate(),
      },
      lastWeek: {
        start: lastWeekStartDate.toDate(),
        end: endDate.toDate(),
      },
      last14days: {
        start: last14DaysStartDate.toDate(),
        end: endDate.toDate(),
      },
      last28days: {
        start: last28DaysStartDate.toDate(),
        end: endDate.toDate(),
      },
      thisMonth: {
        start: thisMonthStartDate.toDate(),
        end: today.toDate(),
      },
      thisQuarter: {
        start: currentQuarterStartDate.toDate(),
        end: today.toDate(),
      },
      prevQuarter: {
        start: prevQuarterStartDate.toDate(),
        end: prevQuarterEndDate.toDate(),
      },
    };
  };

  function getAbsoluteMonths(momentDate) {
    var months = Number(moment(momentDate).format('MM'));
    var years = Number(moment(momentDate).format('YYYY'));
    return months + years * 12;
  }

  const resetToCurrentMonth = (value) => {
    var startMonths = getAbsoluteMonths(today);
    var endMonths = getAbsoluteMonths(value);
    var monthDifference = endMonths - startMonths;
    setCurrentMonth(monthDifference);
  };

  return {
    today,
    timeZone,
    current,
    currentMonth,
    currentYear,
    getCustomranges,
    months,
    days,
    getPrevMonth,
    getNextMonth,
    getPrevYear,
    getNextYear,
    resetToCurrentMonth,
  };
}
