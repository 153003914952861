import React from 'react';

const ArrowRotate = ({
  width = 20,
  height = 20,
  color = 'white',
  strokeWidth = 1.6667,
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.9408 12.084C15.0925 14.7456 12.6629 16.6673 9.79806 16.6673C6.22774 16.6673 3.33341 13.6825 3.33341 10.0007C3.33341 6.31875 6.22774 3.33398 9.79806 3.33398C12.1909 3.33398 14.2801 4.67465 15.3979 6.66732M13.4344 7.50065H16.6667V4.16732'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowRotate;
