import React from 'react';
import { cn } from '../../../../../helper/cn';

const SendTextPreview = ({ type = 'REPLIED', data, text, className }) => {
  return (
    <div
      className={cn(
        'w-fit max-w-[60%] px-3 py-2 rounded-t-xl rounded-bl-xl text-base break-words weight-medium text-[var(--contentText)]',
        type === 'REPLIED' ? 'bg-[#D8FBD2]' : 'bg-white',
        className
      )}>
      {text}
    </div>
  );
};

export default SendTextPreview;
