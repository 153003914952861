import React from 'react';
import FullArrow from '../../../../../assets/customSVG/FullArrow';
import Refresh from '../../../../../assets/customSVG/Refresh';
import Xmark from '../../../../../assets/customSVG/Xmark';

const PreviewModalHeader = ({
  title,
  showReset = false,
  showClose = false,
  handleReset,
  handleCancel,
}) => {
  return (
    <div className='w-full h-16 flex items-center p-3 gap-2.5'>
      <button
        className='size-6 flex items-center justify-center rounded-md hover:bg-[var(--BG-50)]'
        onClick={handleCancel}>
        <FullArrow
          width={18}
          height={15}
          color={'#2D3036'}
          className={'rotate-180'}
        />
      </button>
      <div className='flex items-center flex-1 gap-3'>
        <p className='text-base weight-medium text-[#2D3036]'>{title}</p>
      </div>
      {showReset && (
        <button
          onClick={() => {
            if (handleReset) handleReset();
          }}>
          <Refresh
            width={24}
            height={24}
            color={''}
            strokeWidth={2.2}
            className='stroke-[var(--textBlack)] p-1 rounded-full cursor-pointer hover:stroke-[#FF543E] hover:bg-[#FFE3E0]'
          />
        </button>
      )}
      {showClose && (
        <button
          onClick={() => {
            if (handleCancel) handleCancel();
          }}>
          <Xmark
            width={24}
            height={24}
            color={''}
            strokeWidth={1.6}
            className='stroke-[var(--textBlack)] p-[5px] rounded-full cursor-pointer hover:stroke-[#FF543E] hover:bg-[#FFE3E0]'
          />
        </button>
      )}
    </div>
  );
};

export default PreviewModalHeader;
