import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { cn } from '../../../helper/cn';

const Tooltip = ({
  children,
  content,
  position = 'bottom',
  arrow = false,
  rootClassName = '',
  className = '',
  onClick,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [positionStyle, setPositionStyle] = useState({});
  const [arrowStyle, setArrowStyle] = useState({});
  const tooltipRef = useRef(null);
  const targetRef = useRef(null);

  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => setIsVisible(false);

  const calculatePosition = () => {
    if (targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const offset = 6;

      const positionStyles = {
        top:
          targetRect.top +
          (position === 'bottom'
            ? targetRect.height + offset
            : -tooltipRect.height - offset),
        left: targetRect.left + targetRect.width / 2 - tooltipRect.width / 2,
      };

      const arrowStyles = {
        top: position === 'bottom' ? -8 : tooltipRect.height,
        left: tooltipRect.width / 2 - 8,
      };

      if (position === 'left' || position === 'right') {
        positionStyles.top =
          targetRect.top + targetRect.height / 2 - tooltipRect.height / 2;
        positionStyles.left =
          position === 'left'
            ? targetRect.left - tooltipRect.width - offset
            : targetRect.right + offset;

        arrowStyles.top = tooltipRect.height / 2 - 8;
        arrowStyles.left = position === 'left' ? tooltipRect.width : -8;
      }

      setPositionStyle(positionStyles);
      setArrowStyle(arrowStyles);
    }
  };

  useEffect(() => {
    if (isVisible) calculatePosition();
  }, [isVisible, position]);

  useEffect(() => {
    const handleResize = () => calculatePosition();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.();
  };

  if (!content) return children;

  return (
    <div
      ref={targetRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn('w-fit relative group cursor-pointer', rootClassName)}
      onClick={handleClick}>
      {children}

      {isVisible &&
        ReactDOM.createPortal(
          <div
            ref={tooltipRef}
            className={cn(
              'w-auto max-w-60 absolute z-10 px-2 py-1 break-words overflow-hidden text-white text-sm bg-[#2D3036] rounded shadow-lg',
              className
            )}
            style={positionStyle}>
            {arrow && (
              <div
                className='absolute'
                style={{
                  top: arrowStyle.top,
                  left: arrowStyle.left,
                  width: 0,
                  height: 0,
                  borderLeft: '8px solid transparent',
                  borderRight: '8px solid transparent',
                  borderTop:
                    position === 'top' ? '8px solid black' : 'transparent',
                  borderBottom:
                    position === 'bottom' ? '8px solid black' : 'transparent',
                  transform:
                    position === 'left' || position === 'right'
                      ? 'translateY(-50%)'
                      : 'translateX(-50%)',
                }}
              />
            )}
            {content}
          </div>,
          document.getElementById('tooltip') || document.body
        )}
    </div>
  );
};

export default Tooltip;
