import React, { useEffect, useState } from "react";
import "../style/AuthForm.css";
import "../style/SignUp.css";

import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { ICONS } from "../../../assets/icons/index";
import useToggle from "../../../hooks/useToggle";

import { Form, Modal } from "antd";

import { Button } from "../../../components/form/Button/Button";
import { Input } from "../../../components/form/Input/Input";

import updateToken from "../../../api/updateToken";
import Label from "../../../components/form/label/label";
import { validatePassword } from "../../../constant/auth/authValidation";

import {
  updateCurrentUser,
  updateToggleToast,
} from "../../../reduxToolkit/appSlice";
import { updateAuthToken } from "../../../reduxToolkit/authSlice";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";

import { currentUserDetails } from "../../../api/Api";
import { invitationAccept, Login } from "../api/Api";

//style
const inputWidth = {
  width: "100%",
};
const mainContent = {
  width: "65%",
};

// inputRules
const emailRules = [
  {
    // required: true,
    // message: "Email is required",
  },
  {
    // type: "email",
    // message: "Enter valid email address"
  },
];
const passwordRules = [{ validator: validatePassword }];

export const invitation = [
  { id: 1, label: "Yes, join connect", status: "accepted" },
  { id: 2, label: "No, thanks", status: "rejected" },
];

function AuthForm() {
  const [value, toggleValue] = useToggle(false);
  const [verified, setVerified] = useState({
    email: "",
    password: "",
  });
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [loginError, setLoginError] = useState("");
  const TOKEN = localStorage.getItem("authToken");

  const dispatch = useAspDispatch();
  const { toggleToast, currentUser } = useAspSelector((state) => state.app);

  const [form] = Form.useForm();

  const navigate = useNavigate();
  let { pathname } = useLocation();

  const [queryParams] = useSearchParams();
  const validationToken = queryParams?.get("token");
  const validationToken2 = queryParams?.get("role");
  const validationName = queryParams?.get("name");
  const validationAccount = queryParams.get("account");

  const isValid = (value) => value !== null && value.trim() !== "";

  if (isValid(validationToken2)) localStorage.setItem("role", validationToken2);
  if (isValid(validationToken))
    localStorage.setItem("invite_token", validationToken);
  if (isValid(validationName)) localStorage.setItem("name", validationName);
  if (isValid(validationAccount))
    localStorage.setItem("account", validationAccount);

  // localStorage?.setItem("role", validationToken2);
  // localStorage?.setItem("invite_token", validationToken);
  // localStorage?.setItem("name", validationName);
  // localStorage?.setItem("account", validationAccount);

  let signUpLink = "/sign-up";

  //styles
  const button = {
    margin: "25px 0 15px 0",
    width: pathname === "/login" ? "21%" : "",
  };

  // useEffect(() => {
  //   if (validationToken) {
  // form.setFieldsValue({ Email: validationEmail });
  // signUpLink += `?token=${validationToken}&email=${validationEmail}`;
  // }

  // const TOKEN = localStorage.getItem('authToken');
  // if (TOKEN) {
  //   if (pathname === '/login') navigate('/user/home');
  //   else if (pathname !== '/reset-password') navigate('/Accounts');
  // } else {
  //   localStorage.clear();
  //   navigate('/login');
  // }
  // }, [validationEmail, validationToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginError("");

    if (!name) {
      console.error("Input is missing a name attribute.");
      return;
    }

    setVerified((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onFinish = (values) => {
    if (pathname === "/login") {
      setLoginError("");
      const payLoad = {
        email: values?.Email,
        password: values?.password,
      };
      // if (validationToken) payLoad["invite_token"] = validationToken;

      toggleValue(true);
      Login(payLoad).then((res) => {
        loginResponseUpdate(res);
      });
    }
  };

  const loginResponseUpdate = async (res) => {
    toggleValue(false);
    if (res?.status === 200) {
      localStorage.setItem("authToken", res?.data?.auth_token);
      updateToken(res?.data?.auth_token);
      dispatch(updateAuthToken(res?.data?.auth_token));
      const user_res = await currentUserDetails().then((res) => {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Login Success",
              status: "Success",
              duration: "",
            },
          ])
        );
        if (res?.status === 200) {
          dispatch(updateCurrentUser(res?.data));
          return res?.data;
        }

        return null;
      });

      if (isValid(validationToken2) && isValid(validationName)) {
        setPopoverOpen(true);
      } else if (user_res?.active_accounts?.length === 0) {
        navigate("/on-boarding");
      } else {
        navigate("/Accounts");
      }

      // setTimeout(() => {
      // dispatch(
      //   updateToggleToast([
      //     ...toggleToast,
      //     {
      //       id: toggleToast?.length + 1,
      //       content: 'Login Success',
      //       status: 'Success',
      //       duration: '',
      //     },
      //   ])
      // );
      // }, 1000);
    } else {
      setLoginError(res?.response?.data?.error);
      // dispatch(
      //   updateToggleToast([
      //     ...toggleToast,
      //     {
      //       id: toggleToast?.length + 1,
      //       content: res?.response?.data?.error,
      //       status: "Error",
      //       duration: "",
      //     },
      //   ])
      // );
    }
  };

  const handleClick = (item) => {
    const payLoad = {
      invite_token: validationToken,
      status: item?.status,
      role_code: validationToken2,
    };

    invitationAccept(payLoad).then((res) => {
      if (res?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content:
                item?.status === "success"
                  ? "Invitation Success"
                  : "Invitation Rejected",
              status: "Success",
              duration: "",
            },
          ])
        );
        if (item?.status === "accepted") {
          navigate("/Accounts");
        } else if (currentUser?.active_accounts?.length === 0) {
          navigate("/on-boarding");
        } else if (item?.status === "rejected") {
          navigate("/Accounts");
        }
      } else {
        setPopoverOpen(false);
        navigate("/login");
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  return (
    <div className="step2Wrapper">
      {/* <Google /> */}
      <Form
        onFinish={onFinish}
        className="step2Wrapper-form mt-[30px]"
        form={form}
        autoComplete="off"
      >
        <div style={mainContent}>
          <Label
            title={pathname === "/login" ? "Email Address" : "Email"}
            required
            className="font14px-500 text-[var(--textBlack)]"
          />
          <Form.Item name="Email" style={inputWidth} rules={emailRules}>
            <Input
              name="email"
              iconPrefix={ICONS.emailBox}
              placeholder={
                pathname === "/login"
                  ? "Enter email"
                  : "ex:- yourmail@email.com"
              }
              // disabled={
              //   pathname.includes('/login') && validationToken ? true : false
              // }
              maxLength={60}
              onChange={(e) =>
                handleChange({
                  target: { name: "email", value: e.target.value },
                })
              }
            />
          </Form.Item>
          <div style={{ marginTop: "20px" }} />
          <Label
            title="Password"
            required
            className="font14px-500 text-[var(--textBlack)]"
          />
          <Form.Item name="password" style={inputWidth}>
            <Input
              name="password"
              iconPrefix={ICONS.passwordLock}
              iconSuffixPassword={ICONS.passwordEyeOpen}
              type="password"
              placeholder={pathname === "/login" ? "Password" : "password"}
              onChange={(e) =>
                handleChange({
                  target: { name: "password", value: e.target.value },
                })
              }
              maxLength={20}
            />
          </Form.Item>

          <div
            className={`w-100 flex-row  ${
              loginError ? "align-center space-between" : "justify-end"
            }`}
          >
            {loginError && (
              <div className="flex-row align-center w-100">
                <img src={ICONS?.alertIconError} alt="" />
                <span className="error-message pl-5">{loginError}</span>
              </div>
            )}
            {pathname === "/login" && (
              <div
                className="forgotPassword"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </div>
            )}
          </div>
        </div>
        {verified?.email !== "" && verified?.password !== "" ? (
          <Button
            size="large"
            type="submit"
            width="100%"
            loading={value}
            disabled={value}
            label={pathname === "/login" ? "Login" : "Create Account"}
            style={button}
          />
        ) : (
          <div className="bg-[var(--BG-50)] text-[#566F8F] cursor-not-allowed font16px-600 px-10 py-1.5 mt-10 b-radius-6">
            Login
          </div>
        )}
      </Form>

      {pathname === "/login" && (
        <div className="flex items-center justify-center gap-1 font16px-400 text-[var(--textBlack)] mt-4">
          <p>Don’t have an account?</p>
          {/* <Link to={signUpLink} className='text-[var(--a)]'>
            Sign up
          </Link> */}
          <button
            type="submit"
            className="font16px-600 flex items-center justify-center text-[var(--a)]"
            onClick={() => {
              if (validationToken)
                navigate({
                  pathname: "/sign-up",
                  search: createSearchParams({
                    token: queryParams?.get("token"),
                  }).toString(),
                });
              else navigate("/sign-up");
            }}
          >
            Sign up
          </button>
        </div>
      )}
      <Modal
        open={popoverOpen}
        closable={false}
        centered={true}
        footer={false}
        wrapClassName={"backdrop-blur-xl"}
        width={"49vw"}
      >
        <div className="w-100 h-[38vh] asp-modal-linear b-radius flex-column align-center pt-16">
          <span className="w-100 text-center font24px-600 text-[var(--textBlack)] mb-20">
            <span className="capitalize">{validationName}</span> has invited you
            to Join "{validationAccount}" account on GainWix.ai
          </span>
          <div className="justify-center flex-column mt-20 mb-20">
            {invitation?.map((item) => {
              return (
                <div
                  className="flex align-center justify-center "
                  key={item?.id}
                  onClick={() => handleClick(item)}
                >
                  <span
                    className={`font16px-500 text-[#4C515B] pointer ${
                      item?.id === 1
                        ? "button-primary b-radius-6 px-7 py-1.5"
                        : "font16px-500 text-[#4C515B] mt-10"
                    }`}
                  >
                    {item?.label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AuthForm;
