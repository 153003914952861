import React, { useEffect, useState } from 'react';

// assets
import DropDown from 'assets/customSVG/accounts/DropDown';
import UnLink from '../../../../../assets/customSVG/sequence/UnLink';

// helpers
import { cn } from '../../../../../helper/cn';

// hooks
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import useToggle from '../../../../../hooks/useToggle';

const strokeWidth = '2.5px';
const edgeDefault = 0;

const EdgeElements = ({
  id,
  edge,
  isNew,
  position,
  onClickDelete,
  scale,
  selectedIds,
  allowToEditFlow,
}) => {
  const { x0, y0, x1, y1 } = position;

  const [selected, setSelected] = useState(null);
  const [isEdgeHovered, setIsEdgeHovered] = useToggle(false);

  const elementRef = useOutsideClick(() => {
    if (setSelected) setSelected(null);
  });

  useEffect(() => {
    setIsEdgeHovered(selectedIds?.edgesId?.includes(id));
  }, [selectedIds]);

  const handleMouseEnter = (event) => {
    event.stopPropagation();
    if (!isNew) {
      setIsEdgeHovered(true);
      setSelected(id);
    }
  };

  const handleMouseLeave = (event) => {
    event.stopPropagation();
    if (!isNew) {
      setIsEdgeHovered(false);
      setSelected(null);
    }
  };

  const controlX1 = x0 + (x1 - x0) / 2;
  const controlY1 = y0;
  const controlX2 = x0 + (x1 - x0) / 2;
  const controlY2 = y1;

  const pathData = `M ${x0} ${y0} C ${controlX1} ${controlY1}, ${controlX2} ${controlY2}, ${x1} ${y1}`;

  const t = 0.5;
  const bezierMidpointX =
    Math.pow(1 - t, 3) * x0 +
    3 * Math.pow(1 - t, 2) * t * controlX1 +
    3 * (1 - t) * Math.pow(t, 2) * controlX2 +
    Math.pow(t, 3) * x1;

  const bezierMidpointY =
    Math.pow(1 - t, 3) * y0 +
    3 * Math.pow(1 - t, 2) * t * controlY1 +
    3 * (1 - t) * Math.pow(t, 2) * controlY2 +
    Math.pow(t, 3) * y1;

  return (
    <>
      <svg
        className='z-0'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        // onClick={() => setSelected(edge?.uniq_id)}
      >
        <defs>
          <linearGradient
            id='gradientStroke'
            x1='0%'
            y1='0%'
            x2='100%'
            y2='0%'>
            <stop
              offset='0%'
              stopColor={edge?.settings?.colors?.start || '#0ACD95'}
            />
            <stop
              offset='100%'
              stopColor={edge?.settings?.colors?.end || '#0ACD95'}
            />
          </linearGradient>
        </defs>
        <g
          className={cn(
            'outline-none',
            !isNew && isEdgeHovered && 'pointer-events-auto',
            allowToEditFlow ? 'cursor-pointer' : 'cursor-default'
          )}
          style={{ pointerEvents: 'visibleStroke' }}
          role='button'
          onClick={(event) => {
            event.stopPropagation();
            if (!isNew) setSelected(id);
          }}>
          <path
            d={pathData}
            strokeWidth={strokeWidth}
            stroke='url(#gradientStroke)'
            fill='none'
            style={{ pointerEvents: 'all' }}
            className={cn(
              'outline-none',
              !isNew && isEdgeHovered && 'pointer-events-auto',
              !isNew && (selected === id || isEdgeHovered) && 'animate-dash'
            )}
            strokeDasharray={
              !isNew && (selected === id || isEdgeHovered) ? '7,3' : ''
            }
          />
          <path
            d={pathData}
            strokeOpacity={0}
            strokeWidth={20}
            stroke='url(#gradientStroke)'
            fill='none'
            style={{ pointerEvents: 'all' }}
            className={cn('outline-none pointer-events-auto')}
          />
        </g>
      </svg>

      {allowToEditFlow && (
        <div
          className={cn(
            'absolute p-1 items-center gap-2.5 bg-white/80 text-sm text-[var(--contentText)] rounded-md pointer-events-auto !cursor-pointer z-[1]',
            !isNew && isEdgeHovered && selected === id ? '!flex' : 'hidden'
          )}
          style={{
            top: bezierMidpointY - 25,
            left: bezierMidpointX - 30,
            transform: `translate(-10px,-18px)`,
          }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            if (!isNew) onClickDelete();
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <div className='w-full h-7 relative flex items-center gap-1.5 px-1 py-0.5 rounded-md group/unlink'>
            <div className='size-5 flex items-center justify-center'>
              <UnLink
                width={16}
                height={16}
                color={''}
                className={
                  'stroke-[var(--contentText)] group-hover/unlink:stroke-[#FF543E]'
                }
              />
            </div>
            <p className='text-xs weight-medium text-[var(--contentText)] group-hover/unlink:text-[#FF543E]'>
              Unlink
            </p>
            <div className='absolute bottom-2.5 left-1/4'>
              <DropDown
                width={34}
                height={32}
                color={'white'}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EdgeElements;
