import React from "react";
import "../../styles/Broadcast.css";

import { Input } from "../../../../components/form/Input/Input";
import { ICONS } from "../../../../assets/icons";

import SendForm from "./SendForm";
import RadioButton from "../../../../components/form/radio/RadioButton";
import { SendSettingList } from "../../../../constant/app/campaign/Broadcast";
import Calendar from "../../../../components/commonComponents/calendar/Calendar";
import { Popover } from "antd";
import moment from "moment";

import TimePickers from "./TimePickers";

const BroadSetting = ({
  handleCreateCampaign,
  handleClick,
  selectedRole,
  setSelectedRole,
  selectedItemId,
  numberDetails,
  handleLabelClick,
  selectedIndex,
  setSelectedIndex,
  broadCasts,
  setSelectedItemId,
  handleDateSelection,
  selectedDate,
  TimePick,
  setTimePick,
  startPopoverVisible,
  setStartPopoverVisible,
  handleTimeClick,
  selectedTime,
  campaignDetail,
  error,
  hours,
  minutes,
  periods,
  setHours,
  setPeriod,
  setMinutes,
  handleTime,
}) => {
  const handlelabelClick = (dataName) => {
    const formattedName = dataName.toLowerCase().replace(/\s+/g, "_");
    setSelectedIndex(formattedName);
    handleLabelClick(formattedName);
  };

  const handleOkClick = () => {
    setTimePick(false);
  };

  const currentDate = moment().format("YYYY-MM-DD");
  const content = (
    <Calendar
      heading="Select duration"
      singleSelect={true}
      clickedDateFunc={handleDateSelection}
    />
  );

  return (
    <div className="BroadcastSetting-mainContainer  flex-row">
      <div className="flex-column w-70 ">
        <div className="setting-wrapper">
          <span>Broadcast Name</span>
          <div className="mt-10">
            <Input
              placeholder="Enter broadcast name"
              pattern=""
              onChange={(e) => {
                const newValue = e?.target?.value?.replace(
                  /[^A-Za-z0-9-_ ]/g,
                  ""
                );
                handleCreateCampaign("name", newValue);
              }}
              value={broadCasts?.name}
              style={{ height: "5vh" }}
            />
          </div>
        </div>

        <div className="divider" />

        <div className="setting-wrapper flex-column">
          <span className="channels mt-10">Select Channels</span>
          <span>Select your communication channel</span>
          <div className="Broadcast-channel flex-row space-between pointer mt-10">
            <div className="flex-row align-center">
              <img
                src={ICONS?.WhatsApps}
                alt="whatsapp"
                style={{ width: "25px", height: "25px" }}
              />
              <span className="setting-whatsapp">WhatsApp</span>
            </div>
            <RadioButton selected={true} style={{ marginTop: "-5px" }} />
          </div>
        </div>

        <div className="divider" />

        <div className="setting-wrapper flex-column mt-10">
          <SendForm
            numberDetails={numberDetails}
            handleClick={handleClick}
            setSelectedRole={setSelectedRole}
            selectedRole={selectedRole}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
            broadCasts={broadCasts}
          />
        </div>

        <div className="divider" />

        <div className="flex-column">
          <div className="SendSetting-container flex-column">
            <div
              className="setting-wrapper flex-column"
              style={{ marginBottom: "0 !important" }}
            >
              <span>Send Setting</span>
              <span>When should we send this broadcast?</span>
            </div>

            {SendSettingList?.map((data) => (
              <div
                className="w-100 flex-column "
                style={{ padding: "0px 20px" }}
                key={data?.id}
              >
                <div
                  className={`flex-row  ${
                    (data?.name === "Send Now" &&
                      moment(
                        campaignDetail?.starting_date_and_time?.split("T")[0]
                      ).isAfter(currentDate)) ||
                    moment(currentDate).isAfter()
                      ? "disable-send-now"
                      : "pointer"
                  }`}
                  onClick={() =>
                    (data?.name === "Send Now" &&
                      moment(
                        campaignDetail?.starting_date_and_time?.split("T")[0]
                      ).isAfter(currentDate)) ||
                    moment(currentDate).isAfter()
                      ? null
                      : handlelabelClick(data?.name)
                  }
                >
                  <label className="checkBoxContainer mb-10 mt-10">
                    <input
                      type="checkbox"
                      name="checkbox"
                      checked={
                        selectedIndex ===
                        data?.name?.toLowerCase()?.replace(/\s+/g, "_")
                      }
                      onChange={() => {}}
                    />
                  </label>
                  <div className="setting-setting flex-column ">
                    <span>{data?.name}</span>
                    <p>{data?.content}</p>
                  </div>
                </div>
                <div className="setting-setting flex-column w-100">
                  {selectedIndex === "send_later" &&
                    data?.name === "Send Later" && (
                      <div>
                        <div className="w-100 Broadcast-Time flex-row align-center">
                          <div
                            className="calender-card w-100 space-between flex-row"
                            style={{ padding: "5px 10px" }}
                          >
                            <div className="Calendar-container flex-row w-100 space-between">
                              <div className="aaaa flex-row  align-center">
                                <span className="send-later-content pr-10">
                                  Date{" "}
                                </span>
                                <div>
                                  <Popover
                                    content={content}
                                    trigger="click"
                                    arrow={false}
                                    open={startPopoverVisible}
                                    onOpenChange={() => {
                                      setStartPopoverVisible(
                                        !startPopoverVisible
                                      );
                                      setTimePick(false);
                                    }}
                                  >
                                    <div className="flex-row align-center pointer">
                                      <Input
                                        type="none"
                                        placeholder={"DD/MM/YY"}
                                        iconPrefix={
                                          ICONS?.BroadcastCalendarIcon
                                        }
                                        style={{
                                          width: "7vw",
                                          fontSize: "0.75vw",
                                          fontWeight: "500",
                                          padding: "10px",
                                          color: "var(--textBlack)",
                                        }}
                                        value={selectedDate}
                                        readOnly
                                        className="pointer"
                                        name="calender"
                                      />
                                    </div>
                                  </Popover>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="input-container pointer pl-10"
                            style={{ position: "relative", marginLeft: "20px" }}
                          >
                            <div className="flex-row align-center">
                              <span className="send-later-content pr-10">
                                Time
                              </span>
                              <Popover
                                overlayClassName="time-popover"
                                content={
                                  <TimePickers
                                    hours={hours}
                                    minutes={minutes}
                                    periods={periods}
                                    setHours={setHours}
                                    setMinutes={setMinutes}
                                    setPeriod={setPeriod}
                                    onOkClick={() => handleTimeClick(TimePick)}
                                  />
                                }
                                trigger="click"
                                arrow={false}
                                open={TimePick}
                                onOpenChange={() => handleTime()}
                              >
                                <Input
                                  placeholder="00:00 AM"
                                  iconPrefix={ICONS?.BroadcastTimerIcon}
                                  style={{
                                    width: "7.5vw",
                                    cursor: "pointer",
                                    fontSize: "0.75vw",
                                    fontWeight: "500",
                                    padding: "10px",
                                  }}
                                  value={selectedTime || ""}
                                  className="pointer1"
                                />
                              </Popover>
                            </div>
                          </div>
                        </div>
                        {error && (
                          <div className="error-message flex-row align-center pl-10 ml-10 gap-2">
                            <img
                              src={ICONS?.alertIconError}
                              alt="alertIconError"
                            />
                            {error}
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadSetting;
