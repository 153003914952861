import React, { useState, useCallback, useEffect, useRef } from 'react';

// constants
import { edgeDefault } from '../../../constants/defaults';

// helpers
import { cn } from '../../../../../helper/cn';

// components
import Tooltip from '../../../../../components/commonComponents/tooltip';

const Handler = ({
  type,
  color = '#0ACD95',
  node,
  position,
  onEdgeMouseDown,
  handleEdgeMouseUp,
  isConnected,
  allowToEditFlow,
  newEdge,
}) => {
  const ref = useRef();
  const [currentPosition, setCurrentPosition] = useState(position);

  const handleMouseMove = useCallback((event) => {
    event.stopPropagation();

    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const centerX = rect.left + Math.abs(rect.right - rect.left) / 2;
    const centerY = rect.top + Math.abs(rect.bottom - rect.top) / 2;

    setCurrentPosition({
      x: centerX,
      y: centerY,
    });
  }, []);

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const centerX =
      rect.left + Math.abs(rect.right - rect.left + edgeDefault) / 2;
    const centerY = rect.top + Math.abs(rect.bottom - rect.top) / 2;
    const position = { x: centerX, y: centerY };
    setCurrentPosition(position);
    if (allowToEditFlow && onEdgeMouseDown && !isConnected)
      onEdgeMouseDown({ position });

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const onMouseUpEdge = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();
    const centerX =
      rect.left + Math.abs(rect.right - rect.left - edgeDefault) / 2;
    const centerY = rect.top + Math.abs(rect.bottom - rect.top) / 2;
    const position = { x: centerX, y: centerY };

    if (allowToEditFlow && handleEdgeMouseUp)
      handleEdgeMouseUp({ node, position });
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <Tooltip
    // content={
    //   !allowToEditFlow || (type === 'start' && isConnected)
    //     ? 'Not available To connect'
    //     : 'Available To connect'
    // }
    >
      <div
        ref={ref}
        className={cn(
          'rounded-full contents-[""]',
          type === 'start' && 'size-3.5',
          type === 'end' && 'size-3.5',
          type === 'start' && isConnected && newEdge
            ? '!cursor-not-allowed'
            : 'cursor-grab',
          !allowToEditFlow && 'cursor-not-allowed'
        )}
        onMouseDown={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (allowToEditFlow) handleMouseDown(event);
        }}
        onMouseUp={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (allowToEditFlow) onMouseUpEdge(event);
        }}
        style={{
          zIndex: 999,
          pointerEvents: 'all',
          borderWidth: 2.5,
          borderStyle: 'solid',
          backgroundColor: color,
          borderColor: type === 'start' ? color : type === 'end' && '#FFFFFF',
          // backgroundColor:
          //   type === 'start' ? '#FFFFFF' : type === 'end' && color,
        }}
      />
    </Tooltip>
  );
};

export default Handler;
