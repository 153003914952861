import React, { useCallback, useState } from "react";
import { ICONS } from "../../../assets/icons";
import { ICONSS } from "../../../assets/icons/index1";
import Select from "../../../components/commonComponents/select";
import { brandStatus } from "../../../constant/app/account/account";
import { cn } from "../../../helper/cn";
import { category } from "../../auth/components/onboarding/BrandCreation";

import { useAspSelector } from "../../../test/jest-redux-hooks";

import { Tooltip } from "antd";
import { formatCurrency } from "../../../helper/formatCurrency";
import { validateURL } from "../../../helper/checkUrls";

const Overview = ({
  setAddCredits,
  brandsData,
  dynamicInputs,
  editInfo,
  setEditInfo,
  handleUpdate,
  countryListss,
  stateList,
  cityList,
  handleBrandChange,
  buttonClass,
  dynamicError,
  setupData,
}) => {
  const [activeAccordionId, setActiveAccordionId] = useState([]);
  const { currentAccount } = useAspSelector((state) => state.app);

  const handleClick = (id) => {
    setActiveAccordionId((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((itemId) => itemId !== id)
        : [...prevIds, id]
    );
  };

  // styles ---------------
  const inputContainer = "w-full py-2 gap-2 b-radius-6 mt-5";
  const input =
    "w-full text-[20vw] font14px-500 text-[var(--textBlack)] rounded-lg  py-2";

  return (
    <div className="w-100 flex-row space-between gap-4 mb-20">
      <div className="w-70 flex-column ">
        <div className="w-100 flex-column space-between bg-white b-radius p-20">
          <div className="flex-row align-center gap-2">
            <img src={ICONSS?.conversationCredits} alt="conversationCredits" />
            <span className="font16px-600 text-[var(--textBlack)]">
              WhatsApp Conversation Credits
            </span>
          </div>

          <div className="flex-row align-center space-between mt-20">
            <div className="w-100 flex-column mt-10">
              <span className="mb-5 font14px-400 text-[var(--font-600)]">
                Amount Remaining
              </span>
              <div className="w-100 flex-row align-center space-between ">
                <span className="font22px-600 text-[var(--textBlack)]">
                  {formatCurrency(brandsData?.remaining_credits_amount)}
                </span>
                {(currentAccount?.role === "Account Owner" ||
                  currentAccount?.role === "Account Manager") && (
                  <button
                    className="font15px-500 text-[var(--new-primary)] border border-[var(--new-primary)] px-4 py-1.5 b-radius-6 flex-row align-center gap-2"
                    onClick={() => setAddCredits(true)}
                  >
                    <img
                      src={ICONSS?.conversationCredits}
                      alt="conversationCredits"
                    />
                    Buy More
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 bg-white b-radius mt-15 prl-20 pt-10 h-[55vh]">
          <div className="flex-row align-center gap-2">
            <span className="font16px-600 text-[var(--textBlack)]">
              Brand Information
            </span>
          </div>

          <div className="grid grid-cols-2 gap-3 mt-15">
            {dynamicInputs?.map((item) => {
              return (
                <div className="mb-10" key={item?.id}>
                  <span className="font14px-500 text-[var(--font-600)]">
                    {item?.label} <span className="text-[red]">*</span>
                  </span>
                  {editInfo ? (
                    <div
                      className={`w-full flex items-center mt-5 pl-10 gap-2.5 border  rounded-lg ${
                        (item?.id === 1 && item?.value?.endsWith("-")) ||
                        item?.value?.endsWith(".") ||
                        item?.value?.endsWith("'") ||
                        (item?.id === 4 && !validateURL(item?.value))
                          ? "border-red-400"
                          : "border-[var(--border-input)]"
                      }`}
                    >
                      {item?.id !== 6 &&
                        item?.id !== 7 &&
                        item?.id !== 8 &&
                        item?.id !== 2 && (
                          <input
                            type={item?.id === 9 ? "number" : "text"}
                            className={cn(input)}
                            name="name"
                            value={item?.value || ""}
                            placeholder="E.g. Spring sale offer"
                            onChange={(e) =>
                              handleBrandChange({
                                id: item?.id,
                                value: e.target.value,
                              })
                            }
                            autoComplete="off"
                            maxLength={item?.id === 9 ? 6 : 64}
                          />
                        )}
                      {(item?.id === 6 ||
                        item?.id === 7 ||
                        item?.id === 8 ||
                        item?.id === 2) && (
                        <Select
                          value={
                            item?.value !== "Unknown" ? item?.value : "" || ""
                          }
                          options={
                            item?.id === 6
                              ? countryListss || []
                              : item?.id === 7
                              ? stateList || []
                              : item?.id === 8
                              ? cityList || []
                              : category || []
                          }
                          onChange={(value) => {
                            handleBrandChange({
                              id: item?.id,
                              value: value?.label,
                              locations_id: value?.id,
                            });
                          }}
                          placeholder={"--Select option--"}
                          position="bottom"
                          rootClassName="border-none w-100"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      {item?.value?.length > 40 ? (
                        <Tooltip title={item?.value} placement="top">
                          <div
                            className={cn(
                              inputContainer,
                              "bg-[var(--BG-25)] pl-10 font14px-500 text-[var(--textBlack)]"
                            )}
                          >
                            <p className="max-w-[30ch] truncate">
                              {item?.value}
                            </p>
                          </div>
                        </Tooltip>
                      ) : (
                        <div
                          className={cn(
                            inputContainer,
                            "bg-[var(--BG-25)] pl-10  font14px-500 text-[var(--textBlack)]"
                          )}
                        >
                          {item?.value || "--"}
                        </div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
          {dynamicError && (
            <div className="flex-row align-center">
              <img src={ICONS?.alertIconError} alt="alertIconError" />
              <span className="error-message pl-5">{dynamicError}</span>
            </div>
          )}
          {(currentAccount?.role === "Account Owner" ||
            currentAccount?.role === "Account Manager") && (
            <div className="w-100 justify-end flex-row mt-20">
              <button
                className={`px-2 py-1.5 mb-20 ${buttonClass()}`}
                onClick={() => {
                  if (editInfo) {
                    handleUpdate();
                  } else {
                    setEditInfo(true);
                  }
                }}
              >
                {!editInfo ? "Edit Brand Info" : "Update Brand Info"}
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="w-40 bg-white b-radius p-20">
        <div className="flex-row align-center gap-1">
          <span className="font16px-600 text-[var(--textBlack)]">
            Brand Setup Status
          </span>
        </div>
        <div className="">
          {brandStatus?.map((item, index) => {
            const style = activeAccordionId.includes(item?.id)
              ? { boxShadow: "0px 5px 20px 0px #0000000D" }
              : {};

            const getCardClassName = () => {
              const baseClasses = "mt-20 p-10 b-radius";

              // First card - WhatsApp Business API
              if (index === 0) {
                return setupData?.connect_wapp_buss_api === false
                  ? `${baseClasses} border border-[#F6A723] bg-[#FFFAF2]`
                  : `${baseClasses} bg-white`;
              }

              // Second card - Business Verification
              if (index === 1) {
                return setupData?.start_buss_verification === false
                  ? `${baseClasses} border border-[#F6A723] bg-[#FFFAF2]`
                  : `${baseClasses} bg-white`;
              }

              if (index === 2) {
                return setupData?.add_payment_method === false
                  ? `${baseClasses} border border-[#F6A723] bg-[#FFFAF2]`
                  : `${baseClasses} bg-white`;
              }

              return `${baseClasses} bg-white`;
            };
            const getIcon = () => {
              if (index === 0) {
                return setupData?.connect_wapp_buss_api === false ? (
                  <img src={item?.icon1} alt="brandStatus" />
                ) : (
                  <img src={item?.icon} alt="brandStatus" />
                );
              }

              if (index === 1) {
                return setupData?.start_buss_verification === false ? (
                  <img src={item?.icon1} alt="brandStatus" />
                ) : (
                  <img src={item?.icon} alt="brandStatus" />
                );
              }

              if (index === 2) {
                return setupData?.add_payment_method === false ? (
                  <img src={item?.icon1} alt="brandStatus" />
                ) : (
                  <img src={item?.icon} alt="brandStatus" />
                );
              }

              return <img src={item?.icon} alt="brandStatus" />;
            };

            const getstyleClassName = () => {
              const baseClasses = "font15px-600  p-10 b-radius-6";

              // First card - WhatsApp Business API
              if (index === 0) {
                return setupData?.connect_wapp_buss_api === false
                  ? `${baseClasses} bg-[#1778F2] text-white`
                  : `${baseClasses} bg-[#1778F2] text-white`;
              }

              // Second card - Business Verification
              if (index === 1) {
                return setupData?.start_buss_verification === false
                  ? `${baseClasses} bg-[#6940F224] text-[#6940F2]`
                  : `${baseClasses} bg-[#6940F224] text-[#6940F2]`;
              }

              if (index === 2) {
                return setupData?.add_payment_method === false
                  ? `${baseClasses} bg-[#F6A72329] text-[#F6A723]`
                  : `${baseClasses} bg-[var(--new-primary-bg)] text-[var(--new-primary)]`;
              }

              return `${baseClasses} bg-white`;
            };

            return (
              <div key={item?.id} className={getCardClassName()} style={style}>
                <div className="flex-row align-center space-between mb-10">
                  <div className="flex-row align-center gap-2">
                    {getIcon()}
                    <span className="font15px-500 text-[var(--textBlack)]">
                      {item?.title}
                    </span>
                  </div>

                  <img
                    src={ICONS?.sidebarChevronDown}
                    alt="sidebarChevronDown"
                    onClick={() => {
                      handleClick(item?.id);
                    }}
                    className="pointer"
                  />
                </div>
                {activeAccordionId.includes(item?.id) && ( // Check if this item's id is active
                  <div className="pl-[40px] ">
                    {(index === 0 &&
                      setupData?.connect_wapp_buss_api === false) ||
                    (index === 1 &&
                      setupData?.start_buss_verification === false) ||
                    (index === 2 && setupData?.add_payment_method === false) ? (
                      <span className="font14px-400 text-[var(--font-600)] ">
                        {item?.content}
                      </span>
                    ) : (
                      <span className="font14px-400 text-[var(--font-600)] ">
                        {item?.content1}
                      </span>
                    )}
                    <div className="flex-row align-center  mt-10 gap-2">
                      {((index === 0 &&
                        setupData?.connect_wapp_buss_api === false) ||
                        (index === 1 &&
                          setupData?.start_buss_verification === false) ||
                        (index === 2 &&
                          setupData?.add_payment_method === false)) && (
                        <span className={getstyleClassName()}>
                          {item?.btnTitle}
                        </span>
                      )}
                      {/* <div className="font15px-500 text-[var(--font-600)] flex-row align-center gap-2">
                        <span>Watch Demo</span>
                        <img src={ICONS?.accountsArrow} alt="accountsArrow" />
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Overview;
