import Router from "./routes/Index";
import GlobalLoader from "./components/commonComponents/Loader/globalLoader";
import Toast from "./components/commonComponents/toast/ToastWrapper";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <>
      {/* <GlobalLoader /> */}
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <Toast />
    </>
  );
}

export default App;
