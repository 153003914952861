import React, { useEffect, useReducer, useState } from "react";
import { useOutletContext } from "react-router-dom";
import "../../styles/account.css";

// icons
import AccountBrands from "../../../../assets/customSVG/accounts/AccountBrands";

// api
import { accountList, brandListOverview, brandsInfoApi } from "../../api/Api";

// hooks
import useToggle from "../../../../hooks/useToggle";
import { useSearch } from "hooks/useSearch";

// constants
import { brandCounts } from "../../constants/home";

// components
import AccountCard from "../../components/AccountCard";
import Loader from "../../../../components/commonComponents/Loader/Index";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import SearchBar from "../../../../components/commonComponents/searchBar";
import { Modal, Skeleton } from "antd";
import BrandCreation from "../../../auth/components/onboarding/BrandCreation";
import {
  INITIAL_STATE,
  onBoardingReducer,
} from "../../../../hooks/auth/onBoardingReducer";
import Header from "../../../../components/commonComponents/modals/Header";
import NoData from "../../../../components/commonComponents/Empty/NoData";
import { ICONS } from "../../../../assets/icons";
import { textOnly } from "../../../../helper/validation";

export default function AccountsList() {
  const [clearSearch, load, setLoad] = useOutletContext();
  const [account, setAccount] = useState([]);
  const [otpError, setOtpError] = useState("");
  const [isAccount, setIsAccount] = useToggle(false);

  const [data, dispatch] = useReducer(onBoardingReducer, INITIAL_STATE);

  const [isLoading, setIsLoading] = useToggle(false);

  // redux
  const { currentAccount } = useAspSelector((state) => state?.app);

  // Search
  const { searchKey, setSearchKey, onChange: onSearchChange } = useSearch("");

  // filter
  const [brandFilter, setBrandFilter] = useState(null);

  // popover
  const [brandPopover, setBrandPopover] = useState(false);

  const handleChange = (type, primaryKey, key, value) => {
    if (otpError) {
      setOtpError("");
    }
    if (
      (primaryKey === "account" && key === "name") ||
      (primaryKey === "brand" && key === "name")
    ) {
      value = textOnly(value);
    }
    dispatch({ type, primaryKey, key, value });
  };

  // useEffect(() => {
  //   fetchBrandList(null);
  // }, [load]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchBrandList(searchKey, brandFilter?.value);
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchKey, brandFilter?.value]);

  useEffect(() => {
    fetchBrandList(searchKey, brandFilter?.value);
  }, [currentAccount, brandPopover]);

  const fetchBrandList = async (searchKey) => {
    setIsAccount(true);
    try {
      const res = await brandListOverview({
        search: searchKey,
        account_id: currentAccount?.account_id,
        status: brandFilter?.value,
        limit: 100,
      });
      setAccount(res?.data);
      if (setLoad) setLoad(false);
    } catch (error) {
      console.warn(error);
    } finally {
      setIsAccount(false);
    }
  };
  const handleFilter = (item) => {
    setBrandFilter(item);
  };

  const updateBrandCounts = (counts, account) => {
    return counts.map((brand) => {
      switch (brand.label) {
        case "Inactive Brands":
          return { ...brand, count: account?.inactive_brands_count };
        case "Onboarding":
          return { ...brand, count: account?.onboarding_brands_count };
        case "Total Brands":
          return { ...brand, count: account?.total_brand_count };
        case "Active Brands":
          return { ...brand, count: account?.active_brands_count };
        default:
          return brand;
      }
    });
  };
  const updatedBrandCounts = updateBrandCounts(brandCounts, account);

  useEffect(() => {
    if (updatedBrandCounts.length > 0) {
      setBrandFilter(updatedBrandCounts[0]);
    }
  }, []);

  return (
    <div className="w-full h-full px-5 py-7">
      <h2 className="font24px-500 text-[var(--textBlack)] mb-1">
        Account Overview
      </h2>
      <div className="w-100 flex-row align-center space-between pt-20 mt-10">
        {updatedBrandCounts?.map((item) => {
          return (
            <div
              className={` w-[23.5%] flex-row b-radius-14 p-20 pointer ${
                item?.id === brandFilter?.id
                  ? "bg-[#05805C0A] border-2 border-[var(--new-primary)]"
                  : "brand-cards"
              }`}
              key={item?.id}
              onClick={() => {
                handleFilter(item), setBrandFilter(item);
              }}
            >
              {item?.id !== 3 ? (
                <AccountBrands fillColor={item?.icon} />
              ) : (
                <img src={item?.icon} alt="icon" className="h-10" />
              )}
              <div className="flex-column pl-10">
                <span className="pt-5 pb-10 font15px-400 text-[var(--textBlack)]">
                  {item?.label}
                </span>
                <span className="font24px-600 text-[var(--textBlack)]">
                  {item?.count < 9 && item?.count !== 0
                    ? `0${item?.count}`
                    : item?.count}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="bg-white mt-20 h-[80vh] b-radius pl-20 pr-20">
        <div className="w-100 flex-row align-center space-between border-b-[1px] ">
          <SearchBar
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            onChange={onSearchChange}
            search="inbox-search"
            searchCancel="inboxSearchCancel"
            expandable="true"
            // className={`${expandable ? "w-[20vw]" : ""}`}
          />
          <div className="mt-20 mb-20">
            <span className="invisible">h</span>
            {(currentAccount?.role === "Account Owner" ||
              currentAccount?.role === "Account Manager") && (
              <button
                className="button-primary2 px-4 py-2.5"
                onClick={() => setBrandPopover(true)}
              >
                Add New Brand
              </button>
            )}
          </div>
        </div>
        {/* {isAccount ? ( */}
        <Skeleton loading={isAccount} active paragraph={{ rows: 6 }}>
          {account?.brands?.length > 0 ? (
            <div className="w-full h-[66vh] grid grid-cols-4 gap-5 pb-6 overflow-y-scroll max-[1600px]:grid-cols-3 max-[1600px]:pb-8 max-[1240px]:grid-cols-2 max-[1240px]:pb-12 max-[1440px]:pb-12 min-[1930px]:grid-cols-5 pt-20">
              {account?.brands?.length > 0 &&
                account?.brands?.map((d) => {
                  return (
                    <div key={d?.brand_id} className="max-w-96 h-full">
                      <AccountCard
                        name={d.brand_name}
                        image={d.logo_photo}
                        id={d?.brand_id}
                        lastLogin={d?.last_active_at}
                        billing_plan_name={d?.billing_plan_name}
                        total_amount={d?.total_amount}
                        clearSearch={clearSearch}
                        status={d.status}
                        brandFilter={brandFilter}
                        brandUsers={d?.brand_users}
                        end_date={d?.end_date}
                        loadData={() => {
                          // fetchBrandList(null);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          ) : brandFilter?.count === 0 || searchKey ? (
            <div className="h-[60vh] flex justify-center mt-20 pt-20">
              <div className="flex-row h-10 mt-20">
                <img
                  src={ICONS?.emptySearch}
                  alt="emptySearch"
                  className="w-14"
                />
                <div className="flex-column gap-3 pl-10">
                  <span className="new-header3 ">
                    Sorry, there are no matching results
                  </span>
                  <span className="no-search-body">
                    Use different filters or{" "}
                    <span
                      className="text-[#6940F2] pointer"
                      onClick={() => {
                        setBrandFilter(updatedBrandCounts[0]);
                        setSearchKey("");
                        // clearAccountStatusList();
                        // clearRoleList();
                      }}
                    >
                      reset to default.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-[61vh] flex-row justify-center">
              <NoData
                title="No Brands Found!"
                content="You don’t have any brands under your account. Create new brand and start using GainWix.ai."
                modal={
                  <div>
                    {(currentAccount?.role === "Account Owner" ||
                      currentAccount?.role === "Account Manager") && (
                      <button
                        className="button-primary2 px-4 py-2.5 mb-20"
                        onClick={() => setBrandPopover(true)}
                      >
                        Add new brand
                      </button>
                    )}
                  </div>
                }
                icon={ICONS?.campaignNoData}
              />
            </div>
          )}
        </Skeleton>
        {/* ) : (
        <div className="w-full h-16 bg-white rounded-md flex flex-1 justify-center items-center p-4">
          <Loader Width={35} Height={35} />
        </div>
      )}
      {!isAccount && (!account || account?.accounts_list?.length === 0) && (
        <div className="w-full h-28 bg-white rounded-md flex flex-1 justify-center items-center p-4">
          <p>No Data</p>
        </div>
      )} */}
        <Modal
          open={brandPopover}
          footer={null}
          className={"asp-modal-popup-small"}
          closable={false}
          centered
          mask={true}
          wrapClassName={"bg-[#00000065]"}
          destroyOnClose
          style={{
            width: "100%",
            borderRadius: 20,
            boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
            overflow: "hidden",
          }}
        >
          <div className="">
            <Header
              title={"Create Brand"}
              titleStyle="font20px-600 text-[var(--textBlack)]"
              handleCancel={() => {
                dispatch({ type: "reset" });
                setBrandPopover(false);
              }}
            />
            <div className="prl-20 pb-20">
              <BrandCreation
                onChange={handleChange}
                data={data?.brand}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setBrandPopover={setBrandPopover}
                setOtpError={setOtpError}
                otpError={otpError}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
