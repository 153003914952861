import React from 'react';

// assets
import Delete from '../../../../../assets/customSVG/Delete';
import EditIcon from '../../../../../assets/customSVG/EditIcon';
import Eye from '../../../../../assets/customSVG/Eye';

// helpers
import { cn } from '../../../../../helper/cn';

// components
import Tooltip from '../../../../../components/commonComponents/tooltip';
import Switch from '../../../../../components/commonComponents/step/switch';

const Controlls = ({
  sequenceDetails,
  node,
  newEdge,
  setPreviewData,
  allowToEditFlow,
  setIsPreviewModalOpen,
  isConnected,
  isEdgeConnected,
  toggleLastNode,
  toggleNodeEditMode,
  handleDeleteNode,
}) => {
  return (
    <>
      {(!['PAUSED', 'ACTIVE', 'RUNNING']?.includes(sequenceDetails?.status) ||
        (['PAUSED']?.includes(sequenceDetails?.status) &&
          sequenceDetails?.settings?.defaults?.isEditMode)) && (
        <div
          className='w-auto h-[3rem] absolute'
          style={{
            transform: node?.data
              ? node?.is_trigger
                ? `translate(0px, -108px)`
                : node?.is_last_node
                ? `translate(0px, -97px)`
                : `translate(0px, -80px)`
              : `translate(0px, -110px)`,
          }}>
          <div className='w-auto h-[2.5rem] flex items-center justify-center bg-white rounded-lg shadow-[0px_4px_30px_0px_#2D30361A] cursor-default'>
            <div
              className={cn(
                'w-full h-11 text-black hidden items-center justify-center p-1.5 rounded-lg',
                !newEdge && 'group-hover:!flex'
              )}>
              <div className='w-full flex items-center justify-center gap-1'>
                {!node?.is_trigger && isConnected && !isEdgeConnected && (
                  <Tooltip
                    position='top'
                    content='Mark it as the final node'>
                    <div className='size-8 flex items-center justify-center'>
                      <Switch
                        isChecked={node?.is_last_node}
                        toggleSwitch={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          if (isConnected && allowToEditFlow)
                            toggleLastNode({
                              nodeId: node?.uniq_id,
                              value: !node?.is_last_node,
                            });
                        }}
                        allow={isConnected && allowToEditFlow}
                      />
                    </div>
                  </Tooltip>
                )}
                <Tooltip
                  position='top'
                  content='Edit'>
                  <div
                    className={cn(
                      'size-8 flex items-center justify-center hover:bg-gray-300 rounded-md cursor-pointer',
                      !['COMPLETED', 'PAUSED', 'ACTIVE', 'RUNNING']?.includes(
                        sequenceDetails?.status
                      ) ||
                        (['PAUSED']?.includes(sequenceDetails?.status) &&
                          sequenceDetails?.settings?.defaults?.isEditMode)
                        ? 'cursor-pointer opacity-100'
                        : 'cursor-not-allowed opacity-50'
                    )}
                    onClick={(event) => {
                      event?.stopPropagation();
                      event?.preventDefault();
                      if (
                        !['COMPLETED', 'PAUSED', 'ACTIVE', 'RUNNING']?.includes(
                          sequenceDetails?.status
                        ) ||
                        (['PAUSED']?.includes(sequenceDetails?.status) &&
                          sequenceDetails?.settings?.defaults?.isEditMode)
                      ) {
                        toggleNodeEditMode({
                          id: node?.id,
                          uniqId: node?.uniq_id,
                          value: node?.settings?.defaults?.isEditMode
                            ? 'false'
                            : 'true',
                          defaults: node?.settings?.defaults,
                          node: node,
                        });
                        setPreviewData(node);
                      }
                    }}>
                    <EditIcon
                      width={14}
                      height={14}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  position='top'
                  content='Prview'>
                  <div
                    className={cn(
                      'size-8 flex items-center justify-center hover:bg-gray-300 rounded-md cursor-pointer'
                    )}
                    onClick={() => {
                      setIsPreviewModalOpen(true);
                      setPreviewData(node);
                    }}>
                    <Eye
                      width={20}
                      height={20}
                      color={'var(--font-600)'}
                    />
                  </div>
                </Tooltip>
                {!node?.is_trigger && (
                  <Tooltip
                    position='top'
                    content='Delete'>
                    <div
                      className={cn(
                        'size-8 flex items-center justify-center hover:bg-red-200 rounded-md group/delete',
                        allowToEditFlow && !node?.is_trigger
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed'
                      )}
                      onClick={(event) => {
                        event?.stopPropagation();
                        event?.preventDefault();
                        if (!node?.is_trigger && allowToEditFlow)
                          handleDeleteNode({
                            id: node?.id,
                            order: node?.order,
                            uniq_id: node?.uniq_id,
                            type: node?.type,
                            node: node,
                          });
                      }}>
                      <Delete
                        width={16}
                        height={16}
                        color={''}
                        className='stroke-[#898E99] group-hover/delete:stroke-red-500'
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Controlls;
