import { MAX_NODE_BASED_ON_PLANS } from 'modules/campaigns/constants/sequence';
import { createNode } from 'modules/campaigns/api/sequence';

import { isObjectEmpty } from '..';

const NODE_STATUS_COLOR = {
  normal: '#0ACD95',
  error: '#FF543E',
  warning: '#F6A723',
};

export const getNodeCurrentStatus = (node) => {
  if (node?.is_trigger || !node?.metrics) return null;

  const sent_count = node?.metrics?.sent_count;
  const failed_count = node?.metrics?.failed_count;

  const percentage = (failed_count / sent_count) * 100;

  const status =
    percentage < 50
      ? 'normal'
      : percentage > 50 && percentage < 70
      ? 'warning'
      : percentage >= 70
      ? 'error'
      : 'normal';

  if (status === 'normal') return null;

  return {
    status: status,
    color: NODE_STATUS_COLOR[status],
  };
};

export const allowToAddNodes = ({ nodes, currentPlans }) => {
  const maxNodes = MAX_NODE_BASED_ON_PLANS[currentPlans];
  return { allow: nodes?.length < maxNodes, maxNodes };
};

export const addNode = async ({
  nodes,
  seqId,
  type = '',
  data = {},
  coordinates = { x: 800, y: 300 },
}) => {
  const isNewNodeExist = nodes?.filter((n) => n?.label === 'Add Node');

  if (isNewNodeExist?.length !== 0) return nodes;

  const newNode = await createNode(seqId);

  return [
    ...nodes,
    {
      ...newNode,
      data: { ...newNode?.data },
      settings: {
        ...newNode?.settings,
        defaults: { ...newNode?.settings?.defaults, isEditMode: true },
      },
    },
  ];
};

export const updateNode = ({
  nodes,
  id,
  data,
  primaryKey,
  secondaryKey,
  key,
}) => {
  return nodes?.map((n) => {
    const updatedData =
      data === 'true' ? true : data === 'false' ? false : data;
    if (id === n?.uniq_id)
      if (primaryKey && secondaryKey && key)
        return {
          ...n,
          [primaryKey]: {
            ...n[primaryKey],
            [secondaryKey]: {
              ...n[primaryKey][secondaryKey],
              [key]: updatedData,
            },
          },
        };
      else if (primaryKey && !secondaryKey && key)
        return {
          ...n,
          [primaryKey]: {
            ...n[primaryKey],
            [key]: updatedData,
          },
        };
      else if (!primaryKey && !secondaryKey && key)
        return {
          ...n,
          [key]: updatedData,
        };
      else if (!primaryKey && !secondaryKey && !key) return { ...n, ...data };
      else return n;
    else if (id !== n?.uniq_id && key && key === 'isSelected')
      return { ...n, isSelected: false };
    return n;
  });
};

export const deleteNode = ({ nodes, id, key }) => {
  const res = nodes?.filter((n) => !n?.istrigger && n?.uniq_id !== id);
  return res?.map((node) => {
    return {
      ...node,
      order: key < node?.order ? node?.order - 1 : node?.order,
    };
  });
};

export const updateNodesCoordination = ({ nodes, id, data }) => {
  return nodes?.map((n) => {
    if (id === n?.uniq_id)
      return {
        ...n,
        coordinates: {
          x: n.coordinates.x + data?.x,
          y: n.coordinates.y + data?.y,
        },
      };
    return n;
  });
};

export const updateNodeDefaults = ({ nodes, id, key, data }) => {
  let payload = {};

  const res = nodes?.map((n) => {
    if (key === 'NONE') payload = { other_replies: {} };
    else if (['REPEAT_MESSAGE', 'ERROR_MESSAGE']?.includes(key))
      payload = { other_replies: { ...data } };

    if (id === n?.id)
      return {
        ...n,
        settings: {
          defaults: n?.settings?.defaults,
          connections: n?.settings?.connections,
          ...payload,
        },
      };
    return n;
  });
  return res;
};

export const formatUpdatedBroadcast = ({ node }) => {
  const { data } = node;

  if (!isObjectEmpty(data)) {
    const { broadcast, broadcast_name, language, broadcast_parts } = data;

    const updatedNodeData = {
      broadcast_id: broadcast?.id,
      broadcast_part_id: broadcast_parts?.[0]?.id,
      broadcast_name,
      settings: {
        ...broadcast?.settings,
        template_language: language,
      },
      file_path: broadcast_parts?.[0]?.settings?.file_path,
      file_name: broadcast_parts?.[0]?.settings?.file_name,
      broadcast_file_data: {
        invalid_count: '',
        valid_count: '',
        duplicate_count: '',
      },
    };

    return { ...node, data: updatedNodeData };
  }

  return node;
};

// LIST NODE
export const getRowsCount = ({ sections }) => {
  let sectionCount = 0;
  let rowCount = 0;

  if (sections) {
    sectionCount = sections.length;

    sections.forEach((section) => {
      rowCount += section?.rows?.length || 0;
    });
  }

  return { section: sectionCount, row: rowCount };
};

export const addSectionListNode = ({ nodes, id, sectionId }) => {
  const newSection = {
    rows: [
      {
        title: '',
        description: '',
      },
    ],
    title: '',
  };

  return nodes?.map((node) => {
    if (node?.id === id) {
      const updatedSections = node?.data?.interactive?.action?.sections || [];
      const updatedNode = {
        ...node,
        data: {
          ...node?.data,
          interactive: {
            ...node?.data?.interactive,
            action: {
              ...node?.data?.interactive?.action,
              sections: [...updatedSections, newSection],
            },
          },
        },
      };
      return updatedNode;
    }
    return node;
  });
};

export const addRowInSectionList = ({ nodes, id, sectionId }) => {
  const newRow = {
    title: '',
    description: '',
  };

  const updated = nodes?.map((node) => {
    if (node?.id === id)
      return {
        ...node,
        data: {
          ...node?.data,
          interactive: {
            ...node?.data?.interactive,
            action: {
              ...node?.data?.interactive?.action,
              sections: node?.data?.interactive?.action?.sections?.map(
                (s, i) => {
                  if (i + 1 === sectionId)
                    return { ...s, rows: [...s?.rows, newRow] };
                  else return s;
                }
              ),
            },
          },
        },
      };
    return node;
  });
  return updated;
};

export const updateListNodeData = ({
  nodes,
  id,
  sectionId,
  rowId,
  key,
  value,
}) => {
  const updated = nodes?.map((n) => {
    if (n?.id === id) {
      let payload = { ...n?.data?.interactive?.action };
      let updatedSection = n?.data?.interactive?.action?.sections;
      if (sectionId) {
        updatedSection = n?.data?.interactive?.action?.sections?.map(
          (s, si) => {
            if (si + 1 === sectionId) {
              if (rowId)
                return {
                  ...s,
                  rows: s?.rows?.map((r, ri) => {
                    if (rowId === ri + 1) return { ...r, [key]: value };
                    else return r;
                  }),
                };
              else return { ...s, [key]: value };
            }
            return s;
          }
        );
        payload['sections'] = updatedSection;
      } else {
        payload['button'] = value;
      }

      return {
        ...n,
        data: {
          ...n?.data,
          interactive: {
            ...n?.data?.interactive,
            action: {
              ...n?.data?.interactive?.action,
              ...payload,
            },
          },
        },
      };
    }
    return n;
  });

  return updated;
};

export const deleteListFromNode = ({
  nodes,
  id,
  sectionId,
  rowId,
  key,
  value,
}) => {
  const updated = nodes?.map((n) => {
    let isAbleToRemoveSection = false;
    if (n?.id === id) {
      let updatedSection = n?.data?.interactive?.action?.sections;
      if (!rowId)
        updatedSection = updatedSection?.filter(
          (s, si) => si + 1 !== sectionId
        );
      else
        updatedSection = updatedSection?.map((s, si) => {
          if (si + 1 === sectionId) {
            const updatedRows = s?.rows?.filter((r, ri) => ri + 1 !== rowId);
            if (updatedRows?.length === 0) isAbleToRemoveSection = true;
            return { ...s, rows: updatedRows };
          }
          return s;
        });

      if (isAbleToRemoveSection) {
        updatedSection = updatedSection?.filter(
          (s, si) => si + 1 !== sectionId
        );
      }

      return {
        ...n,
        data: {
          ...n?.data,
          interactive: {
            ...n?.data?.interactive,
            action: {
              ...n?.data?.interactive?.action,
              sections: updatedSection,
            },
          },
        },
      };
    }
    return n;
  });

  return updated;
};

// BUTTON NODE
export const addButtonToInteractiveMessage = ({ nodes, id }) => {
  const newButton = {
    type: 'reply',
    reply: {
      title: '',
    },
  };

  return nodes?.map((node) => {
    if (node?.id === id)
      return {
        ...node,
        data: {
          ...node?.data,
          interactive: {
            ...node?.data?.interactive,
            action: {
              ...node?.data?.interactive?.action,
              buttons: [...node?.data?.interactive?.action?.buttons, newButton],
            },
          },
        },
      };

    return node;
  });
};

export const updateButtonToInteractiveMessage = ({ nodes, id, key, value }) => {
  return nodes?.map((node) => {
    if (node?.uniq_id === id)
      return {
        ...node,
        data: {
          ...node?.data,
          interactive: {
            ...node?.data?.interactive,
            action: {
              ...node?.data?.interactive?.action,
              buttons: node?.data?.interactive?.action?.buttons?.map((b, i) => {
                if (key === i + 1) return { ...b, reply: { title: value } };
                return b;
              }),
            },
          },
        },
      };

    return node;
  });
};

export const deleteButtonToInteractiveMessage = ({ nodes, id, key, value }) => {
  return nodes?.map((node) => {
    if (node?.id === id)
      return {
        ...node,
        data: {
          ...node?.data,
          interactive: {
            ...node?.data?.interactive,
            action: {
              ...node?.data?.interactive?.action,
              buttons: node?.data?.interactive?.action?.buttons?.filter(
                (b, i) => key !== i + 1
              ),
            },
          },
        },
      };

    return node;
  });
};

// ALLOW TO SHOW SUBMIT
export const isValidHeader = ({ node, selectedTab }) => {
  const { type, data } = node || {};
  const { key } = selectedTab || {};

  // Check if node type is 'MEDIA'
  if (type === 'MEDIA' && data && !isObjectEmpty(data)) {
    const mediaLink = data[data.type?.toLowerCase()]?.link;
    return mediaLink?.length > 0;
  }

  // Check if node type is 'BUTTON' or 'LIST' and selectedTab key is not 'NONE'
  if (['BUTTON', 'LIST'].includes(type) && key !== 'NONE') {
    const interactive = data?.interactive;
    const header = interactive?.header;

    if (header) {
      if (key === 'TEXT') return header?.text?.length > 0;

      const link = header[header?.type]?.link;
      return link?.length > 0;
    }
    return false;
  }

  return true;
};

export const isValidBody = ({ node }) => {
  const { type, data } = node || {};

  // Handle 'TEXT' node type
  if (type === 'TEXT' && data && !isObjectEmpty(data))
    return data.text?.body?.length > 0;

  // Handle 'BUTTON' and 'LIST' node types
  if (['BUTTON', 'LIST'].includes(type)) {
    const interactiveBody = data?.interactive?.body;
    return interactiveBody?.text?.length > 0;
  }

  return true;
};

export const isValidFooter = ({ node, footerTab }) => {
  const isButtonOrList = ['BUTTON', 'LIST'].includes(node?.type);

  if (!isButtonOrList) return true;

  if (footerTab?.key === 'TEXT') {
    const footerText = node?.data?.interactive?.footer?.text;
    return footerText?.length > 0;
  }

  return true;
};

export const isValidTemplate = ({ node, selectedTemplate }) => {
  return ['TEMPLATE']?.includes(node?.type)
    ? selectedTemplate
      ? node?.status === 'UN_SAVED'
        ? selectedTemplate?.name
        : node?.data?.template_name !== selectedTemplate?.name
      : false
    : true;
};

export const isValidButton = ({ node }) => {
  if (node?.type !== 'BUTTON' || isObjectEmpty(node?.data)) return true;

  const isEmptyButtons = node?.data?.interactive?.action?.buttons?.every(
    (b) => b?.reply?.title !== ''
  );

  return isEmptyButtons;
};

export const isValidList = ({ node }) => {
  if (node?.type !== 'LIST' || isObjectEmpty(node?.data)) return true;

  // Check if any section has an empty title
  const isEmptySection = node?.data?.interactive?.action?.sections?.some(
    (s) => s?.title === ''
  );

  // Check if any row in any section has an empty title
  const isEmptyRow = node?.data?.interactive?.action?.sections?.some((s) =>
    s?.rows?.some((r) => r?.title === '')
  );

  return !isEmptySection && !isEmptyRow;
};

export const isValidDefaultReply = ({ node }) => {
  const otherReplies = node?.settings?.other_replies;

  if (!otherReplies || isObjectEmpty(otherReplies)) return true;

  const { type, body } = otherReplies;

  if (type === 'repeat') return true;
  if (type === 'compose' && body?.length > 0) return true;

  return false;
};
