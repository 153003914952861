import { Modal, Popover, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./appLayout.css";

//custom-svg
import Settings from "../../assets/customSVG/Settings";
import Channels from "../../assets/customSVG/navChannels";
import CRM from "../../assets/customSVG/sideNav/CRM";
import Calendar from "../../assets/customSVG/sideNav/Calendar";
import Campaigns from "../../assets/customSVG/sideNav/Campaigns";
import Chevron from "../../assets/customSVG/sideNav/Chevron";
import Home from "../../assets/customSVG/sideNav/Home";
import UnifiedInbox from "../../assets/customSVG/sideNav/UnifiedInbox";

//icons
import { ICONS } from "../../assets/icons";
import { ICONSS } from "../../assets/icons/index1";

//helper
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAspDispatch, useAspSelector } from "../../test/jest-redux-hooks";

//redux
import { setPageNavigate } from "../../reduxToolkit/CampaignSlice";
import {
  setUpdateAccount,
  updateCurrentUser,
  updateToggleToast,
} from "../../reduxToolkit/appSlice";

//api
import { downgradeApi, draftApi } from "../../modules/campaign/api/Api";
import {
  carouselDiscard,
  templateDiscard,
} from "../../modules/channels/api/Api";

//common-components
import HeaderDropDown from "../../components/commonComponents/HeaderDropDown/HeaderDropDown";
import NewAlert from "../../components/commonComponents/modal/alert/NewAlert";

// constant
import {
  creditsApi,
  currentUserDetails,
  Logout,
  markRecentAccountActive,
  markRecentActive,
} from "../../api/Api";
import AccountOverview from "../../assets/customSVG/accounts/AccountOverview";
import Purchases from "../../assets/customSVG/accounts/Purchases";
import Teams from "../../assets/customSVG/accounts/Teams";
import { myProfile } from "../../constant/app/account/account";
import {
  accountNavigation,
  navigationConst,
} from "../../constant/app/appLayout";
import useToggle from "../../hooks/useToggle";
import UsagePlan from "./UsagePlan";

const iconComponents = {
  Home: Home,
  Campaigns: Campaigns,
  Calendar: Calendar,
  CRM: CRM,
  Inbox: UnifiedInbox,
  Channels: Channels,

  Settings: Settings,
  "Account Info": Home,
  "Account Users": Teams,
  Brands: AccountOverview,
  Purchases: Purchases,
};

const selectedIcons = {
  // Home: ICONSS?.homeSelected,
  Campaigns: ICONSS?.campaignSelected,
  Calendar: ICONSS?.calendarSelected,
  CRM: ICONSS?.crmSelected,
  Inbox: ICONSS?.inboxSelected,
  Channels: ICONSS?.channelSelected,
  Settings: ICONSS?.settingSelected,
};

function SideBar() {
  //redux
  const templateDetails = useAspSelector((state) => state?.Template);
  const { currentBrand, toggleToast, currentUser, currentAccount } =
    useAspSelector((state) => state?.app);
  const { saveBroadcastDatas } = useAspSelector((state) => state.broadcast);

  //react-redux
  const dispatch = useAspDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();

  const [hoveredNav, setHoveredNav] = useState(null);
  const [expand, setExpand] = useState(false);

  //modals && popover
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const [draftAlertOpen, setDraftAlertOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPlansVisible, setIsPlansVisible] = useState(false);
  const [isPopover, setIsPopover] = useState(false);
  const [loader, setLoader] = useToggle(false);

  const [downGradePopover, setDownGradePopover] = useState(false);

  // credits
  const [credits, setCredits] = useState([]);

  const handleDowngrade = () => {
    const payLoad = {
      brand_id: currentBrand?.brand_id,
    };
    downgradeApi(payLoad).then((res) => {
      if (res?.status === 200) {
        setDownGradePopover(false);
        currentUserDetails().then((res) => {
          if (res?.status === 200) {
            dispatch(updateCurrentUser(res?.data));
            setDownGradePopover(false);
            window.location.reload();
          }
        });
      }
    });
  };

  const matchingBrandStatuses = currentUser?.active_accounts?.flatMap(
    (account) =>
      account?.brands
        ?.filter((brand) => brand?.brand_id === currentBrand?.brand_id)
        ?.map((brand) => brand?.brand_status)
  );

  useEffect(() => {
    if (
      matchingBrandStatuses &&
      matchingBrandStatuses?.includes(9) &&
      location?.pathname?.startsWith("/user")
    ) {
      setDownGradePopover(true);
    }
  }, [matchingBrandStatuses, location]);

  const { id } = useParams();
  const header = id;

  const navigationPath =
    pathname !== "/profile" ? navigationConst : accountNavigation;
  const [navList, setNavList] = useState(navigationPath);

  const handleExpand = () => {
    setExpand(!expand);
  };

  //modals-popover functions
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsPlansVisible(false);
  };

  const handleYes = () => {
    setLoader(true);

    Logout().then((res) => {
      if (res?.status === 200) {
        navigate("/login");
        localStorage.clear();
      } else {
        console.warn("error", res);
      }
    });
  };

  const handleItemClick = () => {
    setIsModalVisible(true);
    setIsPopover(false);
  };

  const handleVisibleChange = () => {
    setIsPlansVisible(!isPlansVisible);
  };

  const discardCarousel = () => {
    if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType === "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      carouselDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType !== "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      templateDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else {
      if (saveBroadcastDatas?.broadcast_id !== null) {
        draftApi(saveBroadcastDatas).then((createCampaignResponse) => {
          if (createCampaignResponse?.status === 200) {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Broadcast saved successfully",
                  status: "Success",
                  duration: "",
                },
              ])
            );
            setDraftAlertOpen(false);
            navigate("/user/campaign/all");
          } else {
            setDraftAlertOpen(false);

            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content:
                    createCampaignResponse?.response?.data?.error ||
                    "Unable to save Broadcast",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        });
      }
    }
  };

  //modules navigate functions
  useEffect(() => {
    if (pathname.startsWith("/profile")) {
      if (
        currentAccount?.role === "Account User" ||
        currentAccount?.role === "Brand Manager" ||
        currentAccount?.role === "Campaign Manager"
      ) {
        const filteredNavList = accountNavigation.filter(
          (item) => item.id !== 2 && item.id !== 3
        );
        setNavList(filteredNavList);
      } else {
        setNavList(accountNavigation);
      }
    } else {
      setNavList(navigationConst);
    }

    updateNavListState(pathname);
  }, [pathname, currentBrand]);

  const navigateModule = (item) => {
    dispatch(setUpdateAccount(false));

    if (location.pathname.startsWith("/user")) {
      creditsApi(currentBrand?.brand_id).then((res) => {
        if (res?.status === 200) {
          setCredits(res?.data?.brand_credits);
        }
      });
    }

    // if (currentBrand && currentBrand?.is_whatsapp_connected) {
    if (
      location.pathname !==
        "/user/channels/whatsapp/edit-template/template-details" &&
      location.pathname !== `/user/campaign/time_specified/settings/${header}`
    ) {
      updateNavListState(item?.routeUrl);
      item?.navName === "Channels"
        ? navigate("/user/channels/whatsapp")
        : item?.navName === "Home"
        ? navigate("/user/home")
        : navigate(`.${item?.routeUrl}`);
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      (templateDetails?.details?.status === "PAM" ||
        templateDetails?.details?.status === "APPROVED" ||
        templateDetails?.details?.status === "REJECTED" ||
        templateDetails?.details?.status === "PRM")
    ) {
      setDiscardAlertOpen(true);
    } else if (
      location.pathname ===
        `/user/campaign/time_specified/settings/${header}` &&
      saveBroadcastDatas?.broadcast_id !== null
    ) {
      setDraftAlertOpen(true);
    } else {
      updateNavListState(item?.routeUrl);
      item?.navName === "Channels"
        ? navigate("/user/channels/whatsapp")
        : item?.navName === "Home"
        ? navigate("/user/home")
        : navigate(`.${item?.routeUrl}`);
    }

    if (location.pathname.startsWith("/user")) {
      markRecentActive(currentBrand?.brand_id, currentAccount?.account_id);
      markRecentAccountActive(currentAccount?.account_id);
    } else {
      markRecentAccountActive(currentAccount?.account_id);
    }

    // account last active api

    // }
  };
  const updateNavListState = (path) => {
    if (location.pathname.startsWith("/user")) {
      creditsApi(currentBrand?.brand_id).then((res) => {
        if (res?.status === 200) {
          setCredits(res?.data?.brand_credits);
        }
      });
    }

    setNavList((prev) => {
      const newState = prev?.map((prevItem) => {
        if (
          path?.includes(prevItem?.routeUrl) ||
          (prevItem?.navName === "Campaigns" &&
            (path?.includes("/user/campaign") ||
              path?.includes("/user/api") ||
              path?.includes("/user/ContactApi")))
        ) {
          return { ...prevItem, selected: true };
        } else {
          return { ...prevItem, selected: false };
        }
      });
      return newState;
    });
  };

  useEffect(() => {
    if (location.pathname.startsWith("/user")) {
      creditsApi(currentBrand?.brand_id).then((res) => {
        if (res?.status === 200) {
          setCredits(res?.data?.brand_credits);
        }
      });
    }
  }, [location]);

  const formatNumber = (num) => {
    if (num >= 1_000_000) {
      return `${(num / 1_000_000).toFixed(2)}M`;
    } else if (num >= 1000) {
      return `${(num / 1000).toFixed(2)}K`;
    } else {
      return num.toString();
    }
  };

  return (
    <nav
      className={`side_bar_container flex-column space-between  py-4 ${
        !expand
          ? "nav-open border border-[#F1F4F6]"
          : "nav-close border border-[#F1F4F6]"
      }`}
    >
      <div>
        <div
          className={`w-100 flex-row align-center   ${
            !expand ? "space-between" : " translate-x-1"
          }`}
        >
          {!expand ? (
            <img src={ICONS?.logoGain} alt="logoGain" />
          ) : (
            <img src={ICONS?.logoMinimize} alt="logoMinimize" className="" />
          )}
          <span
            onClick={handleExpand}
            className={`pointer z-50  ${
              !expand ? "" : "rotate-180 translate-x-2"
            }`}
          >
            <img src={ICONS?.sideNavExpand} alt="sideNavExpand" />
          </span>
        </div>
        {(pathname.includes("/user") || pathname.includes("/profile")) && (
          <div className="header-line ptb-5 mt-20">
            <HeaderDropDown expand={expand} />
          </div>
        )}
        <div className={`${!expand ? " " : "pl-[3px]"}`}>
          {navList?.map((item) => {
            return (
              <div
                key={item?.id}
                className={`navBox mt-20 ${
                  item?.selected ? "active-listText1" : ""
                }`}
                onMouseEnter={() => setHoveredNav(item?.navName)}
                onMouseLeave={() => setHoveredNav(null)}
                onClick={() => {
                  navigateModule(item);
                  if (item?.navName === "Campaigns") {
                    dispatch(setPageNavigate("All Campaigns"));
                  }
                }}
              >
                <Tooltip title={expand ? `${item?.navName}` : ""}>
                  {(() => {
                    const IconComponent = iconComponents[item?.navName];
                    const selectedIcon = selectedIcons[item?.navName];

                    const color = item?.selected
                      ? "white"
                      : hoveredNav === item?.navName
                      ? "var(--textBlack)"
                      : "#616874";

                    const fillColors = item?.selected ? "white" : "none";

                    if (selectedIcon && item?.selected) {
                      return <img src={selectedIcon} alt="" />;
                    }

                    if (IconComponent) {
                      return (
                        <IconComponent
                          color={color}
                          lineColor={color}
                          fillColor={color}
                          fillColors={fillColors}
                          width={
                            item?.navName === "Channels" ? "22" : undefined
                          }
                          height={
                            item?.navName === "Channels" ? "21" : undefined
                          }
                        />
                      );
                    }
                    return null;
                  })()}
                </Tooltip>

                <span
                  className={`navName pl-10 ${!expand ? "block" : "hidden"}`}
                >
                  {item?.navName}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <Popover
          content={<UsagePlan handleCancel={handleCancel} />}
          trigger="click"
          overlayClassName="custom-popover"
          open={isPlansVisible}
          onOpenChange={handleVisibleChange}
          arrow={false}
        >
          {/* <div className="bg-white border border-[#E8E8EA] b-radius p-[15px]">
            <div className="flex-row align-center space-between">
              <span>Free Trial</span>
              <span>Free Trial</span>
            </div>
            <div className="progress-container">
              <progress
                value={50}
                max="100"
                className="custom-progress"
                // className={`custom-progress
                //    ${progressBarColor(
                //   l?.progress_status
                // )}`}
              />
            </div>
            <span>Expires:</span>
            <button className="usage-plan flex-row align-center">
              <p>Upgrade</p>
            </button>
          </div> */}
          {/* {expand && (
            <button
              className="upgrade-plan-button cursor-pointer ml-15"
              // onClick={() => {
              //   setIsUpgrade(true);
              //   handleCancel();
              // }}
            >
              Upgrade Plan
            </button>
          )} */}
        </Popover>

        <div className="flex-column  pl-10 pt-20">
          {location?.pathname?.startsWith("/user") && (
            <>
              {!expand && credits?.total_credits < 100 && (
                <span className="text-white font12px-500 bg-[#FF543E] border-[#FF543E]  rounded-tr-xl rounded-tl-xl p-5 w-70">
                  {credits?.total_credits < 100 && credits?.total_credits !== 0
                    ? "Credits running low!"
                    : credits?.total_credits === 0
                    ? "Credits exhausted"
                    : ""}
                </span>
              )}
              <Tooltip
                title={<>&#x20b9; {credits?.total_credits} Credits left</>}
                className="w-100"
              >
                <div
                  className={`mb-20 flex-row align-center  gap-2 border border-[var(--border-input)] b-radius-6 prl-10 py-2 text-[var(--font-600)] pointer ${
                    expand ? "justify-center" : ""
                  }`}
                  onClick={() => navigate("/profile/account/overview")}
                >
                  <img
                    src={ICONSS?.creditsSideBar}
                    alt="creditsSideBar"
                    className="pr-5"
                  />
                  {!expand && (
                    <>
                      <span className="font15px-600">
                        &#x20b9;{" "}
                        {credits?.total_credits &&
                          formatNumber(credits?.total_credits)}
                      </span>
                      <span className="font15px-400">Credits</span>
                    </>
                  )}
                </div>
              </Tooltip>
            </>
          )}
          <Popover
            open={isPopover}
            onOpenChange={(open) => setIsPopover(open)}
            trigger={"click"}
            arrow={false}
            content={
              <div className="w-[300px] b-radius-6">
                <div className="flex-row gap-2 align-center bg-[var(--channelButton)] b-radius-6 ptb-20 prl-10">
                  <div className=" rounded-full flex items-center justify-center font-largest weight-medium">
                    {/* <Avatar color="#ffffff" /> */}
                    {currentUser?.profile_photo ? (
                      <img
                        src={currentUser?.profile_photo}
                        alt="profileBoy"
                        className="w-[2.2vw] object-cover rounded-full"
                      />
                    ) : (
                      <img src={ICONSS?.profileBoy} alt="profileBoy" />
                    )}
                  </div>
                  <div className="flex-column">
                    <span className="w-[25ch] truncatefont16px-500 text-[var(--textBlack)]">
                      {currentUser?.full_name}
                    </span>
                    <Tooltip
                      title={
                        currentUser?.email?.length > 25 && currentUser?.email
                      }
                    >
                      <span className="w-[25ch] truncate font14px-400 text-[var(--font-600)]">
                        {currentUser?.email}
                      </span>
                    </Tooltip>
                  </div>
                </div>
                {myProfile?.map((item) => (
                  <div
                    key={item?.id}
                    className="gap-3 flex-row align-center p-20 ml-5 font15px-400 text-[var(--font-600)] pointer"
                    onClick={() => {
                      if (item?.id === 4) {
                        handleItemClick(item);
                      }
                    }}
                  >
                    <img src={item?.icon} about="icon" />
                    {item?.label}
                  </div>
                ))}
              </div>
            }
            overlayClassName="my-profile-popover"
          >
            <div className="w-90 flex-row align-center space-between pointer">
              <div className="flex-row align-center">
                <div className="size-[2.18vw] rounded-full flex items-center justify-center font-largest weight-medium">
                  {/* <Avatar color="#ffffff" /> */}
                  {currentUser?.profile_photo ? (
                    <img
                      src={currentUser?.profile_photo}
                      alt="profileBoy"
                      className="w-[2.2vw] object-cover rounded-full"
                    />
                  ) : (
                    <img src={ICONSS?.profileBoy} alt="profileBoy" />
                  )}
                </div>
                {!expand && (
                  <span className="navName text-[var(--textBlack)] pl-10">
                    My Profile
                  </span>
                )}
              </div>
              {!expand && (
                <Chevron
                  color={"var(--font-600)"}
                  strokeColor={"var(--font-600)"}
                />
              )}
            </div>
          </Popover>
        </div>
      </div>

      <Modal
        open={isModalVisible}
        setOpen={setIsModalVisible}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
        footer={false}
      >
        <NewAlert
          handleCancel={handleCancel}
          open={isModalVisible}
          setOpen={() => setIsModalVisible(true)}
          handleNo={() => setIsModalVisible(false)}
          handleYes={handleYes}
          icon={ICONS?.logout}
          content={<p>Are you sure you want to Logout ?</p>}
          title={"Log out, really"}
          noName="Yes, logout"
          buttonColor="var(--new-primary)"
          yesName=" No, I'll stay"
          noButtonClassName="!font-md !weight-semibold"
          loading={loader}
          yesButtonClassName="py-[0.25vw]"
        />
      </Modal>

      <Modal
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>
                You have made some changes in the template. How would you like
                to proceed.
              </p>
            </div>
          }
          noName="Continue Editing"
          yesName="Discard"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel();
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>
      <Modal
        footer={null}
        open={draftAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={draftAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDraftAlertOpen}
          content={
            <div>
              <p>What would you like to do with the broadcast?</p>
            </div>
          }
          noName="Continue Editing"
          yesName="Draft"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel(header);
          }}
          handleYes={() => {
            setDraftAlertOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={downGradePopover}
        footer={null}
        className={"asp-modal-popup-medium"}
        closable={false}
        centered
        mask={true}
        wrapClassName={"bg-[#00000065]"}
        destroyOnClose
        style={{
          width: "100%",
          borderRadius: 20,
          boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
          overflow: "hidden",
        }}
      >
        <div className="px-28 py-10 text-center">
          <div className="flex justify-center items-center">
            <img src={ICONS?.logoGain} alt="logoGain" />
          </div>

          <h2 className="text-[1.875vw] font-[500] my-3">
            Your Subscription Has Expired!!! 😢
          </h2>
          <p className="text-[#616874] font15px-400">
            Your subscription has expired. Renew today to keep enjoying our
            services and access the latest features!
          </p>

          <div className="flex gap-5">
            <button
              className={`w-full h-12 rounded-md  text-[#4C515B] text-base border mt-9`}
              onClick={() => handleDowngrade()}
            >
              Downgrade Plan
            </button>

            <button
              className={`w-full h-12 rounded-md bg-[var(--new-primary)] text-white text-base weight-bold mt-9`}
              onClick={() => {
                navigate("/profile/account/overview");
                setDownGradePopover(false);
              }}
            >
              Proceed to Pay
            </button>
          </div>
          <p className=" italic font15px-400 text-[#616874] mt-20">
            For inquiries, mail us at{" "}
            <span className="text-[#6940F2] font15px-600">
              support@gainwix.ai.{" "}
            </span>
          </p>
        </div>
      </Modal>
    </nav>
  );
}

export default SideBar;
