import React, { useCallback } from 'react';

// assets
import ImageSvg from 'assets/customSVG/ImageSvg';
import { ICONS } from 'assets/icons';

// utils
import { allowedCustomButtonTypes } from '../../../../../components/commonComponents/templates/Buttons/utils';

// helpers
import { cn } from '../../../../../helper/cn';
import {
  addLineBreake,
  replaceFormating,
  replaceVariables,
} from '../../../../../modules/inbox/helper';

// components
import EdgeHandler from '../edge/Handler';
import { node } from 'prop-types';

export default function CarouselCard({
  node,
  data,
  useTemplates,
  carousel,
  className,
  onEdgeMouseDown,
  checkIsEdgeConnected,
  allowToEditFlow,
}) {
  const btnStyle1 = `w-full flex flex-1 items-center justify-center gap-1 h-8 text-[#007AFF] text-[0.813rem] weight-medium`;
  const isNotQuickReply = 'opacity-50 cursor-not-allowed';

  const bodyVariables = useCallback(() => {
    return data?.bodyVariables?.map((v) => v?.value);
  }, [data]);

  return (
    <div className='w-full h-full bg-white rounded-lg'>
      <div className='w-full h-[4.625rem] rounded-md flex gap-2.5 overflow-hidden'>
        <div className='w-1/3'>
          {data?.image ? (
            <>
              {carousel?.header === 'IMAGE' && (
                <img
                  src={data?.image}
                  alt='carousel product image'
                  className='w-full h-full object-cover rounded-md'
                />
              )}
              {carousel?.header === 'VIDEO' && (
                <video
                  className='w-full h-full object-cover rounded-md'
                  src={data?.image}
                  autoPlay
                />
              )}
            </>
          ) : (
            <div className='w-full h-[8rem] flex items-center justify-center'>
              <ImageSvg
                color={'var(--BG-200)'}
                width={40}
                height={40}
              />
            </div>
          )}
        </div>
        {data?.bodyText && (
          <div
            className='w-2/3 max-w-[10rem] h-12 text-[0.813rem] weight-medium break-words line-clamp-3 overflow-hidden'
            dangerouslySetInnerHTML={{
              __html: addLineBreake(
                replaceFormating(
                  replaceVariables(data?.bodyText, bodyVariables())
                )
              ),
            }}></div>
        )}
      </div>

      <div className='pt-2.5 flex flex-col gap-1.5'>
        {data?.buttons?.map((b) => {
          const connection_point = {
            id: b?.id,
            type: 'action',
          };
          let isConnected = false;

          if (checkIsEdgeConnected) {
            isConnected = checkIsEdgeConnected({
              start_node_id: node?.uniq_id,
              connection_point,
            });
          }

          return (
            <div className='relative font-sm weight-medium w-fill flex items-center justify-center border border-[var(--border-50)] rounded'>
              {b?.type === 'URL' && (
                <button className={cn(btnStyle1, isNotQuickReply)}>
                  <img
                    src={ICONS?.nounLink}
                    alt='urlIcon'
                    className='size-[0.9vw]'
                  />
                  {b?.text}
                </button>
              )}
              {b?.type === 'PHONE_NUMBER' && (
                <button className={cn(btnStyle1, isNotQuickReply)}>
                  <img
                    src={ICONS?.call}
                    alt='phoneNumberIcon'
                    className='size-[0.95vw]'
                  />
                  {b?.text}
                </button>
              )}

              {b?.type === 'COPY_CODE' && (
                <button className={cn(btnStyle1, isNotQuickReply)}>
                  <img
                    src={ICONS?.CopyLeft}
                    alt='copeCodeIcon'
                    className='size-[0.95vw]'
                  />
                  <span>Copy Offer Code</span>
                </button>
              )}

              {b?.type === 'QUICK_REPLY' && (
                <div
                  key={`custom-button`}
                  className={cn(btnStyle1)}>
                  <img
                    src={ICONS?.shareLink}
                    alt='shareBtn'
                    className='size-[0.8vw]'
                  />
                  <p className=''>{b?.text}</p>
                </div>
              )}
              {allowedCustomButtonTypes?.includes(b?.type) && (
                <div className='w-auto h-8 absolute top-2 right-1'>
                  <EdgeHandler
                    type='start'
                    isConnected={isConnected}
                    allowToEditFlow={allowToEditFlow}
                    onEdgeMouseDown={({ position }) => {
                      if (!isConnected)
                        onEdgeMouseDown({ connection_point, position });
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
