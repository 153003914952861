import React from 'react';

// assets
import DropDown from 'assets/customSVG/accounts/DropDown';

// helpers
import { cn } from '../../../../../helper/cn';
import { formatNumber } from '../../../helpers';

// export const formatNumber = (num) => num?.toLocaleString();

const Metrics = ({ metrics, type }) => {
  const containerStyle = 'flex flex-col gap-1 justify-center items-center';
  const textStyle = 'text-[13px] weight-medium text-[var(--font-600)]';
  const countStyle = 'text-[15px] weight-semibold text-[var(--font-600)]';
  const dotStyle = 'size-2.5 contents-[""] rounded-full';
  const line =
    'contents-[""] border-[0.5px] border-[var(--border-100)] h-9 mx-1';

  return (
    <div className='w-full h-[4.375rem] relative flex items-center gap-5 bg-white px-5 py-2.5 rounded-2xl shadow-[0px_3.1px_23.21px_0px_#2D30361A]'>
      {type === 'TRIGGER' ? (
        <div className={cn(containerStyle)}>
          <p className={cn(countStyle)}>
            {(metrics?.triggered_count &&
              formatNumber(metrics?.triggered_count)) ||
              0}
          </p>
          <p className='flex items-center gap-2.5'>
            <span className={cn(dotStyle, 'bg-[#FF543E]')}></span>
            <span className={cn(textStyle)}>Triggered</span>
          </p>
        </div>
      ) : (
        <>
          <div className={cn(containerStyle)}>
            <p className={cn(countStyle)}>
              {(metrics?.sent_count && formatNumber(metrics?.sent_count)) || 0}
            </p>
            <p className='flex items-center gap-2.5'>
              <span className={cn(dotStyle, 'bg-[#566F8F]')}></span>
              <span className={cn(textStyle)}>Sent</span>
            </p>
          </div>
          <div className={cn(line)}></div>
          <div className={cn(containerStyle)}>
            <p className={cn(countStyle)}>
              {(metrics?.delivered_count &&
                formatNumber(metrics?.delivered_count)) ||
                0}
            </p>
            <p className='flex items-center gap-2.5'>
              <span className={cn(dotStyle, 'bg-[#04B8A2]')}></span>
              <span className={cn(textStyle)}>Delivered</span>
            </p>
          </div>

          <div className={cn(line)}></div>
          <div className={cn(containerStyle)}>
            <p className={cn(countStyle)}>
              {(metrics?.abandoned_count &&
                formatNumber(metrics?.abandoned_count)) ||
                0}
            </p>
            <p className='flex items-center gap-2.5'>
              <span className={cn(dotStyle, 'bg-[#FF543E]')}></span>
              <span className={cn(textStyle)}>Abandoned</span>
            </p>
          </div>
        </>
      )}

      <div className={cn('', line)}></div>
      <div className={cn(containerStyle)}>
        <p className={cn(countStyle)}>
          {(metrics?.failed_count && formatNumber(metrics?.failed_count)) || 0}
        </p>
        <p className='flex items-center gap-2.5'>
          <span className={cn(dotStyle, 'bg-[#F6A723]')}></span>
          <span className={cn(textStyle)}>Failure</span>
        </p>
      </div>

      <div className='absolute -bottom-[1.125rem] left-1/2 -translate-x-1/2'>
        <DropDown
          width={34}
          height={32}
          color={'white'}
        />
      </div>
    </div>
  );
};

export default Metrics;
