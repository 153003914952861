// timeUtils.js
export const getTimeStringSeq = (hours, minutes, periods, updateKey = null) => {
  if (!hours || !minutes || !periods) {
    return '';
  }

  let formattedHours = parseInt(hours);
  // if (updateKey !== 'periods') {
  //   if (periods === 'PM' && formattedHours !== 12) {
  //     periods = 'AM';
  //   } else if (periods === 'AM' && formattedHours === 12) {
  //     periods = 'PM';
  //   }
  // }

  const timeString = `${formattedHours
    .toString()
    .padStart(2, '0')}:${minutes.padStart(2, '0')} ${periods}`;

  return timeString;
};

export const getTimeString = (hours, minutes, periods) => {
  if (!hours || !minutes || !periods) {
    return '';
  }

  let formattedHours = parseInt(hours);

  if (periods === 'PM' && formattedHours !== 12) {
    periods = 'AM';
  } else if (periods === 'AM' && formattedHours === 12) {
    periods = 'PM';
  }

  const timeString = `${formattedHours
    .toString()
    .padStart(2, '0')}:${minutes.padStart(2, '0')} ${periods}`;

  return timeString;
};

export const formatTimeWithAmPm = (timeString) => {
  if (!timeString || typeof timeString !== 'string') {
    return '';
  }

  const [hours, minutes] = timeString.split(':').map((str) => parseInt(str));
  if (isNaN(hours) || isNaN(minutes)) {
    return '';
  }

  const formattedHours = hours % 12 || 12;
  const amPm = hours >= 12 ? 'PM' : 'AM';
  return `${formattedHours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${amPm}`;
};
