import { Modal, Popover } from 'antd';
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// api
import {
  changeSequenceStatus,
  sendWhatsappPreview,
  updateSequence,
} from '../../api/sequence';

//assets
import AddIcon from 'assets/customSVG/AddIcon';
import Arrow from 'assets/customSVG/Arrow';
import FullArrow from 'assets/customSVG/FullArrow';
import SequencePreview from 'assets/customSVG/sequence/SequencePreview';
import EditIcon from 'assets/customSVG/sequence/EditIcon';
import Delete from '../../../../assets/customSVG/Delete';
import { ICONS } from '../../../../assets/icons';

// hooks
import useToggle from '../../../../hooks/useToggle';

// helpers
import { cn } from '../../../../helper/cn';
import { dateConverter } from '../../../../helper/dateConverter';
import { numberOnly } from '../../../../helper/validation';

// constants
import {
  PREVIEW_CONTENTS,
  SEQUENCE_STATUS_STYLES,
} from '../../constants/sequence';

// redux
import { useAspSelector } from '../../../../test/jest-redux-hooks';

// components
import CountryCode from '../../../../components/commonComponents/countryCode/index';
import Alert from '../../../../components/commonComponents/modals/Alert';
import ModalHeader from '../../../../components/commonComponents/modals/Header';
import Preview from './overview/Preview';
import Tooltip from '../../../../components/commonComponents/tooltip';
import Loader from '../../../../components/commonComponents/Loader/Index';
import PreviewModalHeader from './preview/PreviewModalHeader';
import { previewResetMessage } from '../../api/SquenceOverview';

const status = {
  DRAFT: 'Activate',
  NEW: 'Activate',
  RUNNING: 'Pause',
  ACTIVE: 'Pause',
  PAUSED: 'Activate',
  SCHEDULED: 'Pause',
  COMPLETED: 'Activate',
};

const initalConatactList = { id: 1, country: '+91', phone_number: '' };

const Header = ({
  triggerData,
  sequenceDetails,
  setSequenceDetails,
  allowToActivateSequence,
  isOpenAlertModal,
  setIsOpenAlertModal,
  isEditModeAlertModal,
  setIsEditModeAlertModal,
}) => {
  const navigate = useNavigate();
  const { sequence_id, campaign_id } = useParams();

  const { currentBrand } = useAspSelector((state) => state.app);

  const [isOpenPreview, setIsOpenPreview] = useToggle(false);
  const [isOpenAppPreview, setIsOpenAppPreview] = useToggle(false);
  const [isMessageSending, setIsMessageSending] = useToggle(false);
  const [isOpenGetSendContacts, setIsOpenGetSendContacts] = useToggle(false);
  const [contactsList, setContactsList] = useState([initalConatactList]);

  const addContact = () =>
    setContactsList([
      ...contactsList,
      { id: contactsList?.length + 1, country: '+91', phone_number: '' },
    ]);

  const removeContact = (id) => {
    const res = contactsList?.filter((prev) => prev?.id !== id);
    setContactsList(res);
  };

  const updateConatct = ({ id, key, value }) => {
    const res = contactsList?.map((list) => {
      if (list?.id === id) return { ...list, [key]: value };
      return list;
    });
    setContactsList(res);
  };

  const handleCancelAppPreview = () => setIsOpenAppPreview(false);

  const handleCancelgetNumbers = () => {
    setIsOpenGetSendContacts(false);
    setContactsList([initalConatactList]);
  };

  const formatNumber = () =>
    contactsList?.map((c) => `${c?.country?.slice(1)} ${c?.phone_number}`);

  const allowToSendTextMessage = useCallback(() => {
    const isLength = contactsList?.length > 0;

    const isEmptyValue = contactsList?.some((v) => v?.phone_number === '');
    const isValueLength = contactsList?.some(
      (v) => v?.phone_number?.length !== 10
    );

    return isLength && !isEmptyValue && !isValueLength;
  }, [contactsList]);

  const sendPreviewToWhatsapp = async () => {
    if (!isMessageSending && allowToSendTextMessage()) {
      setIsMessageSending(true);
      try {
        const payload = {
          from_number:
            triggerData?.data?.broadcast?.settings?.send_from_phone_number_id,
          to_number: formatNumber(),
          brand_id: currentBrand?.brand_id,
          campaign_id,
          sequence_id,
          broadcast_id: triggerData?.data?.broadcast?.id,
          template_id: triggerData?.data?.template_id,
          subcategory_type: triggerData?.data?.category,
          is_test_message: true,
        };
        const res = await sendWhatsappPreview(payload);
        if (res?.status === 200) handleCancelgetNumbers();
      } catch (error) {
        console.warn(error);
      } finally {
        setIsMessageSending(false);
      }
    }
  };

  const toggleEditMode = async () => {
    try {
      const payload = {
        brand_id: currentBrand?.brand_id,
        campaign_id,
        data: {
          defaults: {
            ...sequenceDetails?.settings?.defaults,
            isEditMode: !sequenceDetails?.settings?.defaults?.isEditMode,
          },
        },
      };

      const res = await updateSequence(sequence_id, payload);

      if (res?.status === 200) {
        setSequenceDetails((prev) => ({
          ...prev,
          settings: {
            ...prev?.settings,
            defaults: {
              ...prev?.settings?.defaults,
              isEditMode: !prev?.settings?.defaults?.isEditMode,
            },
          },
        }));
        setIsOpenAlertModal(false);
        setIsEditModeAlertModal(false);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const changeStatus = async (mode = 'status') => {
    try {
      const payload = {
        brand_id: currentBrand?.brand_id,
        campaign_id,
        state_action: ['DRAFT', 'NEW']?.includes(sequenceDetails?.status)
          ? 'activate'
          : ['ACTIVE', 'SCHEDULED', 'RUNNING']?.includes(
              sequenceDetails?.status
            )
          ? 'pause'
          : sequenceDetails?.status === 'PAUSED' && 'start',
      };

      if (
        sequenceDetails?.settings?.defaults?.isEditMode &&
        ['PAUSED']?.includes(sequenceDetails?.status)
      ) {
        payload['defaults'] = {
          ...sequenceDetails?.settings?.defaults,
          isEditMode: false,
        };
      }

      if (
        ['ACTIVE', 'SCHEDULED', 'RUNNING']?.includes(sequenceDetails?.status) &&
        !sequenceDetails?.settings?.defaults?.isEditMode &&
        mode === 'edit'
      )
        payload['defaults'] = {
          ...sequenceDetails?.settings?.defaults,
          isEditMode: true,
        };

      const res = await changeSequenceStatus(sequence_id, payload);

      if (res?.status === 200) {
        setSequenceDetails((prev) => ({
          ...prev,
          status: res?.data?.sequence_status,
          settings: {
            ...prev?.settings,
            defaults: {
              ...prev?.settings?.defaults,
              isEditMode:
                ['ACTIVE', 'SCHEDULED', 'RUNNING']?.includes(
                  sequenceDetails?.status
                ) &&
                !sequenceDetails?.settings?.defaults?.isEditMode &&
                mode === 'edit'
                  ? true
                  : sequenceDetails?.settings?.defaults?.isEditMode &&
                    ['PAUSED']?.includes(sequenceDetails?.status)
                  ? false
                  : false,
            },
          },
        }));
        setIsOpenAlertModal(false);
        setIsEditModeAlertModal(false);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const getStatusStyles = useCallback(
    () => SEQUENCE_STATUS_STYLES[sequenceDetails?.status],
    [sequenceDetails]
  );

  // const resetPreviewMessages = async () => {
  //   try {
  //     const query = {
  //       brandId: currentBrand?.brand_id,
  //       campaignId: campaign_id,
  //       previewId: previewData?.id,
  //     };

  //     const res = await previewResetMessage(sequence_id, query);
  //     if (res?.status === 200) {
  //       setPreviewData((prev) => ({
  //         ...prev,
  //         preview: res?.data?.data,
  //       }));
  //     }
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // };

  const hadnleCancelAlertModal = () => setIsOpenAlertModal(false);
  const hadnleCancelEditAlertModal = () => setIsEditModeAlertModal(false);

  const previewContent = (
    <div className='w-full max-w-[350px] rounded-2xl p-4 flex flex-1 flex-col gap-4'>
      {PREVIEW_CONTENTS?.map((content) => {
        return (
          <div
            className='flex gap-2.5 py-2.5 px-4 rounded-[10px] cursor-pointer items-start'
            style={{
              background: content?.id === 2 ? '#F8F9FC' : 'transparent',
            }}
            onClick={() => {
              setIsOpenPreview(false);
              if (content?.id === 1) setIsOpenAppPreview(true);
              if (content?.id === 2) setIsOpenGetSendContacts(true);
            }}>
            <div
              className={cn(
                'size-6 min-w-6 min-h-6 flex items-center justify-center'
              )}>
              <content.icon
                width={content?.width}
                height={content?.height}
                color='#04B8A2'
              />
            </div>
            <div className='flex flex-col gap-1'>
              <h4 className='text-base weight-semibold text-[var(--contentText)]'>
                {content?.title}
              </h4>
              <p className='text-[13px] weight-medium text-[#616874]'>
                {content?.content}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <div className='w-full h-[80px] flex items-center gap-5 z-[10] bg-white relative top-0 left-0 px-5'>
      <div
        className='size-8 flex items-center justify-center rotate-180 p-1.5 border-2 border-[#2D3036] rounded-full'
        onClick={() =>
          navigate(`/user/campaign/${campaign_id}/sequence/${sequence_id}`)
        }>
        <FullArrow color='#2D3036' />
      </div>
      <div className='w-full h-full flex flex-1 items-center justify-between'>
        <div className='flex flex-col gap-1.5'>
          <div className='flex items-center gap-2.5 text-base weight-semibold'>
            <h4 className='w-fit max-w-56 truncate capitalize text-[var(--contentText)]'>
              {sequenceDetails?.name}
            </h4>
            <div
              className='w-fit h-7 px-2.5 flex items-center text-sm weight-semibold capitalize rounded-[0.313rem]'
              style={{
                background: getStatusStyles()?.bg,
                color: getStatusStyles()?.color,
              }}>
              {getStatusStyles()?.text}
            </div>
          </div>
          <div className='w-auto h-full flex items-center gap-2.5'>
            <p className='text-sm text-[var(--font-600)] flex items-center gap-2'>
              <span className='weight-medium'>Duration</span>
              <span className='weight-semibold'>
                {dateConverter(sequenceDetails?.start_date)?.formatedShortDate}{' '}
                - {dateConverter(sequenceDetails?.end_date)?.formatedShortDate}
              </span>
            </p>
            {/* <span className='contents-[""] h-5 border-[0.5px] border-[var(--border-100)]'></span> */}
          </div>
        </div>
        <div className='flex items-center gap-3.5'>
          {['ACTIVE', 'RUNNING', 'SCHEDULED', 'PAUSED']?.includes(
            sequenceDetails?.status
          ) && (
            <Tooltip content='Edit Mode'>
              <button
                className={cn(
                  'flex items-center justify-center p-2 rounded-md border border-gray-200 cursor-pointer',
                  sequenceDetails?.settings?.defaults?.isEditMode
                    ? 'bg-[#05805C] text-white'
                    : 'hover:bg-gray-100'
                )}
                onClick={() => {
                  if (
                    ['PAUSED']?.includes(sequenceDetails?.status) &&
                    sequenceDetails?.settings?.defaults?.isEditMode
                  )
                    toggleEditMode();
                  else setIsEditModeAlertModal(true);
                }}>
                <EditIcon
                  width={20}
                  height={20}
                  color={
                    sequenceDetails?.settings?.defaults?.isEditMode
                      ? 'white'
                      : '#616874'
                  }
                />
              </button>
            </Tooltip>
          )}
          <Tooltip content='Go To Dashboard'>
            <img
              src={ICONS?.analytics}
              alt='analytics'
              onClick={() => {
                navigate(
                  `/user/campaign/${campaign_id}/sequence/${sequence_id}`
                );
              }}
              className={cn(
                'p-10 rounded-md border border-gray-200 cursor-pointer hover:bg-gray-100'
              )}
            />
          </Tooltip>
          <Popover
            open={isOpenPreview}
            onOpenChange={(open) => setIsOpenPreview(open)}
            arrow={false}
            trigger={['click']}
            content={previewContent}>
            <button
              className='w-auto px-[0.563rem] py-[0.438rem] flex items-center gap-2 rounded-md border-[1.5px] border-[#E8E8EA]'
              onClick={() => setIsOpenPreview(true)}>
              <SequencePreview
                width={20}
                height={20}
              />
              <div className='flex items-center gap-2'>
                <p className='text-base text-[var(--font-600)] weight-semibold'>
                  Preview
                </p>
                <div className='size-5 flex items-center justify-center'>
                  <Arrow
                    className='-rotate-90'
                    strokeWidth='1.5'
                  />
                </div>
              </div>
            </button>
          </Popover>
          {!['COMPLETED']?.includes(sequenceDetails?.status) && (
            <button
              className={cn(
                'px-2.5 py-1.5 rounded-md text-base weight-semibold flex items-center gap-1.5',
                allowToActivateSequence
                  ? 'bg-[#05805C] text-white cursor-pointer'
                  : 'bg-[var(--BG-50)] text-[var(--font-600)] cursor-not-allowed'
              )}
              onClick={() => {
                if (
                  allowToActivateSequence &&
                  ['ACTIVE', 'SCHEDULED', 'RUNNING']?.includes(
                    sequenceDetails?.status
                  )
                ) {
                  setIsOpenAlertModal(true);
                } else if (allowToActivateSequence) changeStatus();
              }}
              disabled={!allowToActivateSequence}>
              {status[sequenceDetails?.status]}
            </button>
          )}
        </div>
      </div>
      <Modal
        open={isOpenAppPreview}
        afterOpenChange={(open) => setIsOpenAppPreview(open)}
        footer={null}
        width={'auto'}
        closable={false}
        centered
        wrapClassName={'bg-[#00000095]'}
        rootClassName='!h-auto !max-h-[80vh]'>
        <div className='min-w-[375px] max-w-[375px] h-auto max-h-4/5 !bg-transparent'>
          <Preview
            showHeader={false}
            bodyClassName='h-full max-h-[65vh]'
            handleCancel={handleCancelAppPreview}
            showReset
          />
        </div>
      </Modal>

      <Modal
        open={isOpenGetSendContacts}
        afterOpenChange={(open) => setIsOpenGetSendContacts(open)}
        footer={null}
        className={''}
        closable={false}
        centered={true}
        wrapClassName={'bg-[#00000095]'}
        width={'40vw'}>
        <ModalHeader
          title={'Test this sequence on WhatsApp'}
          subTitle='If you want to test this sequence yourself, add your phone number. You can also send a test to your clients or colleagues by adding their number.'
          handleCancel={handleCancelgetNumbers}
        />
        <div className='px-5 py-5 flex flex-col gap-5'>
          <div className='flex flex-col gap-7'>
            {contactsList?.map((list) => {
              return (
                <div className='w-full h-8 flex items-center gap-5'>
                  <CountryCode
                    value={list?.country}
                    onChange={(data) => {
                      updateConatct({
                        id: list?.id,
                        key: 'country',
                        value: data?.value,
                      });
                    }}
                    expandable={false}
                    className='w-fit h-9'
                  />
                  <div className='w-full h-9 flex-1 flex items-center border border-[var(--border-200)] rounded-lg px-2.5 py-1'>
                    <input
                      type='text'
                      value={list?.phone_number}
                      placeholder='EX:- 0000 000 000'
                      className='w-full h-full'
                      maxLength={10}
                      onChange={(e) =>
                        updateConatct({
                          id: list?.id,
                          key: 'phone_number',
                          value: numberOnly(e?.target?.value),
                        })
                      }
                    />
                  </div>
                  {contactsList?.length > 1 && (
                    <button
                      className={`size-7 group/delete bg-transparent flex items-center justify-center rounded-lg cursor-pointer hover:bg-red-100 transition-all ease-linear duration-100`}
                      onClick={() => {
                        removeContact(list?.id);
                      }}>
                      <Delete
                        width={18}
                        height={18}
                        className={`stroke-[#898E99] group-hover/delete:stroke-[var(--fontRed)]`}
                        color=''
                      />
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          <button
            className={cn(
              'w-full h-8 text-base weight-semibold flex items-center gap-2.5',
              contactsList?.length < 5 ? 'cursor-pointer' : 'cursor-not-allowed'
            )}
            onClick={() => {
              if (contactsList?.length < 5) addContact();
            }}>
            <AddIcon stroke='#616874' />
            <p>Add Number</p>
          </button>

          <div className='w-full h-16 flex items-center justify-between pt-5 border-t border-[var(--border-100)]'>
            <p>Max limit 5 numbers</p>
            <button
              className={cn(
                'min-w-28 px-4 py-1.5 rounded-md text-base weight-bold flex items-center justify-center',
                allowToSendTextMessage()
                  ? 'bg-[#05805C]  text-white'
                  : 'bg-[var(--BG-50)]  text-[var(--text-600)]'
              )}
              onClick={sendPreviewToWhatsapp}>
              {isMessageSending ? (
                <Loader
                  Width={20}
                  Height={20}
                  loaderBg='white'
                />
              ) : (
                <p>Send Text</p>
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Alert
        open={isOpenAlertModal}
        setOpen={setIsOpenAlertModal}
        icon={ICONS?.PausedBlack}
        title='Pause this sequence?'
        content='Pausing this sequence may disrupt actions and delay tasks. Proceed only if necessary.'
        yesContent='Pause Sequence'
        noContent='Cancel'
        handleYes={() => {
          changeStatus();
        }}
        handleNo={hadnleCancelAlertModal}
      />

      <Alert
        open={isEditModeAlertModal}
        setOpen={setIsEditModeAlertModal}
        icon={ICONS?.EditBlack}
        title='Edit this sequence?'
        content={
          ['PAUSED']?.includes(sequenceDetails?.status)
            ? 'This action will allow you to make changes to the flow.'
            : 'Pause the sequence to edit it. This will stop all scheduled actions temporarily, allowing you to make changes.'
        }
        yesContent={
          ['PAUSED']?.includes(sequenceDetails?.status)
            ? 'Edit Sequence'
            : 'Pause & Edit Sequence'
        }
        noContent='Cancel'
        handleYes={() => {
          if (['PAUSED']?.includes(sequenceDetails?.status)) toggleEditMode();
          else changeStatus('edit');
        }}
        handleNo={hadnleCancelEditAlertModal}
      />
    </div>
  );
};

export default Header;
