import axios from "axios";

const headers = {
  "X-Requested-With": "XMLHttpRequest",
};

const { ASP_API_URL } = process.env;

// baseURL
export const baseURL = "https://api.preprod.gainwix.app/"; // preprod
// const baseURL = "https://b872-183-82-32-251.ngrok-free.app";
// export const baseURL = "https://api.gainwix.app/"; // prod

// export const baseURL = "https://v1.api.gainwix.app/"; //prod-v1

// webhooks
// const baseURL = 'https://webhook.gainwix.app';

// API Secret Key
const API_SECRET_KEY = "e1b886c2-6aa7-4483-bb8f-253625cd8409"; // preprod
// const API_SECRET_KEY = "99f316f8-2c19-4632-b3c5-25518cd1e7a4"; // prod

// Site Key
export const sitekey = "6LfThrQqAAAAADPt2mC7Zc1QyQkIrLnEDeD6Aaiy"; //preprod
// export const sitekey = "6LcOiMEqAAAAAHCm7o1sgeihid4dikVzBbRGMVlE"; //prod-v1

// config id
export const configId = "820380526237844"; //preprod
// export const configId = "963924375177412";  //prod-v1

// change appId in index.html when deploying in production --------------->

const AspApi = axios.create({
  baseURL: ASP_API_URL || baseURL,
  headers,
});

AspApi.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: localStorage.getItem("authToken"),
      "X-ASP-APP-SECRET": API_SECRET_KEY,
    };
    return config;
  },

  (error) => {
    console.error(error);
    return error;
  }
);

AspApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.data?.error === "Token not logged in" ||
      error?.response?.data?.error === "No active session found" ||
      error?.response?.data?.error === "Token expired"
    ) {
      localStorage.removeItem("authToken");
      window.location.href = "/login";
      return error;
    } else return error;
  }
);

export default AspApi;
