import React from 'react';

const Refresh = ({
  width = 24,
  height = 24,
  color = '#616874',
  strokeWidth = 2,
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 20.6942C7.02944 20.6942 3 16.8016 3 11.9999C3 8.96454 4.61019 6.29246 7.05044 4.73732M13.3635 3.40476C17.687 4.03953 21 7.64598 21 11.9999C21 15.1321 19.2855 17.8775 16.7136 19.4079M13.1255 5.61085L10.739 3.30542L13.1255 1V5.61085ZM10.7381 18.3892L13.1246 20.6946L10.7381 23L10.7381 18.3892Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Refresh;
